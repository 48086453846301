import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {  UntypedFormGroup } from '@angular/forms';
import { FilterType } from '../commen/Enums';
import { FilterButtonComponent } from '../filter-button/filter-button.component';
import { FilterService } from '../Services/FilterService';
import {  Router } from '@angular/router';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-filter-function',
  templateUrl: './filter-function.component.html',
  styleUrls: ['./filter-function.component.scss'],
})
export class FilterFunctionComponent implements OnInit {
  auditFilter: UntypedFormGroup;
  customers: string[] = [];
  @ViewChild('filterButton') filterButtonComponent: FilterButtonComponent;
  documentsList: string[] = [];
  documentsListId: string[] = [];
  customerListId: string[] = [];
  favoriteViews: any[] = [];
  @ViewChild('selectBox') selectBox!: MatSelect;
  parentInputValue = null;
  isLoading = false;
  addingNewFilter: boolean = false;
  newFilterName: string = '';
  activeFilterIndex: number = -1;
  searchText: string = '';
  selectedStatus: number = 0;
  isSearched: boolean = false;
  @Input() FilterType: FilterType;
  @Input() canCreate: boolean = false;

  public filterTypeEnum = FilterType;
  closeListener: any;
  filterUpdateListner: any;

  constructor(
    private filterService: FilterService,
    private router: Router
  ) {

  }
  isFilterPopupOpen: boolean = false;
  isFavoriteFiltersPopupOpen: boolean = false;
  ngOnInit(): void {
    if (this.FilterType) {
      this.getFavViews();
    }
    this.closeListener = this.filterService.CloseSignalFavorite.subscribe(data => {
      if (data) {
        this.getFavViews();
      }
    })
    this.filterUpdateListner = this.filterService.updateFilters.subscribe(data => {
      if (data) {
        this.parentInputValue = data;
      }
    })
  }
  ngOnDestroy(){
    if (this.filterUpdateListner) {
        this.filterUpdateListner.unsubscribe();
    }
    
    if (this.closeListener) {
      this.closeListener.unsubscribe();
     }
  }
  @Output() tableSettingPopup = new EventEmitter<any>();
  @Input() parentProperty: any;
  @Output() childEvent = new EventEmitter<any>();
  emitEventToParent() {
    this.childEvent.emit('Data to send to parent');
  }
  getFavViews() {
    this.favoriteViews = [];
    var type = 0;
    if (this.FilterType == FilterType.Audits) {
      type = FilterType.AuditView;
    }
    if (this.FilterType == FilterType.ControlDocuments) {
      type = FilterType.ControlDocumentsView;
    }
    if (this.FilterType == FilterType.DPA) {
      type = FilterType.DPAView;
    }
    if (this.FilterType == FilterType.TPM) {
      type = FilterType.TPMView;
    } 
    if (this.FilterType == FilterType.ROPA) {
      type = FilterType.ROPAView;
    }
    if (this.FilterType == FilterType.Questions) {
      type = FilterType.QuestionsView;
    }
    this.favoriteViews.push({
      viewName: 'Default',
      id: null,
      isCurrent: false,
      filters: {}
    })
    this.filterService.getFavoriteViews(type).subscribe(data => {
      this.favoriteViews = this.favoriteViews.concat(data);
      var findSelected = this.favoriteViews.find(d => d.isCurrent == true);
      if (!findSelected) {
        this.favoriteViews[0].isCurrent = true;
      }
      this.favoriteViews = this.favoriteViews;
    })
  }
  saveFavView(ViewName) {
    this.filterService.SaveFavoriteView.next({ name: ViewName, type: this.FilterType });
  }
  selectFilter(index: number) {
    this.activeFilterIndex = index;
  }
  openFilterPopup(): void {
    console.log(this.parentProperty)
    this.isFavoriteFiltersPopupOpen = false;
    this.isFilterPopupOpen = true;
    if (this.filterButtonComponent) {
      this.filterButtonComponent.click();
    }
  }

  openFavoriteFiltersPopup(): void {
    this.isFilterPopupOpen = false;
    this.isFavoriteFiltersPopupOpen = true;
  }

  closeFilterPopup(): void {
    this.isFilterPopupOpen = false;
    console.log("executing")
    this.emitEventToParent();
  }
  closeFavoriteFiltersPopup(): void {
    this.isFavoriteFiltersPopupOpen = false;
  }

  applySorting(): void {
    console.log(this.auditFilter.value);
    this.closeFilterPopup();
  }
  resetSortingOptions(): void {
    // Implement resetSortingOptions logic
  }
  search(): void {
    this.isSearched = true;
    if (this.FilterType == FilterType.Audits) {
      this.filterService.AuditSearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
    else if(this.FilterType == FilterType.ControlDocuments){
      this.filterService.ControlDocumentSearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
    else if(this.FilterType == FilterType.DPA){
      this.filterService.DPASearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
    else if(this.FilterType == FilterType.TPM){
      this.filterService.TPMSearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
    else if(this.FilterType == FilterType.ROPA){
      this.filterService.ROPASearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
    else if(this.FilterType == FilterType.Questions){
      this.filterService.QuestionSearchChangeSubject.next({ keyWords: this.searchText, isChanged: true });
    }
  }
  clearSearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.AuditSearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  clearControlDocumentSearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.ControlDocumentSearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  clearDPASearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.DPASearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  clearROPASearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.ROPASearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  clearTPMSearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.TPMSearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  clearQuestionsSearch(): void {
    this.isSearched = false;
    this.searchText = ''
    this.filterService.QuestionSearchChangeSubject.next({ keyWords: null, isChanged: true });
  }
  showAddFilterInput() {
    this.addingNewFilter = true;
  }

  ApplyAuditFilter(filters) {
    this.filterService.AuditFilterChangeSubject.next(filters);
  }
  ApplyControlDocumentFilter(filters) {
    if (filters.status) {
      this.selectedStatus = filters.status;
    }
    this.filterService.ControlDocumentFilterChangeSubject.next(filters);
  }
  ApplyDPAFilters(filters, reset = false) {
    this.filterService.DPAFilterChangeSubject.next(filters);
    if (reset) {
      this.filterButtonComponent.clear();
      this.searchText = '';
      this.isSearched = false;
    }
  }
  ApplyQuestionFilters(filters, reset = false) {
    this.filterService.QuestionFilterChangeSubject.next(filters);
    if (reset) {
      this.filterButtonComponent.clear();
      this.searchText = '';
      this.isSearched = false;
    }
  }

  ApplyTPMFilters(filters, reset = false) {
    this.filterService.TPMFilterChangeSubject.next(filters);
    if (reset) {
      this.filterButtonComponent.clear();
      this.searchText = '';
      this.isSearched = false;
    }
  }

  ApplyROPAFilters(filters, reset = false) {
    this.filterService.ROPAFilterChangeSubject.next(filters);
    if (reset) {
      this.filterButtonComponent.clear();
      this.searchText = '';
      this.isSearched = false;
    }
  }

  ApplyFavFilters(filters) {
    if (this.FilterType == FilterType.Audits) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyAuditFilter(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyAuditFilter(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.Audits]).subscribe(data => { })
    }
    if (this.FilterType == FilterType.ControlDocuments) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyControlDocumentFilter(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyControlDocumentFilter(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.ControlDocuments]).subscribe(data => { })
    }
    if (this.FilterType == FilterType.DPA) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyDPAFilters(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyDPAFilters(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.DPA]).subscribe(data => { })
    }
    if (this.FilterType == FilterType.TPM) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyTPMFilters(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyTPMFilters(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.TPM]).subscribe(data => { })
    }
    if (this.FilterType == FilterType.Questions) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyQuestionFilters(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyQuestionFilters(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.Questions]).subscribe(data => { })
    }
    if (this.FilterType == FilterType.ROPA) {
      this.parentInputValue = filters.filters;
      if (filters.id) {
        this.ApplyROPAFilters(filters.filters);
        this.getFavViews();
      }
      else {
        this.ApplyROPAFilters(filters.filters);
        this.getFavViews();
      }
      this.filterService.save(filters.filters, FilterType[FilterType.ROPA]).subscribe(data => { })
    }
  }


  changeStatus(status: number) {
    this.filterService.ControlDocumentStatuChangeSubject.next(status);
  }


  // 
  navigateToCreateDPA() {
     this.router.navigate(['/DataProtection/DPA/Create']);
  }
  navigateToCreateQuestion() {
     this.router.navigate(['/Audit/Question']);
  }
  navigateToCreateTPM() {
    this.router.navigate(['/Assets/TPM/Create']);
 }
 navigateToCreateROPA() {
  this.router.navigate(['GDPR/ROPA/Create']);
}
  openTableSetting(){
    this.tableSettingPopup.emit();
  }

  getSelectedStatusText(): string {
    switch (this.selectedStatus) {
      case 0: return 'Any';
      case 1: return 'Draft';
      case 2: return 'Minor';
      case 3: return 'Major';
      default: return '';
    }
  }

  toggleSelect() {
    if (this.selectBox.panelOpen) {
      this.selectBox.close(); // Close the dropdown if it's open
    } else {
      this.selectBox.open(); // Open the dropdown if it's closed
    }
  }
}
