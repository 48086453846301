import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import FileInfo from 'src/app/Common/models/files';
import { FileService } from 'src/app/Services/file.services';
import { FormsSection, ROPAFileTypes } from 'src/app/Enum/permission-type';
import { environment } from 'src/app/environments/environment';
@Component({
  selector: 'app-atlas-input',
  templateUrl: './atlas-input.component.html',
  styleUrls: ['./atlas-input.component.scss'],
})
export class AtlasInputComponent implements OnInit {
  @Input() controlName!: string;
  @Input() formGroupData: UntypedFormGroup;
  @Input() placeholder: string = '';
  @Input() label: string = '';
  @Input() recordId: number = 0;
  @Input() removeFirstOption: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() Section: FormsSection = FormsSection.None;
  @Input() ROPAFileType: ROPAFileTypes = ROPAFileTypes.None;
  @Input() Options = [];
  chipInput: string = '';
  @Input() type: string = '';
  @Input() radioName: string = '';
  form!: UntypedFormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  public fields: Object = { text: 'viewValue', value: 'value' };
  public mode: string = 'CheckBox';
  @Input() uploadedFiles: FileInfo[] = [];
  @Output() onFilesDelete = new EventEmitter<{ files: FileInfo[], ROPAFileType?: ROPAFileTypes }>();

  uploadedFileIds = [];
  isLoading = false;
  errorMessage: string = '';
  constructor(private controlContainer: ControlContainer,
    private fileServices: FileService,
  ) {

  }
  onFiltering(event: any): void {

  }
  ngOnInit(): void {
    this.form = <UntypedFormGroup>this.controlContainer.control;
    if (this.control.value && this.type == 'chips') {
      this.tags = this.control.value;
    }
    if (this.control.value && this.type == 'checkbox') {
    }
    if (this.control.value && this.type == 'htmlEditor') {
      this._descriptionHTMLFormate = this.control.value ? this.control.value : "";
    }
    if (this.control.value && this.type == 'multiSelect') {
      // this._descriptionHTMLFormate = this.control.value? this.control.value : "";
    }
    if (this.type == 'select') {
      // this._descriptionHTMLFormate = this.control.value? this.control.value : "";
    }
    if (this.type === 'date') {

    }
  }

  get control(): UntypedFormControl {
    return this.form.get(this.controlName) as UntypedFormControl;
  }
  tags: string[] = [];
  addTag(): void {
    const email = this.chipInput.trim();
    this.errorMessage = '';
    if (this.controlName === 'emails' || this.controlName === 'email') {
      // Validate the email
      if (this.isValidEmail(email)) {
        if (email && this.tags.indexOf(email) === -1) {
          this.tags.push(email);
          this.control.setValue(this.tags);
        }
        this.chipInput = ''; // Clear the input
      } else {
        // Set the error message if the email is invalid
        this.errorMessage = 'Please enter a valid email address';
      }
    } else {
      // Non-email control case
      if (email && this.tags.indexOf(email) === -1) {
        this.tags.push(email);
        this.control.setValue(this.tags);
      }
      this.chipInput = ''; // Clear the input
    }
  }
  isValidEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(email);
  }

  removeTag(email: string): void {
    const index = this.tags.indexOf(email);
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.control.setValue(this.tags);
    }
  }
  // for html editor
  @ViewChild('rteObj') rteObj: RichTextEditorComponent;
  @Input() onCreateCommand = (el) =>
    console.log("onCreate handler not implemented");
  @Input() onChange = (el) =>
    console.log("onChange handler not implemented");
  @Input() Label: string = "";
  _descriptionHTMLFormate = "";
  @Input()
  set descriptionHTMLFormate(value: string | undefined) {
    this._descriptionHTMLFormate = value || "";
  }
  @Input() height: number = 200;
  public tools = {
    items: [
      'Bold', 'Italic', 'Underline', 'FontSize', 'FontColor',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
    ]
  };

  public onCreate(event): void {
    this.onCreateCommand(event)
  }
  ngAfterViewInIt() {
    if (this.type == 'htmlEditor') {
      this.rteObj.valueChange().subscribe(e => {
      })
    }

  }
  change(event) {
    this.control.setValue(event);
  }
  public actionCompleteHandler(e: any): void {
    if (e.requestType === 'SourceCode') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.add('e-overlay');
    } else if (e.requestType === 'Preview') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.remove('e-overlay');
    }
  }
  ///for MultiSelect
  onFileUpload(event: any) {
    const files = event.target.files;
    const fileLimit = 50;
    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        return;
      } else {
        this.isLoading = true;
        this.uploadFile(file);
      }
    }
  }
  uploadFile(file: any) {
    if (this.recordId == 0 || !this.recordId) {
      this.fileServices.UploadForSection(file, this.Section, 0, this.ROPAFileType).subscribe((data) => {
        file.id = data;
        this.uploadedFiles.push(file);
        this.uploadedFileIds.push(data);
        this.control.setValue(this.uploadedFileIds)
        this.isLoading = false;
      });
    } else {
      this.fileServices
        .UploadForSection(file, this.Section, this.recordId, this.ROPAFileType)
        .subscribe((data) => {
          this.uploadedFileIds.push(data);
          file.id = data;
          this.uploadedFiles.push(file);
          this.control.setValue(this.uploadedFileIds)
          this.isLoading = false;
        });
    }
  }
  view(event) {
    console.log("");
  }
  isDeletable(): boolean {
    return true;
  }
  downloadFileUsingLink(fileLink: FileInfo): void {
    const url = environment.apiUrl + `Files/DownloadForSection/${this.Section}/${fileLink.id}`;
    location.href = url;
  }
  deleteFile(file: FileInfo) {
    this.isLoading = true;
    this.fileServices.deleteSectionFile(file.id, this.Section).subscribe((data) => {
      this.isLoading = false;
      this.uploadedFiles = this.uploadedFiles.filter((v, i) => v.id !== file.id);
      const payload = { files: this.uploadedFiles };

      if (this.ROPAFileType !== ROPAFileTypes.None) {
        payload['ROPAFileType'] = this.ROPAFileType;
      }

      if (this.onFilesDelete) {
        this.onFilesDelete.emit(payload);
      }
      const index = this.uploadedFileIds.indexOf(file.id);
      if (index > -1) {
        this.uploadedFileIds.splice(index, 1);
      }
      this.control.setValue(this.uploadedFileIds);
    });
  }
  onCheckboxChange(event: Event, value: string): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    const currentValue = this.control.value || [];

    if (isChecked) {
      if (!currentValue.includes(value)) {
        currentValue.push(value);
      }
    } else {
      const index = currentValue.indexOf(value);
      if (index > -1) {
        currentValue.splice(index, 1);
      }
    }

    this.control.setValue([...currentValue]);
  }

}
