<div class="row">
  <div class="col-md-12">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Control Documents</h5>

        <!-- (ngSubmit)="SubmitControlDocument()" -->
        <form [formGroup]="controlDocumentForm">
          <div class="position-relative row">
            <div class="position-relative mb-3 col-md-6">
              <label for="name" class="form-label">Name <span class="small"> *</span></label>
              <input name="name" formControlName="name" id="name" placeholder="Name" type="text" class="form-control">
              <label for="name" class="form-label mt-1 text-danger"
                *ngIf="this.controlDocumentForm.get('name').value==''">Required</label>

            </div>
            <div class="position-relative mb-3 col-md-3">
              <label for="name" class="form-label">Version <span
                  class="ms-3">{{this.controlDocumentForm.controls["version"].value}}</span></label>
              <!-- <input name="name" formControlName="version" id="name" placeholder="Name" type="text" readonly
                class="form-control"> -->
              <label for="name" class="form-label mt-1 text-danger"
                *ngIf="controlDocumentForm.controls.version.errors">Invalid Number</label>
            </div>
            <div class="col-sm-3 mb-5" *ngIf="isEditMode">
              <button class="mb-5 ms-4  btn btn-success btn-atlas btn-sm small text-small"
                (click)="showDocumentControlHistoryModel(true)">View History</button>
            </div>
          </div>

          <div class="position-relative row">
            <div class="position-relative mb-3 col-md-6">
              <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Categories Control Document</mat-label>
                <mat-select formControlName="categoriesControlDocumentId">
                  <mat-option *ngFor="let catedocTyoe of categoriesControlDocumentList" [value]="catedocTyoe.id">
                    {{catedocTyoe.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <label for="name" class="form-label mt-1 text-danger"
                *ngIf="this.controlDocumentForm.get('categoriesControlDocumentId').value==''">Required</label>
            </div>
            <!-- <div class="position-relative mb-3 col-md-4">
                        <div class="position-relative form-check">
                            <label class="form-label form-check-label">
                              <input type="checkbox" formControlName="status"  class="form-check-input">
                                IsActive Status
                            </label>
                          </div>
                        <!-- <label for="name" class="form-label">Name</label>
                        <input name="name" formControlName="status" id="name" placeholder="Name" type="checkbox"
                            class="form-control">
                        <label for="name" class="form-label mt-1 text-danger"
                            *ngIf="this.controlDocumentForm.get('name').value==''">Required</label> -->

            <!-- </div> -->
            <div class="position-relative mb-3 col-md-3">
              <label for="name" class="form-label">Status <span
                  class="ms-3">{{this.controlDocumentForm.controls["status"].value}}</span></label>
              <!-- <input name="name" formControlName="status" id="name" placeholder="status" type="text" readonly class="form-control"> -->

            </div>

          </div>
          <div class="position-relative mb-3 col-md-6 ps-0">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Department</mat-label>
              <mat-select formControlName="departmentId">
                <mat-option *ngFor="let department of departmentsList" [value]="department.id">
                  {{department.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <label for="name" class="form-label mt-1 text-danger"
              *ngIf="this.controlDocumentForm.get('departmentId').value==''">Required</label>
          </div>
          <div >
            <div class="position-relative mb-3 " id="target">
              <mat-label>Description</mat-label>
              <div id="rteContainer">
                <ejs-richtexteditor #rteObj [toolbarSettings]='tools' [height]='height'
                  [(ngModel)]="descriptionHTMLFormate" [ngModelOptions]="{ standalone: true }" (created)='onCreate()'
                  (contextmenu)="onRteRightClick($event)" (actionComplete)='actionCompleteHandler($event)'>
                  <ng-template #toolbarTemplate>
                    <button ejs-richtexteditorbutton title="Custom">Custom</button>
                    <!-- Add other toolbar buttons as needed -->
                  </ng-template>
                </ejs-richtexteditor>
              </div>

              <ejs-contextmenu #contextMenuObj [items]="dataContext" (select)="itemSelect($event)"></ejs-contextmenu>
              <ejs-contextmenu #contextMenuObjView [items]="dataContextView"
                (select)="itemSelect($event)"></ejs-contextmenu>
              <!-- 
              <div #contextMenu style="display: none; position: absolute; background: white; border: 1px solid #ccc;"
                (click)="handleContextMenuClick($event)">
                <div style="padding: 8px;" data-action="addControl">Add Control</div>
                <div style="padding: 8px;" data-action="removeControl">Remove Control</div>
              </div> -->

            </div>
          </div>

          <div class="row">

          </div>
          <button class="mt-1 btn btn-success btn-atlas" (click)="SubmitWithStatus(1)">Draft</button>
          <button class="mt-1 btn btn-success btn-atlas  ms-2" (click)="SubmitWithStatus(2)">Minor</button>
          <button class="mt-1 btn btn-success btn-atlas  ms-2" (click)="SubmitWithStatus(3)">Major</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>
<app-link-question *ngIf="linkQuestion" [onClose]="onClose.bind(this)"
  (linkQuestionForListsOut)="linkQuestionfunc($event)" [addMultiSelect]=true [linkedControls]="linkedControlsList"
  style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;"></app-link-question>

<app-compare-version-popup *ngIf="historyDocumentationModel" [historyDocumentationModel]="historyDocumentationModel"
  [controldocumentId]="controldocumentId" [onClose]="onClose.bind(this)"></app-compare-version-popup>

<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 1000001;">
  <mat-spinner></mat-spinner>
</div>

<!-- <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
  <button mat-menu-item (click)="showlinkComponent()">Link</button>
</mat-menu> -->
<div #dropdown class="dropdown" style="display:none;position: absolute; background-color: white;    z-index: 1000001;
border: 1px solid gray;
border-radius: 5px;">
  <button class="dropdown-item" type="button" (click)="ViewControl(LinkForLink)">View Liniked Controls</button>
  <button class="dropdown-item mt-2" type="button" (click)="RemoveLinking($event)">Remove Linking</button>
  <!-- <button class="dropdown-item" type="button" (click)="EditLinkedControls(LinkForLink)">Edit Liniked Controls</button> -->
</div>


<app-app-control-details *ngIf="openDetails" [event]="controlDetails" [onClose]="onClose.bind(this)"
  style="position: fixed;top: 15%;width: 70%;z-index: 1000000;">
</app-app-control-details>
<!-- <app-app-popup *ngIf="openConfirmation" [Title]="Title" [onClose]="onReject.bind(this)"
  [ConfirmationMessage]="ConfirmationMessage" [onConfirm]="onConfirm.bind(this)">
</app-app-popup> -->

<app-app-popup 
  *ngIf="openConfirmation"
  [Title]="Title"
  [ConfirmationMessage]="ConfirmationMessage"
  [onClose]="onReject.bind(this)"
  [onConfirm]="onConfirm.bind(this)"
  [showCloseButton]="true"
  [showConfirmButton]="true"
  [closeButtonText]="'No'"
  [confirmButtonText]="'Publish'">
</app-app-popup>

<div class="Pop-Up-Box-Control-List pop-up-box" *ngIf="showControlListPopUp">
  <div class="Pop-Up-Header">
    <span (click)="closeControlListPopUp(false)" class="material-icons">highlight_off</span>
    <h1>Linked Controls</h1>
  </div>

  <hr class="HR">
  <div class="row mb-1" *ngFor="let control of linkedControlsList">
    <div class="col-md-9">
      <p>{{control.name}}</p>
    </div>
    <div class="col-md-3">
      <button class="btn btn-success btn-atlas" (click)="OpenControlDetails(control.id)">View Control</button>
    </div>
  </div>

</div>

<app-control-document-log-change-popup *ngIf="showLogChangePopup" [controlDocumentId]="controldocumentId"
  [onClose]="onClose.bind(this)" [onSave]="SaveWithLog.bind(this)">
</app-control-document-log-change-popup>