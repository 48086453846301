<div [ngClass]="{'marginLeft': addMargin && !globals.toggleSidebar}" class="popup-overlay">
    <div class="Pop-Up-Box-Com pop-up-box-com center-popup">
      <div class="Pop-Up-Header pt-3 ps-3 pe-3">
        <span (click)="onClose(false)" class="close-icon">X</span>
        <h4>{{Title}}</h4>
      </div>
      <hr class="HR mb-2">
      <div class="container">
        <p class="mt-3 mb-3">{{ConfirmationMessage}}</p>
      </div>
      <hr class="HR">
      <div class="button-group mb-2 me-2 pe-2 mt-2">
        <button *ngIf="showCloseButton" (click)="onClose(false)" class="btn btn-atlas-cancel">{{closeButtonText}}</button>
        <button *ngIf="showConfirmButton" (click)="onConfirm(false)" class="btn btn-success btn-atlas">{{confirmButtonText}}</button>
      </div>
    </div>
  </div>
  