<ng-container *ngIf="Label == ''">
    <ejs-richtexteditor #rteObj [toolbarSettings]='tools' [height]='height' [(ngModel)]="_descriptionHTMLFormate"
        [placeholder]="placeholder" (ngModelChange)="change($event)" [ngModelOptions]="{ standalone: true }"
        (created)='onCreate($event)' (actionComplete)='actionCompleteHandler($event)'>
        <ng-template #toolbarTemplate>
            <button ejs-richtexteditorbutton title="Custom">Custom</button>
            <!-- Add other toolbar buttons as needed -->
        </ng-template>
    </ejs-richtexteditor>
</ng-container>
<div class="position-relative mb-3 " *ngIf="Label != ''">
    <mat-label>{{Label}}</mat-label>
    <ejs-richtexteditor #rteObj [toolbarSettings]='tools' [height]='height' [(ngModel)]="_descriptionHTMLFormate"
        [placeholder]="placeholder" (ngModelChange)="change($event)" [ngModelOptions]="{ standalone: true }"
        (created)='onCreate($event)' (actionComplete)='actionCompleteHandler($event)'>
        <ng-template #toolbarTemplate>
            <button ejs-richtexteditorbutton title="Custom">Custom</button>
            <!-- Add other toolbar buttons as needed -->
        </ng-template>
    </ejs-richtexteditor>
</div>