import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterType } from '../commen/Enums';
import { environment } from 'src/app/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private apiUrl = environment.apiUrl; // Replace with your API URL
    constructor(private http: HttpClient) { }
    clearFilterData(filterType: FilterType): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}Filter/Clear/${filterType}`);
    }
    getFilterData(filterType: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}Filter/${filterType}`);
    }
    getFavoriteViews(filterType: FilterType): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}Filter/GetViews/${filterType}`);
    }
    getSaveFilterData(filterType: FilterType): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}Filter/GetSaveViews/${filterType}`);
    }
    save(filterData: any, filterType): Observable<any> {
        return this.http.post(`${this.apiUrl}Filter/${filterType}`, filterData);
    }
    setSelected(filterType, id): Observable<any> {
        var endURl = id ? `${filterType}/${id}` : `${filterType}`
        return this.http.post(`${this.apiUrl}Filter/SetSelected/${endURl}`, {});
    }
    delete(id: any): Observable<any> {
        return this.http.delete(`${this.apiUrl}Filter/Delete/${id}`);
    }
    saveAuditFavoriteView(filterData: any, name): Observable<any> {
        var body = {
            filters: filterData,
            viewName: name,
        }
        return this.http.post(`${this.apiUrl}Filter/AuditView`, body);
    }
    saveFavoriteView(filterData: any, name, type): Observable<any> {
        var body = {
            filters: filterData,
            viewName: name,
        }
        return this.http.post(`${this.apiUrl}Filter/${type}`, body);
    }
    //Filtering Subjects
    public AuditFilterChangeSubject = new BehaviorSubject<any>(null)
    public AuditSearchChangeSubject = new BehaviorSubject<any>({ keyWords: null, isChanged: false })


    public ControlDocumentFilterChangeSubject = new BehaviorSubject<any>(null)
    public ControlDocumentStatuChangeSubject = new BehaviorSubject<any>(null)
    public ControlDocumentSearchChangeSubject = new BehaviorSubject<any>({ keyWords: null, isChanged: false })
    public DPAFilterChangeSubject = new BehaviorSubject<any>(null)
    public DPASearchChangeSubject = new BehaviorSubject<any>({ keyWords: null, isChanged: false })
    public QuestionFilterChangeSubject = new BehaviorSubject<any>(null)
    public QuestionSearchChangeSubject = new BehaviorSubject<any>({ keyWords: null, isChanged: false })

    public TPMFilterChangeSubject = new BehaviorSubject<any>(null)
    public TPMSearchChangeSubject = new BehaviorSubject<any>({ keyWords: null, isChanged: false })
    public SaveFavoriteView = new BehaviorSubject<any>({ name: null, type: null })
    public CloseSignalFavorite = new BehaviorSubject<any>(null);
    public updateFilters = new BehaviorSubject<any>(null);
}
export function getEnumKeys(enumType: any): string[] {
    return Object.keys(enumType).filter(key => isNaN(Number(enumType[key])));
}``

export function getEnumKeyByValue(enumType: any, value: number): string | undefined {
    return Object.keys(enumType).find(key => enumType[key] === value);
}
