<app-filter-function [canCreate]="route | hasPermission:'canCreate'" [FilterType]="filterTypeEnum" (tableSettingPopup)="tableSettingPopup()"></app-filter-function>
<div>
 <app-atlas-table [columns]="columns" [dataSource]="data" [customColumnWidths]="true" [hasDeleteIcon]="true"
        [hasPreviewIcon]="true" [hasStartIcon]="true" [onStart]="startAudit.bind(this)" [onEdit]="getEditUrl.bind(this)"
        (SortPageChanged)="sortChange($event)" [haveCommonIcons]="true" [hasEditIcon]="true && (route | hasPermission:'canUpdate')" [hasDeleteIcon]="true && (route | hasPermission:'canDelete')" [onDelete]="onDelete.bind(this)" [onCustomButtonIconClick]="DuplicateAudit.bind(this)"
        [onOpen]="OpenAuditDetails.bind(this)" [hasServerPaging]="true" [hasPaginator]="false" [totalItems]="totalItems"
        [customPageSizeOptions]="customPageSizeOptions" [pageChange]="pageChangeHandler.bind(this)">
    </app-atlas-table>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 1000001;">
    <mat-spinner></mat-spinner>
</div>

<app-app-audit-details *ngIf="OpenAuditDetailsPopUp"
    style="position: absolute; top: 20%;width:70%;height: 75vh; z-index: 100000;" [onClose]="onClose.bind(this)"
    [data]="auditDetails"></app-app-audit-details>

<app-table-setting
*ngIf="isTableSettingPopupOpen"
    [columns]="columns"
    [settingType]="settingType"
    (closePopup)="onCloseTableSettingPopup()"
    (saveSettings)="onSaveSettings()"
  ></app-table-setting>