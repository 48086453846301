import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ControlDocumentsServices {
  constructor(private http: HttpClient) { }
  controlDocumentBasePath = environment.apiUrl + "ControlDocuments";
  getAll(): any {
    return this.http.get<any>(this.controlDocumentBasePath + "/All");
  }
  getAllMajorAndMinor(): any {
    return this.http.get<any>(this.controlDocumentBasePath + "/GetAllMajorAndMinor");
  }
  get(id): any {
    return this.http.get<any>(this.controlDocumentBasePath + "/Get/" + id);
  }
  getAuditTrailById(id): any {
    return this.http.get<any>(this.controlDocumentBasePath + "/GetAuditTrainByAuditTrailId/" + id);
  }

  create(body): any {
    return this.http.post<any>(this.controlDocumentBasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.controlDocumentBasePath + "/Update", body);
  }

  delete(id): any {
    return this.http.delete<any>(
      this.controlDocumentBasePath + "/Delete/" + id
    );
  }
  deleteControldocumentAuditTrailByControldocument(body): any {
    return this.http.post<any>(
      this.controlDocumentBasePath + "/DeleteControldocumentAuditTrail",
      body
    );
  }
  getControldocumentAuditTrailByControldocument(body): any {
    return this.http.post<any>(
      this.controlDocumentBasePath + "/GetControldocumentAuditTrail",
      body
    );
  }
  getAllAuditTrails(): any {
    return this.http.get<any>(
      this.controlDocumentBasePath + "/GetAllAuditTrails"
    );
  }
  getAllControlDocuments(body): any {
    return this.http.post<any>(this.controlDocumentBasePath + "/GetAll", body);
  }
  GetAllTrailAuditListByControlDocumentId(controldocumentId: string): any {
    return this.http.get<any>(
      this.controlDocumentBasePath + "/GetAllAuditTrails/" + controldocumentId
    );
  }

  GetControlDocumentChangeLogs(controldocumentId: string): any {
    return this.http.get<any>(this.controlDocumentBasePath + "/GetControlDocumentChangeLogs/" + controldocumentId)
  }

  CreateControlDocumentChangeLog(body: any): any {
    return this.http.post<any>(this.controlDocumentBasePath + "/CreateControlDocumentChangeLog", body);
  }
}
