import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';
import * as Diff from 'diff';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-diff-viewer',
  templateUrl: './diff-viewer.component.html',
  styleUrls: ['./diff-viewer.component.scss']
})
export class DiffViewerComponent implements OnInit {
  @Input() mode: 'chars' | 'words' | 'wordsWithWS' | 'lines' | 'trimLines' | 'sentences' | 'css' | 'json' | 'arrays' = 'chars';

  constructor(
    public globals: ThemeOptions,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.computeDiff()
  }
  @Input()
  closePopUp: (closeboth) => {};

  @Input()
  CurrentHTML: any = '';
  addMargin: boolean = false;
  @Input()
  PreviouseHTML: any = '';
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 939 && window.innerWidth >= 768) {
      this.addMargin = true
    }
    else {
      this.addMargin = false;
    }
  }
  public groups: any[] = [];
  removeHtmlTags(text: string): string {
    text = text.replace(/<br\s*\/?>/gi, '\n'); // Replace <br> with newline
    text = text.replace(/<\/p>/gi, '\n');      // Replace closing <p> tags with newline
    text = text.replace(/<p>/gi, '\n');        // Replace opening <p> tags with newline
    return text.replace(/<[^>]*>/g, '');       // Remove remaining HTML tags
  }

  computeDiff(): void {
    const previousText = this.removeHtmlTags(this.PreviouseHTML);
    const currentText = this.removeHtmlTags(this.CurrentHTML);
    switch (this.mode) {
      case 'chars':
        this.groups = Diff.diffChars(previousText, currentText);
        break;
      case 'words':
        this.groups = Diff.diffWords(previousText, currentText);
        break;
      default:
        this.groups = [];
    }
  }

  getStyle(group: any): any {
    if (group.added) {
      return { color: 'green', backgroundColor: '#b5efdb' };
    }
    if (group.removed) {
      return { color: 'red', backgroundColor: '#fec4c0' };
    }
    // Default style
    return {};
  }
}
