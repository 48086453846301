import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateHelper } from 'src/app/Common/Helpers/date.helper';
import { PublishControlDocumentServices } from 'src/app/Services/publish-control-document';
import { TitleService } from 'src/app/Services/title.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { globalConstants } from 'src/constants/global-constants';

@Component({
  selector: 'app-publish-control-document',
  templateUrl: './publish-control-document.component.html',
  styleUrls: ['./publish-control-document.component.scss'],
  providers: [
    HasPermissionPipe,
  ],
})
export class PublishControlDocumentComponent implements OnInit {
  isLoading: boolean = false;
  route :any;
  columns: Partial<Column>[] = [];
  detailColumns: Partial<Column>[] = [];
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  pageSize: number = globalConstants.defaultPageSize;
  pageNumber: number = globalConstants.defaultPageNumber;
  totalItems: number;
  showRevisionHistory: boolean = false;
  version:any;
  
  constructor(private publishDocumentServices: PublishControlDocumentServices, private router: Router,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private datePipe: DatePipe,
    private dateHelper: DateHelper,
    private titleService: TitleService,
    private modalService: NgbModal) {
    this.titleService.setTitle('Published Control Documents');
  }
  closeResult: string;

  initColumns() {
    this.columns = [
      {
        label: 'Published Document Name',
        elementAttribute: 'controlDocumentName',
        columnClass: 'matters-TypeWidth',
        isLink: false,
        columnWidth: '200px',
      },
      {
        label: 'Assignees',
        elementAttribute: 'userNames',
        columnClass: 'matters-TypeWidth',
        isLink: false,
        columnWidth: '200px',
      },
      {
        label: 'Description',
        elementAttribute: 'description',
        columnClass: 'matters-TypeWidth',
        isLink: false,
        columnWidth: '200px',
      },
      {
        label: 'Published Date',
        elementAttribute: 'publishedDate',
        columnClass: 'matters-TypeWidth',
        isLink: false,
        columnWidth: '200px',
        formatFn: (date: Date) => {

          let dateString = this.datePipe.transform(date, "MMM d, y, h:mm:ss a");
          if (dateString) {
            return dateString;
          } else {
            return "";
          }
        },
      },
      {
        iconColumn: true,
        elementAttribute: "positionActionIcon",
        columnWidth: "100px",
      },
    ];
  }
  
  documents:any;
  documentDetails:any;
  faStar = faStar;
  faPlus = faPlus;
  publishDocumentId: any;
  isPublishDocumentDetail: boolean = false;
  heading = 'Publish Documents';
  id:any;
  subheading = 'Publish Documents Inside System';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id) {
        this.documentDetail({id:this.id})
        this.handleMissingId();
      } else {
        console.log('ID not found in paramMap');
        // Perform alternative action
        this.handleMissingId();
      }
    });

  }
  handleMissingId(){
    this.fetchDocuments();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }
  fetchDocuments() {
    this.isLoading = true;
    var pagingParamsDTO = {
      pageNumber: this.pageNumber,
      pageSize: this.pageSize
    }
    return this.publishDocumentServices.getAll(pagingParamsDTO).subscribe(data => {
      let dateProperties = ['publishedDate'];
      this.documents = this.dateHelper.applyOffsetToDateTimeProperties(data.publishDocumentDTOList, dateProperties);
      this.totalItems = data.count;
      this.isLoading = false;
    })
  }
  closeDetailPopup(){
    this.isPublishDocumentDetail= false;
  }
  documentDetail(e){
    this.isLoading = true;
    let role = localStorage.getItem('roles');
    this.publishDocumentId = e.id
    if(role == 'Admin'){
      this.fetchDocumentDetails(e.id);
    }
    else{
      // this.publishDocumentServices.readAssignedDocument(e.id).subscribe(data => {
      // })
      this.fetchDocumentDetails(e.id);
      this.showRevisionHistory = true
    }
    this.isLoading = false;
  }
  viewPublishedDocument(){
    this.showRevisionHistory = true

  }
  editPublishDocument(event: any) {
    this.router.navigate(['/Documentation/CreatePublishDocument/' + event.id])
  }
  fetchDocumentDetails(id){
  this.isLoading = true;
    return this.publishDocumentServices.getDetail(id).subscribe(data => {
      this.documentDetails = data;
      this.isPublishDocumentDetail = true;
      this.isLoading = false;
    })
  }

  onClose(event) {
    this.showRevisionHistory = false;
    this.isPublishDocumentDetail = false;
  }

  pageChangeHandler(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.previousPageIndex < event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.pageIndex + 1;
    } else if (event.previousPageIndex > event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.previousPageIndex;
    }
    this.isLoading = true;
    this.fetchDocuments();
  }
  ngOnDestroy() {
    this.titleService.setTitle('');
  }
}
