import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class InternalControlTypeService {

  constructor(private http : HttpClient) { }
  errorControlTypebasePath = environment.apiUrl + 'InternalControlType';
  getAll(): any {
    return this.http.get<any>(this.errorControlTypebasePath + '/All')
}

get(id) : any {
    return this.http.get<any>(this.errorControlTypebasePath + '/Get/' + id);
}

create(body): any {
    return this.http.post<any>(this.errorControlTypebasePath + '/Create', body);
}

update(body): any {
    return this.http.post<any>(this.errorControlTypebasePath + '/Update', body);
}

delete(id): any {
    return this.http.delete<any>(this.errorControlTypebasePath + '/Delete/'+id);
}
}
