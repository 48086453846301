<div [ngClass]="{
	'marginLeft': addMargin && !globals.toggleSidebar}
" [class]="'Pop-Up-Box pop-up-box'">
    <div class="Pop-Up-Header">
        <span (click)="closePopUp(false)" class="material-icons">highlight_off</span>
        <h1>Control Details</h1>
        <div class="Pop-Up-Edit-Button">
            <!-- <button class="Edit-Button btn" (click)="getEditUrl(false)">Edit</button> -->
        </div>
    </div>
    <hr class="HR">
    <div class="container">
        <span *ngFor="let group of groups">
            <span [ngStyle]="getStyle(group)" [innerHTML]="group.value"></span>
        </span>
    </div>
</div>