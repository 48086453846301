import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MaturityService } from 'src/app/Services/maturity.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-maturity',
  templateUrl: './maturity.component.html',
  styleUrls: ['./maturity.component.sass'],
  providers: [HasPermissionPipe]
})
export class MaturityComponent implements OnInit {

  heading = "Maturity";
  subheading = "Maturity that you will be adding inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  constructor(
    private maturityService: MaturityService,
    private router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private hasPermission: HasPermissionPipe
  ) {}

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  maturityId: any;
  route: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionDeleteIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionEditIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Level",
      elementAttribute: "level",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    
  ];

  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;

  }

  getAll() {
    this.maturityService.getAll().subscribe((res) => {
      this.data = res;
      console.log(res)
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Audit/Edit-Maturity/" + event.id,
    ]);
  }

  onDelete(event) {
    this.maturityId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.maturityService.delete(this.maturityId).subscribe((data) => {
          this.getAll();
          this.modalService.dismissAll();
        });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }


}
