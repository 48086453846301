import { Injectable } from "@angular/core";
import { UserService } from "src/app/Services/user-services";
import { environment } from "../../../src/environments/environment";


@Injectable({
    providedIn: "root",
})
export class LogoutServices {

    constructor(
        private userService: UserService
    ) { }
    logout() {
        this.userService.Logout().subscribe(() => { });
        localStorage.clear();
        const logoutUrl = environment.logoutUrl;
        window.location.href = logoutUrl;

    }
}
