import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { CisAssetTypeService } from "src/app/Services/CIS-AssetType.services";
import { CisLevelService } from "src/app/Services/cis-level.service";
import { ControlFunctions } from "src/app/Services/control-functions.service";
import { ControlTypeService } from "src/app/Services/control-type.service";
import { DocumentService } from "src/app/Services/document.service";
import { PrimaryObjectivesService } from "src/app/Services/primary-objectives.service";
import { ScfDomainsService } from "src/app/Services/scfDomains.service";
import { SuggestedControlService } from "src/app/Services/suggested-controls.service";
import { ChapterService } from "src/app/Services/chapter.service";
import { ListHelper } from "src/app/ListHelperCLasses/ListHelper";
import { ControlModel } from "src/app/Models/ControlModel";
import { ControlService } from "src/app/Services/control.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/Services/notification.service";
import { SuggestedControlOwnershipService } from "src/app/Services/suggested-control-ownership.service";
import { Location } from "@angular/common";
import {
  linkQuestionModel,
  QuestionsViewModel,
  QuestionViewModelCLass,
} from "src/app/Models/Audit.Models";

import {
  hasInvalidControl,
  markFormGroupTouched,
} from "src/app/Helpers/FormHelper";
import { QuestionService } from "src/app/Services/question.service";
import Swal from "sweetalert2";
import { TitleService } from "src/app/Services/title.service";

@Component({
  selector: "app-question",
  templateUrl: "./question.component.html"

})
export class QuestionComponent implements OnInit {
  chapters = new UntypedFormControl("");
  DocumentFormControl = new UntypedFormControl();
  ControlTypeFormControl = new UntypedFormControl("");
  CisAssetFormControl = new UntypedFormControl("");
  CislevelFormControl = new UntypedFormControl("");
  PrimaryObjectiveFormControl = new UntypedFormControl("");
  ControlFunctionFormControl = new UntypedFormControl("");
  SuggestedControlFormControl = new UntypedFormControl("");
  ScfDomainsFormControl = new UntypedFormControl("");
  SuggestedControlOwnershipFormControl = new UntypedFormControl("");
  redirectOption: boolean = true;
  addControlId = null;
  addChapterId = null;
  addDocument = null;
  enableOnEdit: boolean = true;
  lblDescription: string = "Description";
  lblPredefinedSuggestedAction: string = "Predefined Suggested Action";

  backButtonUrl : string ="/Audit/QuestionList";

  @Input() set auditId(value: any) {
    if (value) {
      this.documentlist = null
      this.documentService.getDocumentListForAuditQuestion(value).subscribe(res => {
        this.documentlist = res;
        console.log(res)
        console.log("related document ids")
      });
    }
  }
  @Input() set AddQuestionDataOnPopUp(value: any) {
    if (value) {
      if (value.questionId != null) {
        this.onQuestionEdit({ id: value.questionId }, true);
        this.redirectOption = value.redirectOption;
        this.questionId = value.questionId;
        this.updateMode = true;
      } else {
        this.questionForm.get("documentId").setValue(value.documentId);
        this.loadChapter();
        this.questionForm.get("chapterId").setValue(value.chapterId);
        this.loadControls();
        this.questionForm.get("controlId").setValue(value.controlId);
        this.redirectOption = value.redirectOption;
        this.addControlId = value.controlId;
        this.addChapterId = value.chapterId;
        this.addDocument = value.documentId;
      }
    }
    this.isLoading = false;
  }
  @Output() isQuestionCreated = new EventEmitter<boolean>();
  @Output() isQuestionEdited = new EventEmitter<boolean>();
  @Output() isClosed = new EventEmitter<boolean>();
  @Input() inPopup: boolean = false;
  chapterId: string;
  chaptersList: ListHelper[] = [];
  ControlsList: ListHelper[] = [];

  description: string;
  predefinedSuggestAction: string;
  isLoading = false;
  controlModel: ControlModel = new ControlModel();

  controlId: string;
  controlForm: UntypedFormGroup;
  doctypelist: any;
  documentlist: any;
  controlTypelist: any;
  cisAssetTypelist: any;
  cisLevellist: any;
  controlFunctionlist: any;
  scfdomainslist: any;
  primaryObjectiveslist: any;
  suggestedControlOwnershiplist: any;
  suggestedControllist: any;
  updateMode = false;
  documentId: string;
  addQuestion: boolean = false;
  linkQuestion: boolean = false;
  chaptersIds: string[] = [];
  controltypeids: string[] = [];
  cisassettypeids: string[] = [];
  controlfunctionids: string[] = [];
  cislevelids: string[] = [];
  primaryobjectiveids: string[] = [];
  suggestedcontrolids: string[] = [];
  suggestedcontrolownershipids: string[] = [];
  scfdomainids: string[] = [];
  Questions: QuestionsViewModel[] = [];
  CreateQuestion: QuestionViewModelCLass = new QuestionViewModelCLass();
  questionForm: UntypedFormGroup;
  Controls = [];
  questionId: any;
  linkedQuestions = [];
  descriptioncreated = false;
  isQuestionAddedFromAudit: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private documentService: DocumentService,
    private controlTypeService: ControlTypeService,
    private cisAssetTypeService: CisAssetTypeService,
    private cisLeveService: CisLevelService,
    private controlFunctionService: ControlFunctions,
    private primayObjectiveService: PrimaryObjectivesService,
    private suggestedControlService: SuggestedControlService,
    private scfDomainService: ScfDomainsService,
    private chapterService: ChapterService,
    private controlService: ControlService,
    private router: Router,
    private route: ActivatedRoute,
    private suggestedControlOwnershipService: SuggestedControlOwnershipService,
    private locationService: Location,
    private notify: NotificationService,
    private questionService: QuestionService,
    private titleService: TitleService
  ) {
    this.loadInitialData();
    this.questionForm = this.formBuilder.group({
      documentId: ["", [Validators.required]],
      chapterId: ["", [Validators.required]],
      controlId: ["", [Validators.required]],
      Question: ["", [Validators.required]],
    });
    this.questionForm.valueChanges.subscribe((data) => {
      if (!this.questionId) {
        this.questionService.getQuestionId().subscribe((res) => {
          this.questionId = res;
          console.log(res);
        });
      }
    });
  

  }
  onCreate(event) {

  }
  save() {
    if (this.questionForm.invalid || hasInvalidControl(this.questionForm)) {
      markFormGroupTouched(this.questionForm);
    } else {
      this.isLoading = true;
      this.createQuestionModel();
      this.createOrEdit();
    }
  }

  createOrEdit() {
    if (this.updateMode) {
      this.questionService.update(this.CreateQuestion).subscribe(
        (data) => {
          this.isLoading = false;
          this.enableOnEdit = false
          this.Questions.forEach((q, i) => {
            if (q.id == this.questionId) {
              this.Questions[i] = data;
              this.updateMode = false;
              this.linkedQuestions = [];
              this.description = null;
              this.predefinedSuggestAction = null;
              this.questionForm.reset();
              console.log(this.redirectOption);
              if (!this.redirectOption) {
                this.questionForm
                  .get("documentId")
                  .setValue(this.addDocument);
                this.questionForm
                  .get("chapterId")
                  .setValue(this.addChapterId);
                this.questionForm
                  .get("controlId")
                  .setValue(this.addControlId);
              }
              this.controltypeids = [];
              this.primaryobjectiveids = [];
              this.controlfunctionids = [];
              this.cisassettypeids = [];
              this.suggestedcontrolids = [];
              this.scfdomainids = [];
              this.suggestedcontrolownershipids = [];
              this.cislevelids = [];
              this.questionId = null;

              console.log(data);
            }
          });
        },
        (error) => {
          this.isLoading = false;
          this.isQuestionEdited.emit(false);
        }
      );
    } else {
      this.questionService.create(this.CreateQuestion).subscribe(
        (res) => {
          this.Questions.push(res);
          this.isLoading = false;
          this.questionForm.reset();
          console.log(this.redirectOption);
          if (!this.redirectOption) {
            this.questionForm.get("documentId").setValue(this.addDocument);
            this.questionForm.get("chapterId").setValue(this.addChapterId);
            this.questionForm.get("controlId").setValue(this.addControlId);
          }
          this.description = "";
          this.predefinedSuggestAction = "";
          this.controltypeids = [];
          this.primaryobjectiveids = [];
          this.controlfunctionids = [];
          this.cisassettypeids = [];
          this.suggestedcontrolids = [];
          this.scfdomainids = [];
          this.suggestedcontrolownershipids = [];
          this.cislevelids = [];
          this.questionId = null;
          this.linkedQuestions = [];
          this.isQuestionAddedFromAudit = true;


        },
        (error) => {
          this.isLoading = false;
        }
      );
    }
  }
  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      if (data.id) {
        this.questionId = data.id;
        this.onQuestionEdit({ id: data.id }, false);
        if (!this.inPopup) {
          this.titleService.setTitle("Edit Question");
          this.titleService.setBackButtonUrl(this.backButtonUrl);

        }
      }
      else{
        if (!this.inPopup) {
          this.titleService.setTitle("New Question");
          this.titleService.setBackButtonUrl(this.backButtonUrl);
        }
      }

    });
   
  }
  ngOnDestroy(){
    if (!this.inPopup) {
      this.titleService.setTitle("");
      this.titleService.setBackButtonUrl('');
    }
  }
  onClose(event) {
    this.linkQuestion = false;
  }


  loadInitialData() {
    this.isLoading = true;
    this.descriptioncreated = true;



    this.documentService.getList().subscribe((result) => {
      this.documentlist = result;
    });


    this.controlTypeService.getAll().subscribe((result) => {
      this.controlTypelist = result;
    });


    this.cisAssetTypeService.getAll().subscribe((result) => {
      this.cisAssetTypelist = result;
    });


    this.cisLeveService.getAllCISLevels().subscribe((result) => {
      this.cisLevellist = result;
    });



    this.controlFunctionService.getAll().subscribe((result) => {
      this.controlFunctionlist = result;
    });



    this.primayObjectiveService.getAll().subscribe((result) => {
      this.primaryObjectiveslist = result;
    });



    this.suggestedControlService.getAll().subscribe((result) => {
      this.suggestedControllist = result;
    });



    this.suggestedControlOwnershipService.getAll().subscribe((result) => {
      this.suggestedControlOwnershiplist = result;
    });



    this.scfDomainService.getAll().subscribe((result) => {
      this.scfdomainslist = result;
      this.isLoading = false;
    });
  }

  createQuestionModel() {

    if (this.controltypeids.length > 0) {
      this.controltypeids.forEach((row) => {
        this.CreateQuestion.controlTypeIds.push(row);
      });
    }


    if (this.cisassettypeids.length > 0) {
      this.cisassettypeids.forEach((row) => {
        this.CreateQuestion.cisAssetTypeIds.push(row);
      });
    }


    if (this.controlfunctionids.length > 0) {
      this.controlfunctionids.forEach((row) => {
        this.CreateQuestion.controlFunctionIds.push(row);
      });
    }

    if (this.primaryobjectiveids.length > 0) {
      this.primaryobjectiveids.forEach((row) => {
        this.CreateQuestion.PrimaryObjectiveIds.push(row);
      });
    }

    if (this.suggestedcontrolids.length > 0) {
      this.suggestedcontrolids.forEach((row) => {
        this.CreateQuestion.suggestedControlIds.push(row);
      });
    }

    console.log(this.suggestedcontrolownershipids)
    if (this.suggestedcontrolownershipids.length > 0) {
      this.suggestedcontrolownershipids.forEach((row) => {
        this.CreateQuestion.scoIds.push(row);
      });
    }

    console.log(this.CreateQuestion.scoIds)

    if (this.scfdomainids.length > 0) {
      this.scfdomainids.forEach((row) => {
        this.CreateQuestion.scfDomainIds.push(row);
      });
    }


    if (this.cislevelids.length > 0) {
      this.cislevelids.forEach((row) => {
        this.CreateQuestion.cisLevelIds.push(row);
      });
    }

    this.CreateQuestion.chapterId = this.questionForm.get("chapterId").value;
    this.CreateQuestion.documentId = this.questionForm.get("documentId").value;
    this.CreateQuestion.controlId = this.questionForm.get("controlId").value;
    this.CreateQuestion.question = this.questionForm.get("Question").value;
    this.CreateQuestion.description = this.description;
    this.CreateQuestion.predefinedSuggestAction = this.predefinedSuggestAction;
    this.CreateQuestion.id = this.questionId;
  }

  linkQuestionfunc(event: linkQuestionModel) {
    console.log(event);
    event.questionId = this.questionId;
    this.questionService.linkQuestion(event).subscribe((res) => {
      this.linkedQuestions = res;
    });
  }
  deleteLinkedQuestion(event: linkQuestionModel) {
    this.questionService.deleteLinkQuestion(event).subscribe((res) => {
      this.linkedQuestions = res;
    });
  }
  showlinkQuestionComponent() {
    this.linkQuestion = true;
  }
  showAddQuestionFields() {
    this.addQuestion = true;
  }


  loadChapter(updateChapterValue = null, updateControlValue = null) {
    this.chapterService
      .getLeafChilds(this.questionForm.get("documentId").value)
      .subscribe((res) => {
        this.chaptersList = res;
        if (updateChapterValue) {
          this.questionForm.patchValue({
            chapterId: updateChapterValue,
          });
        }
        this.loadControls(updateControlValue);
      });
  }
  loadControls(updateControlValue = null) {
    this.controlService
      .getControlByChapterId(this.questionForm.get("chapterId").value)
      .subscribe((res) => {
        this.ControlsList = res;
        if (updateControlValue) {
          this.questionForm.patchValue({
            controlId: updateControlValue,
          });
        }
      });
  }
  onQuestionDelete(Question) {
    console.log(Question);
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.questionService.delete(Question.id).subscribe((res) => {
          this.Questions = this.Questions.filter((x) => x.id != Question.id);
          console.log("=-------------------------=");
          console.log(this.Questions);
          Swal.fire("Deleted!", "Selected record has been deleted.", "success");
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
  onQuestionEdit(Question, updateMode = true) {
    this.questionService.get(Question.id).subscribe(
      (res) => {
        this.isLoading = true;
        this.updateMode = updateMode;
        this.questionId = Question.id;
        this.linkedQuestions = res.linkedQuestions;
        this.description = res.description;
        this.predefinedSuggestAction = res.predefinedSuggestAction;
        this.questionForm.patchValue({
          documentId: res.documentId,
          Question: res.question,
        });
        this.loadChapter(res.chapterId, res.controlId);
        this.controltypeids = res.controlTypeIds.map((x) => x.id);
        this.primaryobjectiveids = res.primaryObjectiveIds.map((x) => x.id);
        this.controlfunctionids = res.controlFunctionIds.map((x) => x.id);
        this.cisassettypeids = res.cisAssetTypeIds.map((x) => x.id);
        this.suggestedcontrolids = res.suggestedControlIds.map((x) => x.id);
        this.scfdomainids = res.scfDomainIds.map((x) => x.id);
        this.suggestedcontrolownershipids = res.scoIds.map((x) => x.id);
        this.cislevelids = res.cisLevelIds.map((x) => x.id);
        this.isLoading = false;
        console.log(res);
        this.descriptioncreated = true;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }
  saveAll() {
    if (this.updateMode) {
      this.isQuestionEdited.emit(true);
      return;
    }
    let list = this.Questions.map((d) => d.id);
    if (list.length > 0) {
      this.questionService.drafterdToActive(list).subscribe(
        (data) => {
          if (this.redirectOption) {
            this.router.navigate(["Audit/QuestionList"]);
          } else {
            this.isQuestionCreated.emit(true);
          }
        },
        (error) => {
          this.isQuestionCreated.emit(false);
        }
      );
    }

  }

  cancel() {
    if (!this.redirectOption) {
      if (this.isQuestionAddedFromAudit) {
        let list = this.Questions.map((d) => d.id);
        this.questionService.drafterdToActive(list).subscribe(
          (data) => { },
          (error) => {
            this.isQuestionCreated.emit(false);
          }
        );
        this.isQuestionCreated.emit(true);
      }
      this.isClosed.emit(true);
      return;
    }
    this.router.navigate(["/Audit/QuestionList"]);
  }
  descriptionChange($event) {
    this.description = $event;
  }
  createdescription(event: any) {
    let html = this.description;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }
  predefinedSuggestActionChange($event) {
    this.predefinedSuggestAction = $event;
  }
  CreatepredefinedSuggestAction(event: any) {
    let html = this.predefinedSuggestAction;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }

}
