import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { ProgressStatus, ProgressStatusEnum } from 'src/app/Common/models/progress-status.model';
import { FileService } from 'src/app/Services/file.services';

@Directive({
  selector: '[appDownloadFile]'
})
export class DownloadFileDirective {
  private anchor: HTMLAnchorElement;

  @Input() fileName: string;

  @Output() downloadStatus: EventEmitter<ProgressStatus>;

  constructor(
    private _elementRef: ElementRef<HTMLAnchorElement>,
    private fileService: FileService) {
    this.anchor = _elementRef.nativeElement;
    this.downloadStatus = new EventEmitter<ProgressStatus>();

    this.anchor.addEventListener('click', (event) => {
      event.preventDefault();
      this.download(this.anchor.href)
    });
  }

  public download(fileLink: string) {
    this.downloadStatus.emit({ status: ProgressStatusEnum.START });
    this.fileService.downloadFile(fileLink).subscribe(
      data => {
        switch (data.type) {
          case HttpEventType.DownloadProgress:
            this.downloadStatus.emit({ status: ProgressStatusEnum.IN_PROGRESS, percentage: Math.round((data.loaded / data.total) * 100) });
            break;
          case HttpEventType.Response:
            {
              this.downloadStatus.emit({ status: ProgressStatusEnum.COMPLETE });
              const downloadedFile = new Blob([data.body], { type: data.body.type });
              const a = document.createElement('a');
              a.setAttribute('style', 'display:none;');
              document.body.appendChild(a);
              a.download = this.fileName;
              a.href = URL.createObjectURL(downloadedFile);
              a.target = '_blank';
              a.click();
              document.body.removeChild(a);
              break;
            }
          default:
        }
      },
      error => {
        this.downloadStatus.emit({ status: ProgressStatusEnum.ERROR });
      }
    );
  }
}
