import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RevisionHistoryService {

  constructor(private http: HttpClient) {}
  revisionHistorybasePath = environment.apiUrl + "RevisionHistory";

  get(body): any {
    return this.http.post<any>(this.revisionHistorybasePath + "/GetControlDocumentDetailsWithVersionHistory",body);
  }

  getControlDocumentOldVersion(body) : any {
    return this.http.post<any>(this.revisionHistorybasePath + '/GetControlDocumentOldVersion',body);
}
}
