const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_YcW6CaPEW",
    "aws_user_pools_web_client_id": "7imlppvjp6160jdmnlvongm4e",
    "oauth": {
        "domain": "atlasqa.auth.eu-north-1.amazoncognito.com",
        "scope": ['openid', 'aws.cognito.signin.user.admin', 'profile'],
        "redirectSignIn": "https://atlasdev.kiskane.se",
        "redirectSignOut": "https://atlasdev.kiskane.se",
        "responseType": "code"
    }
};

export default awsmobile;
