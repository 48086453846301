<ng-container (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeTableSettingPopup();">
        <div class="pop-up-box ps-3 pe-3 pb-4" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header  pt-4 ">
                <h1>Table Settings</h1>
                <span class="spacer"></span>
                <svg (click)="closeTableSettingPopup()" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </div>
            <hr class="HR">

            <div class="checkbox-list">
                <div *ngFor="let column of overAllColumns">
                    <div class="checkbox-item mt-3">
                      <mat-checkbox [(ngModel)]="column.checked">
                        {{ column.name }}
                      </mat-checkbox>
                    </div>
                    <hr class="HR">
                  </div>
            </div>

            <ng-container>
                <div class="buttons d-flex ms-2 me-2">
                    <button class="btn btn-atlas-cancel btn-table-settings-reset" mat-button (click)="reset()">Reset</button>
                    <button class="btn btn-success btn-atlas btn-table-settings-Apply" mat-button (click)="save()">Apply</button>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
