<ng-container *ngIf="type == 'text'">
    <label for="" class="label-atlas">{{label}}</label>
    <input  [formControl]="control" class="form-control form-control-atlas"  [placeholder]="control.value ? '': placeholder"/>
</ng-container>
<ng-container *ngIf="type == 'selectSingle'">
  <label for="" class="label-atlas">{{label}}</label>
  <select [formControl]="control" class="form-control form-control-atlas" (change)="onChange()">
    <option value="" [disabled]="removeFirstOption"  selected>{{placeholder}}</option>
    <option *ngFor="let option of Options" [value]="option.id">{{option.name}}</option>
  </select>
  <!-- <input  [formControl]="control" class="form-control form-control-atlas"  [placeholder]="control.value ? '': placeholder"/> -->
</ng-container>
<ng-container *ngIf="type == 'chips'">
    <label for="" class="label-atlas">{{label}}</label>
    <div class="custom-chip-list" 
    [ngClass]="{'invalid-tag-input': control.invalid && control.touched}"
    >
        <div class="chip" *ngFor="let email of tags">
          {{ email }}
          <span class="remove-chip" (click)="removeTag(email)">×</span>
        </div>
        <input
          type="text"
          placeholder="Enter email"
          [(ngModel)]="chipInput"
          (keydown.enter)="addTag()"
        />
      </div>
      <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
  </ng-container>
  
<ng-container *ngIf="type == 'htmlEditor'">
    <label for="" class="label-atlas">{{label}}</label>
    <ejs-richtexteditor #rteObj [toolbarSettings]='tools' [height]='height' [(ngModel)]="_descriptionHTMLFormate"
    [class]="'custom-text-editor'"
        [placeholder]="placeholder" (ngModelChange)="change($event)" [ngModelOptions]="{ standalone: true }"
        (created)='onCreate($event)' (actionComplete)='actionCompleteHandler($event)'>
        <ng-template #toolbarTemplate>
            <button ejs-richtexteditorbutton title="Custom">Custom</button>
            <!-- Add other toolbar buttons as needed -->
        </ng-template>
    </ejs-richtexteditor>
  </ng-container>
  <ng-container *ngIf="type == 'multiSelect'">
    <label for="multiselectelement" class="label-atlas">{{label}}</label>
    <ejs-multiselect id='multiselectelement'
                   [dataSource]='Options'
                   [fields]='fields'
                   (filtering)='onFiltering($event)'
                   [mode]='mode'
                   [formControl]="control"
                   [placeholder]='placeholder'>
  </ejs-multiselect>
  </ng-container>

  <ng-container *ngIf="type == 'select'">
    <label for="dropdownElement" class="label-atlas">{{label}}</label>
    <ejs-dropdownlist id='dropdownelement'
                      [dataSource]='Options'
                      [fields]='fields'
                      [formControl]="control"
                      [placeholder]='placeholder'>
    </ejs-dropdownlist>
</ng-container>

  
  <ng-container *ngIf="type == 'File'">
    <div class="position-relative mb-3">
      <app-atlas-files-list [filesList]="uploadedFiles" [visible]="(recordId!=0 && recordId) ? true : false"
        [visibleView]="(recordId!=0 && recordId) ? true : false" [visibleDownload]="(recordId!=0 && recordId) ? true : false"
        [EntityName]="'DPA'"
        [visibleLink]="false" [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
        [isDeletable]="isDeletable.bind(this)" [formGroupData]="formGroupData" [download]="downloadFileUsingLink.bind(this)"></app-atlas-files-list>
        <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"></app-atlas-file-upload>
      </div>
    <!-- <ejs-multiselect id='multiselectelement'
                   [dataSource]='Options'
                   [fields]='fields'
                   (filtering)='onFiltering($event)'
                   [mode]='mode'
                   [placeholder]='placeholder'>
  </ejs-multiselect> -->
  </ng-container>
  <div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%; z-index: 1000;">
    <mat-spinner></mat-spinner>
  </div>
  