<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">User</h5>
                <form [formGroup]="userForm" autocomplete="off">

                    <div class="position-relative mb-1">
                        <mat-form-field appearance="fill" class="d-block">
                            <mat-label>Select Role</mat-label>
                            <mat-select formControlName="roleIds" multiple>
                                <mat-option *ngFor="let role of roles" [value]="role.id">
                                    {{role.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                       
                    </div>
                    <div class="position-relative mb-3">
                        <label for="email" class="form-label">Email</label>
                        <input name="email" id="email" formControlName="email" type="email" placeholder="Email"
                            class="form-control"
                            [ngClass]="{ 'invalid-input': userForm.get('email').invalid && userForm.get('email').touched }"
                            required>
                        <small *ngIf="userForm.get('email').hasError('invalidEmail')" class="text text-danger">
                            Please Provide Proper Email As it is Compulsory
                        </small>
                    </div>
                    <div class="position-relative mb-3" *ngIf="editForm">
                        <label for="firstName" class="form-label">First Name</label>
                        <input name="firstName" id="firstName" formControlName="firstName" type="text"
                            placeholder="First Name" class="form-control"
                            [ngClass]="{ 'invalid-input': userForm.get('firstName').invalid && userForm.get('firstName').touched }"
                            required>
                    </div>


                    <!--Last Name-->
                    <div class="position-relative mb-3" *ngIf="editForm">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input name="lastName" id="lastName" formControlName="lastName" type="text"
                            placeholder="Last Name" class="form-control"
                            [ngClass]="{ 'invalid-input': userForm.get('lastName').invalid && userForm.get('lastName').touched }"
                            required>
                    </div>
                    <button *ngIf="!editForm" type="button" [disabled]="isLoading || submitted"
                        class="mt-1 btn btn-success btn-atlas" (click)="sendInvitation()">Invite</button>

                    <button type="button" *ngIf="editForm" [disabled]="isLoading || submitted"
                        class="mt-1 btn btn-success btn-atlas" (click)="createUser()">Submit</button>
                    <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>