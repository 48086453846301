import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private http: HttpClient) {}
  documentbasePath = environment.apiUrl + "Documents";
  controlsBasePath = environment.apiUrl + "Controls";
  chapterBasePath = environment.apiUrl + "Chapter";
  getAll(): any {
    return this.http.get<any>(this.documentbasePath + "/All");
  }

  get(id): any {
    return this.http.get<any>(this.documentbasePath + "/Get/" + id);
  }

  create(body, file: any): any {
    return this.http.post<any>(this.documentbasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.documentbasePath + "/Update/", body);
  }

  delete(id): any {
    return this.http.post<any>(this.documentbasePath + "/Delete/" + id, {});
  }

  getList(): any {
    return this.http.get<any>(this.documentbasePath + "/GetList");
  }

  getDocumentListForAuditQuestion(auditId): any {
    return this.http.get<any>(this.documentbasePath + "/GetDocumentListForQuestion/" + auditId);
  }
  getControlsCount(documentId): any {
    return this.http.get<any>(
      this.controlsBasePath + "/GetControlsCountForDocument/" + documentId
    );
  }
  getChaptersCount(documentId): any {
    return this.http.get<any>(
      this.chapterBasePath + "/GetChaptersCountForDocument/" + documentId
    );
  }
}
