// superadmin.guard.ts

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class SuperAdminGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const roles = localStorage.getItem('roles')
        const isSuperAdmin = roles == ('SuperAdmin');

        if (isSuperAdmin) {
            return true;
        }

        // If not a superadmin, navigate to a different page or show an error message.
        window.location.href = '/';
        return false;
    }
}
