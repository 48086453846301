import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChapterService } from "src/app/Services/chapter.service";
import { CommonService } from "src/app/Services/common-service.service";
import { ControlService } from "src/app/Services/control.service";
import { DocumentService } from "src/app/Services/document.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { EllipsisPipe } from "src/app/Shared/pipes/ellipsis.pipe";
import Swal from "sweetalert2";

@Component({
  selector: "app-chapter",
  templateUrl: "./chapter.component.html",
  styleUrls: ["./chapter.component.sass"],
  providers: [EllipsisPipe,
    HasPermissionPipe
  ],
})
export class ChapterComponent implements OnInit {
  heading = "Chapters";
  subheading = "Chapters that you will be adding inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  faPlus = faPlus;
  data: any[] = [];
  cols: any[] = [];
  documents: any[] = [];
  isLoading = false;
  controlsCount = 0;
  documentId: string;
  controlsOpened = false;
  controls = [];
  openDetails = false;
  controlDetails = {};
  route :any;
  columns: Partial<Column>[] = [];

  initColumns() {
    this.columns = [
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
        elementAttribute: "positionDeleteIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
      },
      {
        iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
        elementAttribute: "positionEditIcon",
        columnWidth: '2%',
        hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
      },

    {
      label: "Document",
      elementAttribute: "document",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Chapter",
      elementAttribute: "chapter",
      isLink: false,
      columnWidth: "200px",
      formatFn: (chapter: string) =>
        chapter ? this._ellipsisPipe.transform(chapter, 50) : "",
    },
    {
      label: "Title",
      elementAttribute: "title",
      isLink: false,
      columnWidth: "200px",
      formatFn: (title: string) =>
        title ? this._ellipsisPipe.transform(title, 50) : "",
    },
    {
      label: "Objective",
      elementAttribute: "objective",
      isLink: false,
      columnWidth: "200px",
      formatFn: (objective: string) =>
        objective ? this._ellipsisPipe.transform(objective, 50) : "",
    },
    {
      label: "Description",
      elementAttribute: "description",
      isLink: false,
      columnWidth: "200px",
      formatFn: (description: string) =>
        description ? this._ellipsisPipe.transform(description, 50) : "",
    },
    {
      label: "Other Information",
      elementAttribute: "otherInformation",
      isLink: false,
      columnWidth: "200px",
      formatFn: (description: string) =>
        description ? this._ellipsisPipe.transform(description, 50) : "",
    },
  ];
}
  onClose(event) {
    this.openDetails = false;
  }
  constructor(
    private chapterService: ChapterService,
    private documentServices: DocumentService,
    private modalService: NgbModal,
    private router: Router,
    private commonService: CommonService,
    private locationService: Location,
    private controlServices: ControlService,
    private _ellipsisPipe: EllipsisPipe,
    private hasPermissionPipe: HasPermissionPipe,
    private activeRoute: ActivatedRoute,

  ) { }
  ViewedChapter = "";
  getEditUrl(event: any) {
    this.router.navigate(["/Dashboard/Setup/createControl/" + event.id]);
  }
  closeChapterControls() {
    this.controlsOpened = false;
  }
  ngOnInit(): void {
    this.documentId = this.commonService.documentId;
    this.route = this.activeRoute.snapshot;
    this.getDocuments();
    this.cols = [
      { field: "title", header: "Title", subField: "controlsCount", Id: "id" },
    ];
    this.initColumns();
  }
  OpenControlDetails(event) {
    let controlId = event.id;
    this.controlServices.get(controlId).subscribe((data) => {
      this.openDetails = true;
      this.controlDetails = data;
    });
  }

  getChapterControls(chapterId: any, title: any) {
    this.isLoading = true;
    this.controlsOpened = true;
    this.ViewedChapter = title;
    this.controlServices.AllForChapters(chapterId).subscribe((data) => {
      this.controls = data;
      this.isLoading = false;
    });
  }
  changeDocument(event) {
    this.commonService.documentId = event.target.value;
    this.documentId = event.target.value;
    this.getAll(event.target.value);
  }

  Edit(id: string) {
    this.chapterService.get(id).subscribe((res) => {
      this.commonService.documentId = res.documentId;
    });
    this.router.navigate(["/Dashboard/Setup/create-or-edit-chapter/" + id]);
  }
  docu: UntypedFormControl = new UntypedFormControl();

  getDocuments() {
    this.isLoading = true;
    this.documentServices.getAll().subscribe((data) => {
      this.documents = data;
      this.isLoading = false;
      if (this.documentId) {
        this.getAll(this.documentId);
        this.docu.setValue(this.documentId);
      } else if (this.documents.length != 0) {
        this.documentId = this.documents[0].id;
        this.getAll(this.documents[0].id);
        this.docu.setValue(this.documents[0].id);
      }

    });
  }
  MoveBack() {
    this.locationService.back();
  }
  getAll(documentId) {
    this.isLoading = true;
    this.chapterService.getAll(documentId).subscribe((res) => {
      this.data = res.parentChildList;
      this.controlsCount = res.count;
      this.isLoading = false;
    });
  }
  Navigate() {
    this.router.navigate(["/Dashboard/Setup/create-or-edit-chapter"], {
      queryParams: { docId: this.documentId },
    });
  }
  onDelete(id: string) {
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.chapterService.delete(id).subscribe((data) => {
          if (this.documentId) {
            this.getAll(this.documentId);
          } else {
            this.getDocuments();
          }
          this.modalService.dismissAll();
        });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
}
