import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { DiffEditorModel } from "ngx-monaco-editor";
import { CommonFunction } from "src/app/Shared/commen/commen-function";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-control-documents-details",
  templateUrl: "./control-documents-details.component.html",
  styleUrls: ["./control-documents-details.component.scss"],
})
export class ControlDocumentsDetailsComponent implements OnInit {
  description: SafeHtml = "";
  compareVersionIdFirst: string = "";
  compareVersionIdSecond: string = "";
  historyDocumentationModel: boolean = false;
  versionModelPopUp: boolean = true;

  isLoading: boolean = false;
  AllAuditTrailsList = [];
  @Input() onClose: (closeboth) => {};
  @Input() selectedEvent: any = {};
  controlDocumentDetails: any = null;
  @Input() set event(value: any) {
    if (value) {
      this.controlDocumentDetails = JSON.parse(value?.newValue);
      this.controlDocumentDetails.Status = value.status;
      this.controlDocumentDetails.Version = value.version;
      this.controlDocumentDetails.CategoriesControlDocumentName =
        value.categoriesControlDocumentList?.find(
          (e) => e.id == this.controlDocumentDetails.CategoriesControlDocumentId
        )?.name;
      this.controlDocumentDetails.documentOwner = value.documentOwner;
      this.controlDocumentDetails.lastUpdatedBy = value.lastUpdatedBy;
      this.controlDocumentDetails.createdDate = this.datePipe.transform(
        value.createdDate,
        "MMM d, y, h:mm:ss a"
      );
      this.controlDocumentDetails.DescriptionHTMLFormate = value.descriptionHTMLFormate;

      this.AllAuditTrailsList = value?.controldocumentAuditTrails;
      this.ref.detectChanges();
    }
  }

  getEditUrl(event: any) {
    this.router.navigate(["/Audit/Question/" + this.event.id]);
  }
  constructor(
    private router: Router,
    protected _sanitizer: DomSanitizer,
    private ref: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.showDocumentControlHistoryModel(false);
    this.versionModelPopUpShow(false);
  }

  ////-------------------------------------------------

  descriptionChange($event) {
    this.description = $event.html;
  }
  createdescription(event: any) {
    let html = this.description;
    if (html != null) {
      event.root.innerHTML = html;
    }
  }

  showDocumentControlHistoryModel(isShow: boolean) {
    this.historyDocumentationModel = isShow;
  }
  versionModelPopUpShow(isShow: boolean) {
    this.versionModelPopUp = isShow;
  }
  CompareVersion() {
    let obj1 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdFirst)
        ?.newValue
    );
    let obj2 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdSecond)
        ?.newValue
    );
    if (obj1 && obj2) {
      this.text1 = CommonFunction.removeTags(obj1.Description);
      this.text2 = CommonFunction.removeTags(obj2.Description);
      this.onCompare();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////

  text1 = "";
  text2 = "";
  isCompared = true;

  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";

  @Input()
  languages = ["plaintext", "postiats"];

  @Input()
  themes = [
    {
      value: "vs",
      name: "Default",
    },
    {
      value: "vs-dark",
      name: "Dark",
    },
    {
      value: "hc-black",
      name: "High Dark",
    },
  ];

  // input
  inputOptions = { theme: "vs", language: "plaintext" };
  // compare, output
  diffOptions = {
    theme: "vs",
    language: "plaintext",
    readOnly: true,
    renderSideBySide: true,
  };
  originalModel: DiffEditorModel = {
    code: "",
    language: "plaintext",
  };

  modifiedModel: DiffEditorModel = {
    code: "",
    language: "plaintext",
  };

  onChangeLanguage(language) {
    this.inputOptions = {
      ... this.inputOptions,
      language: language,
    }
    this.originalModel = {
      ... this.originalModel,
      language: language,
    }
    this.modifiedModel = {
      ... this.modifiedModel,
      language: language,
    }
  }
  onChangeTheme(theme) {
    this.inputOptions = { ... this.inputOptions, theme: theme }
    this.diffOptions = { ... this.diffOptions, theme: theme }
  }

  onChangeInline(checked) {
    this.diffOptions = {
      ... this.diffOptions,
      renderSideBySide: !checked,
    }
  }

  onCompare() {
    this.originalModel = {
      ... this.originalModel,
      code: this.text1,
    }
    this.modifiedModel = {
      ... this.originalModel,
      code: this.text2,
    }
    this.isCompared = true;
    this.versionModelPopUpShow(true);

  }

}
