import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ControlModel } from "src/app/Models/ControlModel";
import { ChapterService } from "src/app/Services/chapter.service";
import { CommonServices } from "src/app/Services/commonServices";
import { ControlService } from "src/app/Services/control.service";
import { DocumentService } from "src/app/Services/document.service";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { EllipsisPipe } from "src/app/Shared/pipes/ellipsis.pipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-controls",
  templateUrl: "./controls.component.html",
  styleUrls: ["./controls.component.scss"],
  providers: [EllipsisPipe, HasPermissionPipe],
})
export class ControlsComponent implements OnInit {
  heading = "Controls";
  openDetails = false;
  controlDetails = {};
  showfilter = false;
  route: any;
  columns: Partial<Column>[] = [];
  subheading = "Control Types that you will be adding inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  initColumns() {
    this.columns = [
      // {
      //   iconColumn: true,
      //   elementAttribute: "positionOpenIcon",
      //   columnWidth: "2%",
      // },
      // {
      //   iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
      //   elementAttribute: "positionDeleteIcon",
      //   columnWidth: '2%',
      //   hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
      // },
      // {
      //   iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
      //   elementAttribute: "positionEditIcon",
      //   columnWidth: '2%',
      //   hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
      // },
      {
        label: "Document",
        elementAttribute: "document",
        isLink: false,
        columnWidth: "200px",
      },
      {
        label: "Chapter",
        elementAttribute: "chapter",
        isLink: false,
        columnWidth: "200px",
        formatFn: (chapter: string) =>
          chapter ? this._ellipsisPipe.transform(chapter, 50) : "",
      },
      {
        label: "Title/Control",
        elementAttribute: "title",
        isLink: false,
        columnWidth: "200px",
        formatFn: (title: string) =>
          title ? this._ellipsisPipe.transform(title, 50) : "",
      },
      {
        label: "Objective",
        elementAttribute: "objective",
        isLink: false,
        columnWidth: "200px",
        formatFn: (objective: string) =>
          objective ? this._ellipsisPipe.transform(objective, 50) : "",
        isHtml: true,
      },
      {
        label: "Description",
        elementAttribute: "description",
        isLink: false,
        columnWidth: "200px",
        formatFn: (description: string) =>
          description ? this._ellipsisPipe.transform(description, 50) : "",
        isHtml: true,
      },
      {
        label: "Other Information",
        elementAttribute: "otherInformation",
        isLink: false,
        columnWidth: "200px",
        formatFn: (description: string) =>
          description ? this._ellipsisPipe.transform(description, 50) : "",
        isHtml: true,
      },
      {
        iconColumn: true,
        elementAttribute: "positionActionIcon",
        columnWidth: "100px",
      },
    ];
  }
  modalService: any;
  constructor(
    private chapterService: ChapterService,
    private controlServices: ControlService,
    private documentServices: DocumentService,
    private commonServices: CommonServices,
    private _ellipsisPipe: EllipsisPipe,
    private router: Router,
    private hasPermissionPipe: HasPermissionPipe,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService

  ) {
    this.titleService.setTitle("Controls");
  }
  onClose(event) {
    this.openDetails = false;
    this.showfilter = false;
  }
  @HostListener("document:keydown.escape") close() {
    this.openDetails = false;
    this.showfilter = false;
  }
  OpenControlDetails(event) {
    let controlId = event.id;
    this.controlServices.get(controlId).subscribe((data) => {
      this.openDetails = true;
      this.controlDetails = data;
    });
  }
  Navigate() {
    this.router.navigate(["/Dashboard/Setup/createControl"], {
      queryParams: { docId: this.currentDocumentId },
    });
  }
  ngOnInit(): void {
    this.currentDocumentId = "";
    this.getDocuments();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
    this.cols = [
      { field: "title", header: "Title", subField: "controlsCount", Id: "id" },
    ];
  }
  ngOnDestroy() {
    this.titleService.setTitle("");
  }
  controlsOpened = false;
  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  controlTypeId: any;
  cols: any[] = [];
  documents: any[] = [];
  isLoading = false;
  controlsCount = 0;
  controls = [];
  chapterControls = [];
  chapterControlsShow = false;
  currentDocumentId = "";
  ViewedChapter = "";
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  pageSize: number = globalConstants.defaultPageSize;
  pageNumber: number = globalConstants.defaultPageNumber;
  totalItems: number;

  getEditUrl(event: any) {
    this.router.navigate(["/Dashboard/Setup/createControl/" + event.id]);
  }
  changeDocument(event) {
    this.currentDocumentId = event.target.value;
    this.commonServices.controlsSelectedDocument.next(event.target.value);
    this.getAll(event.target.value);
    this.getControlsCount(event.target.value);
    this.getControls(event.target.value);
  }
  OpenCompliance() {
    this.controlsOpened = false;
    return false;
  }
  CloseCompliance() {
    this.controlsOpened = true;
    return false;
  }
  getAll(documentId) {
    this.isLoading = true;
    this.chapterService.getAll(documentId).subscribe((res) => {
      this.data = res;
      this.isLoading = false;
    });
  }

  getControls(documentId: any) {
    this.controlServices.All({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
      id: documentId
    }).subscribe((Data) => {
      this.controls = Data.data;
      this.totalItems = Data.count;
      this.isLoading = false;
    });
  }
  closeChapterControls() {
    this.chapterControlsShow = false;
    this.controlsOpened = false;
    this.getControls(this.currentDocumentId);
  }
  getChapterControls(chapterId: any, title: any) {
    this.isLoading = true;
    this.chapterControlsShow = true;
    this.controlsOpened = true;
    this.ViewedChapter = title;
    this.controlServices.AllForChapters(chapterId).subscribe((data) => {
      this.controls = data;
      this.isLoading = false;
    });
  }
  getControlsCount(documentId) {
    this.documentServices.getControlsCount(documentId).subscribe((data) => {
      this.titleService.setTitle("Controls");
      this.controlsCount = data;
    });
  }
  showfilterfun() {
    this.showfilter = true;
  }
  onDelete(event) {
    let controlId = event.id;

    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.controlServices.delete(controlId).subscribe((data) => {
          this.getControls(this.currentDocumentId);
        });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  applyFilter(event: ControlModel) {
    this.docu.setValue(event.documentId);
    event.pageNumber = globalConstants.defaultPageNumber;
    event.pageSize = this.pageSize;
    this.isLoading = true;
    this.controlServices.ApplyFilters(event).subscribe((res) => {
      this.controls = res.data;
      this.totalItems = res.count;
      this.isLoading = false;
    });
  }
  refresh() {
    localStorage.removeItem("ControlFilters");
    this.getDocuments();
  }
  onPageChange(event: any) {
    console.log(event)
  }
  @ViewChild("document") documentInout: ElementRef<HTMLInputElement>;
  docu: UntypedFormControl = new UntypedFormControl();
  getDocuments() {
    this.isLoading = true;
    this.documentServices.getAll().subscribe((data) => {
      this.documents = data;
      this.isLoading = false;
      if (this.documents.length != 0) {

        if (this.commonServices.controlsSelectedDocument.value) {
          this.currentDocumentId =
            this.commonServices.controlsSelectedDocument.value;
          this.docu.setValue(this.currentDocumentId);
        } else {
          this.currentDocumentId = this.documents[0].id;
          this.docu.setValue(this.currentDocumentId);
        }
        this.getAll(this.currentDocumentId);
        this.getControls(this.currentDocumentId);
        this.getControlsCount(this.currentDocumentId);
      }
    });
  }
  pageChangeHandler(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.previousPageIndex < event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.pageIndex + 1;
    } else if (event.previousPageIndex > event.pageIndex) {
      this.pageNumber = event.pageIndex == 0 ? 1 : event?.previousPageIndex;
    }
    this.isLoading = true;
    this.getControls(this.currentDocumentId);
  }
}
