<div class="app-page-title">
  <div class="page-title-wrapper">
      <div class="page-title-heading">
          <div class="page-title-icon">
              <i class="icon {{icon}}"></i>
          </div>
          <div>
              {{heading}}
              <div class="page-title-subheading">
                  {{subheading}}
              </div>
          </div>
      </div>
      <div class="page-title-actions">
          <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Dashboard/Setup/CreateCISLevel">
              <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
              Create New
          </button>
      </div>
  </div>
</div>

<div>
  <app-atlas-table [columns]="columns" [dataSource]="data"
                   [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                   [hasDeleteIcon]="true"
                   [hasPreviewIcon]="true"
                   [onEdit]="getEditUrl.bind(this)"
                [onDelete]="onDelete.bind(this)"
                      >



  </app-atlas-table>
</div>

<ng-template #content5 let-c="close" let-d="dismiss">
  <div class="modal-header">
      <h4 class="modal-title"> Are you sure you want to remove the CIS Level</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
      </button>
  </div>
  <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="delete()">Yes</button>
</ng-template>
<button id="btnDelete" style="display: none;" type="hidden" class="btn btn-primary"  (click)="open(content5)">Launch demo modal</button>
