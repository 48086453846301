import { select } from '@angular-redux/store';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserTableSettingService } from 'src/app/Services/table-setting-services';
import { getColumnsWithLabel, getHiddenColumns, saveUserTableSettings } from 'src/app/Helpers/UserTableSettingHelper';
@Component({
  selector: 'app-table-setting',
  templateUrl: './table-setting.component.html',
  styleUrls: ['./table-setting.component.scss']
})
export class TableSettingComponent implements OnInit {
  @Input() columns: any[] = [];
  @Input() settingType: any;
  
  @Output() closePopup = new EventEmitter<void>();
  @Output() saveSettings = new EventEmitter<void>();
  
  userSettingColumns: any[] = [];
  overAllColumns: any[] = [];
  displayedColumns: any[] = [];
  isFilterPopupOpen: boolean = false;
  filterForm: UntypedFormGroup;

  constructor(private userTableSettingService: UserTableSettingService) {}

  ngOnInit(): void {  
    this.userSettingColumns = getHiddenColumns(this.settingType)
    this.overAllColumns = getColumnsWithLabel(this.columns).map(column => ({
      name: column,
      checked: !this.userSettingColumns.includes(column) 
    }));
  
  }

  closeTableSettingPopup(): void {
    this.isFilterPopupOpen = false;
    this.emitClosePopup();
  }

  emitClosePopup(): void {
    this.closePopup.emit();
  }

  reset(): void {
   const setting = saveUserTableSettings([], this.settingType)
    this.emitSaveSettings();
    this.userTableSettingService.create({
      columnSettings : setting
    }).subscribe(res => {
     
    });
    this.closeTableSettingPopup();

  }

   save(): void {
    const selectedColumns = this.overAllColumns
      .filter(column => !column.checked) 
      .map(column => column.name); 

     const setting = saveUserTableSettings(selectedColumns, this.settingType)
      this.emitSaveSettings();
      this.userTableSettingService.create({
        columnSettings : setting
      }).subscribe(res => {
       
      });
      this.closeTableSettingPopup();
  }
  
  emitSaveSettings(): void {
    this.saveSettings.emit();
  }
}
