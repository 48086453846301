import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TenantServices {
  tenantBasePath = environment.apiUrl + "Tenant";

  constructor(private http: HttpClient) { }
  create(body): any {
    return this.http.post<any>(this.tenantBasePath + "/Create", body);
  }
  CreateTenant(body): any {
    return this.http.post<any>(this.tenantBasePath + "/CreateTenant", body);
  }
  JoinTenant(body): any {
    return this.http.post<any>(this.tenantBasePath + "/JoinTenant", body);
  }
  signup(body): any {
    return this.http.post<any>(this.tenantBasePath + "/Signup", body);
  }
  update(body): any {
    return this.http.post<any>(this.tenantBasePath + "/Update", body);
  }
  delete(body): any {
    return this.http.post<any>(this.tenantBasePath + "/Delete", body);
  }
  get(Id): any {
    return this.http.get<any>(this.tenantBasePath + "/Get/" + Id);
  }
  getAll(): any {
    return this.http.get<any>(this.tenantBasePath + "/All");
  }

  saveCurrentTenant(body): any {
    return this.http.post<any>(this.tenantBasePath + "/SaveCurrentTenant/", body);
  }
  userVerification(body): any {
    return this.http.post<any>(this.tenantBasePath + "/verify-email/", body);
  }
  getAllTenants() {
    return this.http.get<any>(this.tenantBasePath + "/GetAllTenants");
  }

  getAllTenantUsers(tenantId) {
    return this.http.get<any>(this.tenantBasePath + "/GetAllTenantUser/" + tenantId);
  }
}
