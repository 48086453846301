import { ProgressStatus, ProgressStatusEnum } from '../Common/models/progress-status.model';
import { MatSnackBar } from '@angular/material/snack-bar';



export default function showDownloadStatus(progressStatus: ProgressStatus, snackBar: MatSnackBar, fileName: string = null) {

	let fileMesssage = 'file';

	if(fileName) {
		fileMesssage += ` '${fileName}'`;
	}

	if(progressStatus.status == ProgressStatusEnum.START) {
		snackBar.open(`Start downloading the ${fileMesssage}`, null, {
			duration: 3000
		});
	}
	else if(progressStatus.status == ProgressStatusEnum.IN_PROGRESS) {
		if(progressStatus.percentage && progressStatus.percentage % 10 == 0){
			snackBar.open(`${progressStatus.percentage} of the ${fileMesssage} downloaded.`, null, {
				duration: 1000
			});
		}
	}
	else if(progressStatus.status == ProgressStatusEnum.COMPLETE) {
		snackBar.open(`The ${fileMesssage} was successfully downloaded.`, null, {
			duration: 2000
		});
	}
	else if(progressStatus.status == ProgressStatusEnum.ERROR) {
		snackBar.open(`Some errors occured while downloading the ${fileMesssage}.`, null, {
			duration: 2000,
		});
	}
}