<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <h5 class="card-title">Roles</h5>
                <form [formGroup]="PermissionsForm" (ngSubmit)="AddPermissions()" autocomplete="off">

                    <div class="position-relative mb-3">
                        <mat-form-field appearance="fill">
                            <mat-label>Select Role</mat-label>
                            <mat-select formControlName="roleId" (selectionChange)="roleChange($event)">
                                <mat-option *ngFor="let food of roles" [value]="food.id">
                                    {{food.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <br>
                        <label *ngIf="PermissionsForm.invalid" class="text text-danger">Required</label>
                        <br>

                        <!-- Table to display menu permissions -->
                        <div *ngIf="PermissionsForm.controls.roleId.valid">

                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Menu</th>
                                    <th>Access</th>
                                    <th>Create</th>
                                    <!-- <th>Read</th> -->
                                    <th>Update</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let submenu of menus; let first = first">
                                    <tr>
                                        <td style="font-weight: bold">{{submenu.name}}</td>
                                    </tr>
                                    <ng-container *ngFor="let childmenu of submenu.menus">
                                        <tr>
                                            <td style="padding-left: 20px;">{{childmenu.name}}</td>
                                            <!-- <td>
                                                <input type="checkbox">
                                            </td>
                                            <td>
                                                <input type="checkbox">
                                            </td>
                                            <td>
                                                <input type="checkbox">
                                            </td>
                                            <td>
                                                <input type="checkbox">
                                            </td>
                                            <td>
                                                <input type="checkbox">
                                            </td> -->
                                            <td>
                                                <!-- Bind access checkbox -->
                                                <input type="checkbox" [checked]="isSelected(childmenu.menuId, 'canAccess')" (change)="togglePermission($event, childmenu.menuId, 'canAccess')">
                                            </td>
                                            <td>
                                                <!-- Bind create checkbox -->
                                                <input type="checkbox" [checked]="isSelected(childmenu.menuId, 'canCreate')" (change)="togglePermission($event, childmenu.menuId, 'canCreate')">
                                            </td>
                                            <!-- <td>
                                                <input type="checkbox" [checked]="isSelected(childmenu.menuId, 'canRead')" (change)="togglePermission($event, childmenu.menuId, 'canRead')">
                                            </td> -->
                                            <td>
                                                <!-- Bind update checkbox -->
                                                <input type="checkbox" [checked]="isSelected(childmenu.menuId, 'canUpdate')" (change)="togglePermission($event, childmenu.menuId, 'canUpdate')">
                                            </td>
                                            <td>
                                                <!-- Bind delete checkbox -->
                                                <input type="checkbox" [checked]="isSelected(childmenu.menuId, 'canDelete')" (change)="togglePermission($event, childmenu.menuId, 'canDelete')">
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                        </div>
                        
                        <br>
                    </div>
                    <button type="submit" [disabled]="isLoading" class="mt-1 btn btn-success btn-atlas">Submit</button>
                    <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>
