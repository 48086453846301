import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InvitationService {

  invitationbasePath = environment.apiUrl + "Invitation";
  constructor(private http: HttpClient) { }

  CheckInvitation(token: any): any {
    return this.http.get<any>(this.invitationbasePath + "/ValidateInvitation/" + token);
  }
  GetUserRolesByInvitationId(id: any): any {
    return this.http.get<any>(this.invitationbasePath + "/GetUserRole/" + id);
  }
  create(body): any {
    return this.http.post<any>(this.invitationbasePath + "/Register", body);
  }
}
