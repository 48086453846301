import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlSortedDTO } from 'src/app/Models/AudutingModel';

@Component({
  selector: 'app-sorting-pop-up',
  templateUrl: './sorting-pop-up.component.html',
  styleUrls: ['./sorting-pop-up.component.scss']
})
export class SortingPopUpComponent {
  @Input() onClose: (closeboth) => {};
  @Output() sortingData = new EventEmitter<ControlSortedDTO>();
  sortingModel : ControlSortedDTO = new ControlSortedDTO();


  applySorting()
  {
    
    this.sortingData.emit(this.sortingModel);
  }

  ResetSortingOptions()
  {
    this.sortingModel = new ControlSortedDTO()
  }

}
