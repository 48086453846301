import { Component, HostBinding } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable, Subscription } from 'rxjs';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ThemeOptions } from '../../../theme-options';
import { NotificationsService } from 'src/app/Services/notification-service';
import { TitleService } from 'src/app/Services/title.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  faEllipsisV = faEllipsisV;
  increaseCount = 0;
  currentTitle = "";
  backButtonUrl = "";
  titleListner: Subscription;
  titleBackListner: Subscription;
  constructor(public globals: ThemeOptions,
    public notificationsService: NotificationsService,
    public titleService: TitleService,
    public router: Router 
  ) {
    this.notificationsService.markAsSeen({ ids: [] }).subscribe(data => {
      this.increaseCount = data.unSeen;
    });
    this.titleListner = this.titleService.getTitleObservable().subscribe((title) => {
      this.currentTitle = title;
    });
    this.titleBackListner = this.titleService.getBackButtonUrlObservable().subscribe((url) => {
      this.backButtonUrl = url;
    });
  }
  @HostBinding('class.isActive')
  get isActiveAsGetter() {
    return this.isActive;
  }
  backButton() {
    this.router.navigate([this.backButtonUrl]);
  }
  isActive: boolean;
  @select('config') public config$: Observable<any>;
  toggleSidebarMobile() {
    this.globals.toggleSidebarMobile = !this.globals.toggleSidebarMobile;
  }
  toggleHeaderMobile() {
    this.globals.toggleHeaderMobile = !this.globals.toggleHeaderMobile;
  }
  ngOnDestroy() {
    if (this.titleListner) {
      this.titleListner.unsubscribe();
    }
    if (this.titleBackListner) {
      this.titleBackListner.unsubscribe();
    }
  }
}
