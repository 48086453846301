import { Component, Input } from "@angular/core";
import {
  linkQuestionModelView,
} from "src/app/Models/Audit.Models";

@Component({
  selector: "app-linked-questions-list",
  templateUrl: "./linked-questions-list.component.html",
  styleUrls: ["./linked-questions-list.component.scss"],
})
export class LinkedQuestionsListComponent  {
  
  @Input() LinkedQuestions: linkQuestionModelView[] = [];
  @Input()
  onDelete: (event) => {};
 
}
