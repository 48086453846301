import { Component, HostListener } from '@angular/core';
import FileInfo from 'src/app/Common/models/files';
import { ImportService } from 'src/app/Services/import.service';
import { NotificationService } from 'src/app/Services/notification.service';

@Component({
  selector: 'app-import-document',
  templateUrl: './import-document.component.html',
  styleUrls: ['./import-document.component.scss']
})
export class ImportDocumentComponent {


  uploadedFiles: FileInfo[] = [];
  uploadedFile: File | null;
  isLoading = false;
  IsPreviewDataLoaded: boolean = false;
  data: any;
  constructor(private importService: ImportService,
    private notify: NotificationService,) { }

  @HostListener("document:keydown", ["$event"])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      this.IsPreviewDataLoaded = false;
    }
  }

  modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }], // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }], // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }], // outdent/indent
      [{ 'direction': 'rtl' }], // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }], // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean'], // remove formatting button
      ['link', 'image'], // link and image, video
    ],
    imageResize: true // for image resize
  };

  predefinedObservationActionChange(valueName, index, $event) {
    this.data.importData[index].importControls.control[valueName] = $event;
  }

  onFileUpload(event: any) {
    const files = event.target.files;
    const fileLimit = 1;

    for (const file of files) {
      if (this.uploadedFiles.length >= fileLimit) {
        this.notify.showWarning("You Can Upload Only One File To Import Data", "Import");
        return;
      } else {
        this.isLoading = true;
        this.uploadFile(file);
      }
    }

  }
  uploadFile(file: any) {
    this.uploadedFiles.push(file);
    this.uploadedFile = file;
    this.isLoading = false;
  }

  createDocument() {
    this.isLoading = true

    const formData = new FormData();
    formData.append(this.uploadedFile.name, this.uploadedFile);
    this.importService.ImportFile(formData).subscribe(res => {


      if (res.isError) {
        this.isLoading = false;
        this.notify.showError("Document Already Imported", "Import");
        this.uploadedFiles = []
        return;
      }

      this.notify.showSuccess("Imported Data Is Ready For Preview", "Import");
      this.data = res;
      console.log(this.data)
      this.IsPreviewDataLoaded = true;
      this.isLoading = false
      this.uploadedFiles = []
    })
  }


  public onCreate(): void {
  }



  deleteFile(file: FileInfo) {
    this.uploadedFiles = []
  }
  isDeletable(): boolean {
    return true;
  }
  view(event) {
    console.log("");
  }

  onClose(event) {
    this.IsPreviewDataLoaded = false
    console.log("on close")
  }

  hasNewDataList(datalist: any) {
    return !!datalist.find(x => !x.isExist);

  }

  hasAnyNewDataList(datalists: any): boolean {
    let hasNewDataList: boolean = false;
    let resultArray = Object.keys(datalists).map(function (personNamedIndex) {
      let datalist = datalists[personNamedIndex];
      return datalist;
    });
    resultArray.forEach(e => {
      let res = e.find(x => !x.isExist);
      if (res != null) {
        hasNewDataList = true
      }
    })
    return hasNewDataList;
  }

  save() {
    this.isLoading = true
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.importService.saveImportData(this.data).subscribe(res => {
      this.notify.showSuccess("Imported Data Saved Successfully", "Import");
      this.IsPreviewDataLoaded = false;
      this.isLoading = false
    },
      err => {
        this.notify.showError("Error Occured While Saving Data", "Import");
        this.IsPreviewDataLoaded = false;
        this.isLoading = false
      });
  }
}
