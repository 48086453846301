
export enum TimeZoneIANAName {
    "HST" = "Pacific/Honolulu",
    "AKST" = "America/Anchorage",
    "PST" = "PST8PDT",
    "MST" = "America/Denver",
    "AZ" = "America/Phoenix",
    "CST" = "America/Chicago",
    "EST" = "America/New_York",
    "AST" = "America/Montserrat",
    "PDT" = "PST8PDT",
    "MDT" = "America/Hermosillo",
    "MOST" = "Africa/Casablanca",
    "UTC" = "UTC",
    "GMT" = "Europe/London",
    "GST" = "Africa/Casablanca",
    "WET" = "Europe/Amsterdam",
    "CET" = "Europe/Belgrade",
    "RST" = "Europe/Brussels",
    "CEST" = "Europe/Sarajevo",
    "ECT" = "Africa/Brazzaville",
    "JST" = "Europe/Athens",
    "GTBST" = "Europe/Athens",
    "MEST" = "Africa/Cairo",
    "EGST" = "Africa/Cairo",
    "SST" = "Africa/Cairo",
    "SAST" = "Africa/Harare",
    "EET" = "Europe/Helsinki",
    "ISST" = "Asia/Jerusalem",
    "EEST" = "Europe/Helsinki",
    "NMST" = "Asia/Jerusalem",
    "IDT" = "Asia/Jerusalem",
    "ARST" = "Asia/Baghdad",
    "ABST" = "Asia/Kuwait",
    "MSK" = "Europe/Moscow",
    "EAT" = "Asia/Kuwait",
    "IRST" = "Asia/Tehran",
    "ARBST" = "Asia/Muscat",
    "AZT" = "Asia/Baku",
    "MUT" = "Asia/Baku",
    "GET" = "Asia/Baku",
    "AMT" = "Asia/Baku",
    "AFT" = "Asia/Baku",
    "YEKT" = "Asia/Tashkent",
    "PKT" = "Asia/Tashkent",
    "WAST" = "Asia/Tashkent",
    "IST" = "Asia/Calcutta",
    "SLT" = "Asia/Calcutta",
    "NPT" = "Asia/Katmandu",
    "BTT" = "Asia/Dhaka",
    "BST" = "Asia/Dhaka",
    "NCAST" = "Asia/Almaty",
    "MYST" = "Asia/Rangoon",
    "THA" = "Asia/Bangkok",
    "KRAT" = "Asia/Bangkok",
    "IRKT" = "Asia/Irkutsk",
    "SNST" = "Asia/Singapore",
    "AWST" = "Australia/Perth",
    "TIST" = "Asia/Taipei",
    "UST" = "Asia/Taipei",
    "TST" = "Asia/Tokyo",
    "KST" = "Asia/Seoul",
    "YAKT" = "Asia/Yakutsk",
    "CAUST" = "Australia/Adelaide",
    "ACST" = "Australia/Darwin",
    "EAST" = "Australia/Brisbane",
    "AEST" = "Australia/Sydney",
    "WPST" = "Pacific/Guam",
    "TAST" = "Australia/Hobart",
    "VLAT" = "Asia/Vladivostok",
    "SBT" = "Pacific/Guadalcanal",
    "NZST" = "Pacific/Auckland",
    "UTC12" = "Etc/GMT-12",
    "FJT" = "Pacific/Fiji",
    "PETT" = "Asia/Kamchatka",
    "PHOT" = "Pacific/Tongatapu",
    "AZOST" = "Atlantic/Azores",
    "CVT" = "Atlantic/Cape Verde",
    "ESAST" = "America/Sao_Paulo",
    "ART" = "America/Buenos Aires",
    "SAEST" = "SA Eastern Standard Time",
    "GNST" = "America/Godthab",
    "MVST" = "America/Godthab",
    "NST" = "Canada/Newfoundland",
    "PRST" = "Canada/Atlantic",
    "CBST" = "Canada/Atlantic",
    "SAWST" = "America/Santiago",
    "PSAST" = "America/Santiago",
    "VST" = "America/Caracas",
    "SAPST" = "America/Bogota",
    "CAST" = "America/El_Salvador",
    "CCST" = "Canada/Saskatchewan",
    "SMST" = "Pacific/Midway",
}
