<div class="Pop-Up-Box-Control-List pop-up-box">
	<div class="Pop-Up-Header">
		<span (click)="onClose(false); closePopup();" class="material-icons">highlight_off</span>
		<h1>Linked Controls</h1>
	</div>

	<hr class="HR">
	<div class="row mb-1" style="text-align: left;" *ngFor="let control of linkedControlsList">
		<div class="col-md-9">
			<p>{{control.name}}</p>
		</div>
		<div class="col-md-3">
			<button class="btn btn-success btn-atlas" (click)="OpenControlDetails(control.id)">View Control</button>
		</div>
	</div>

</div>

<app-app-control-details *ngIf="openDetails" [event]="controlDetails" [onClose]="onCloseDetail.bind(this)"
	style="position: fixed;top: 15%;width: 70%;z-index: 1000000;">
</app-app-control-details>

<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 1000001;">
	<mat-spinner></mat-spinner>
</div>