<!-- <app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon" [url]="'/Settings/CreateRole'"></app-page-title> -->
<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas"
                routerLink="/Settings/create-user">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
</div>
<app-filter-function 
  [parentProperty]="parentData" 
  (childEvent)="handleChildEvent($event)">
</app-filter-function>
<div>
    <app-atlas-table [columns]="columns" [dataSource]="data" [customColumnWidths]="true"
        [customPageSizeOptions]="customPageSizeOptions" [hasDeleteIcon]="true" [hasPreviewIcon]="true"
        [onOpen]="onOpenDetails.bind(this)"
        [onEdit]="getEditUrl.bind(this)" [onDelete]="onDelete.bind(this)">



    </app-atlas-table>
    <app-user-details *ngIf="openUserDetails" [UserDetails]="userDetails" [open]="openUserDetails" [Close]="onClose.bind(this)"></app-user-details>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
    <mat-spinner></mat-spinner>
</div>
<ng-template #content5 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> Are you sure you want to remove the User</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="delete()">Yes</button>
</ng-template>
<!-- <button id="btnDelete" style="display: none;" type="hidden" class="btn btn-primary"  (click)="open(content5)">Launch demo modal</button> -->