import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { dateTimeFormats } from "src/app/Common/consntants";
import { DocumentService } from "src/app/Services/document.service";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { EllipsisPipe } from "src/app/Shared/pipes/ellipsis.pipe";
import { globalConstants } from "src/constants/global-constants";

@Component({
  selector: "app-documents",
  templateUrl: "./documents.component.html",
  styleUrls: ["./documents.component.sass"],
  providers: [EllipsisPipe, HasPermissionPipe],
})
export class DocumentsComponent implements OnInit {
  constructor(
    private documentService: DocumentService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal,
    private _ellipsisPipe: EllipsisPipe,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private titleService: TitleService
  ) { 
    this.titleService.setTitle("Documents");
  }

  heading = "Documents";
  dateFormat = dateTimeFormats.date;
  subheading = "Document that you will be uploading inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  route: any;
  documentId: any;
  isLoading = false;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  columns: Partial<Column>[] = [];

  initColumns() {
    this.columns = [
    {
      iconColumn: true,
      elementAttribute: "positionDownloadIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Type",
      elementAttribute: "type",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },

    {
      label: "Release Date",
      elementAttribute: "releaseDate",
      columnClass: "matters-TypeWidth",
      isLink: false,
      formatFn: (date: Date) => {
        let dateString = this.datePipe.transform(date, this.dateFormat);
        if (dateString) {
          return `${dateString[0]}${dateString[1]}/${dateString[3]}${dateString[4]}/${dateString[6]}${dateString[7]}${dateString[8]}${dateString[9]}`;
        } else {
          return "";
        }
      },
      columnWidth: "200px",
    },
    {
      label: "Notes",
      elementAttribute: "notes",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
      formatFn: (chapter: string) =>
        chapter ? this._ellipsisPipe.transform(chapter, 50) : "",
    },
    {
      iconColumn: true,
      elementAttribute: "positionActionIcon",
      columnWidth: "100px",
    },
  ];
}

  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }

  getAll() {
    this.isLoading = true;
    this.documentService.getAll().subscribe((res) => {
      this.data = res;
      this.isLoading = false;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate(["/Dashboard/Setup/createdocuments/" + event.id]);
  }

  onDelete(event) {
    this.documentId = event.id;
    (document.getElementById("btnDelete") as HTMLInputElement).click();
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.documentService.delete(this.documentId).subscribe((data) => {
      this.getAll();
      this.modalService.dismissAll();
    });
  }
  ngOnDestroy() {
    this.titleService.setTitle("")
  }
}
