import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule, DatePipe } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { Routes, RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { ButtonModule } from "primeng/button";
import { PermissionAction, PermissionType } from "../Enum/permission-type";
import { PermissionsGuard } from "../Guard/permissions-guard";
import { EllipsisPipe } from "../Shared/pipes/ellipsis.pipe";
import { SharedModule } from "../Shared/Shared.module";
import { ConfigActions } from "../ThemeOptions/store/config.actions";
import { ChapterComponent } from "./chapter/chapter.component";
import { CreateOrEditChapterComponent } from "./chapter/create-or-edit-chapter/create-or-edit-chapter.component";
import { ControlFunctionsComponent } from "./control-functions/control-functions.component";
import { CreateControlFunctionsComponent } from "./control-functions/control-functions/control-functions.component";
import { ControlTypesComponent } from "./control-types/control-types.component";
import { CreateControlTypesComponent } from "./control-types/create-control-types/create-control-types.component";
import { ControlsComponent } from "./controls/controls.component";
import { CreateOrEditControlComponent } from "./controls/create-or-edit-control/create-or-edit-control.component";
import { CreateAssetTypeComponent } from "./cs-asset-types/create-asset-type/create-asset-type.component";
import { CsAssetTypesComponent } from "./cs-asset-types/cs-asset-types.component";
import { CreateCsLevelComponent } from "./cs-levels/create-cs-level/create-cs-level.component";
import { CsLevelsComponent } from "./cs-levels/cs-levels.component";
import { CreateDocumentTypesComponent } from "./document-types/Create/create-document-types/create-document-types.component";
import { DocumentTypesComponent } from "./document-types/document-types.component";
import { CreateDocumentComponent } from "./documents/create-document/create-document.component";
import { DocumentsComponent } from "./documents/documents.component";
import { CreateErrorControlTypeComponent } from "./error-control-types/create-error-control-type/create-error-control-type.component";
import { ErrorControlTypesComponent } from "./error-control-types/error-control-types.component";
import { ImportDocumentComponent } from "./import-document/import-document.component";
import { ImportQuestionsComponent } from "./import-questions/import-questions.component";
import { CreatePrimaryObjectiveComponent } from "./primary-objectives/create-primary-objective/create-primary-objective.component";
import { PrimaryObjectivesComponent } from "./primary-objectives/primary-objectives.component";
import { CreateEditScfDomainsComponent } from "./scf-domains/create-edit-scf-domains/create-edit-scf-domains.component";
import { ScfDomainsComponent } from "./scf-domains/scf-domains.component";
import { CreateSuggestedControlOwnershipComponent } from "./suggested-control-ownership/create-suggested-control-ownership/create-suggested-control-ownership.component";
import { SuggestedControlOwnershipComponent } from "./suggested-control-ownership/suggested-control-ownership.component";
import { CreateSuggestedControlComponent } from "./suggested-controls/create-suggested-control/create-suggested-control.component";
import { SuggestedControlsComponent } from "./suggested-controls/suggested-controls.component";
import { DepartmentsComponent } from './departments/departments.component';
import { CreateOrEditDepartmentsComponent } from './departments/create-departments/create-departments.component';
import { TreeTableModule } from 'primeng/treetable';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';

import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { MatTableModule } from "@angular/material/table";
import { HasPermissionPipe } from "../Shared/pipes/HasPermissionPipe";

const routes: Routes = [
  {
    path: "Setup/controls",
    component: ControlsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Controls,
    },
  },
  {
    path: "Setup/createoreditcontrols",
    component: CreateOrEditControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Controls,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createoreditcontrols/:id",
    component: CreateOrEditControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Controls,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/documentTypes",
    component: DocumentTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.DocumentTypes,
    },
  },
  {
    path: "Setup/createDocumentTypes",
    component: CreateDocumentTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.DocumentTypes,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createDocumentTypes/:id",
    component: CreateDocumentTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.DocumentTypes,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/createdocuments",
    component: CreateDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Documents,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createdocuments/:id",
    component: CreateDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Documents,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/documents",
    component: DocumentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Documents,
    },
  },
  {
    path: "Setup/controlTypes",
    component: ControlTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlTypes,
    },
  },
  {
    path: "Setup/createcontrolTypes",
    component: CreateControlTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlTypes,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/editcontrolTypes/:id",
    component: CreateControlTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlTypes,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/createControl",
    component: CreateOrEditControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlTypes,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createControl/:id",
    component: CreateOrEditControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Controls,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/assetTypes",
    component: CsAssetTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISAssetTypes,
    },
  },
  {
    path: "Setup/createAssetTypes",
    component: CreateAssetTypeComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISAssetTypes,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createAssetTypes/:id",
    component: CreateAssetTypeComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISAssetTypes,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/CISLevel",
    component: CsLevelsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISLevels,
    },
  },
  {
    path: "Setup/CreateCISLevel",
    component: CreateCsLevelComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISLevels,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/CreateCISLevel/:id",
    component: CreateCsLevelComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.CISLevels,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/ControlFunctions",
    component: ControlFunctionsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlFunctions,
    },
  },
  {
    path: "Setup/primaryObjectives",
    component: PrimaryObjectivesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PrimaryObjectives,
    },
  },
  {
    path: "Setup/CreateControlFunctions",
    component: CreateControlFunctionsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlFunctions,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/CreateControlFunctions/:id",
    component: CreateControlFunctionsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlFunctions,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/createPrimaryObjective",
    component: CreatePrimaryObjectiveComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PrimaryObjectives,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createPrimaryObjective/:id",
    component: CreatePrimaryObjectiveComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PrimaryObjectives,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/suggestedControls",
    component: SuggestedControlsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControls,
    },
  },
  {
    path: "Setup/createsuggestedControl",
    component: CreateSuggestedControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControls,
      permissionAccess:PermissionAction.Create
    },
  },
  {
    path: "Setup/createsuggestedControl/:id",
    component: CreateSuggestedControlComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControls,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/Departments",
    component: DepartmentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Department,
    },
  },
  {
    path: "Setup/create-or-edit-department",
    component: CreateOrEditDepartmentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Department,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/create-or-edit-department/:id",
    component: CreateOrEditDepartmentsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Department,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/internalControlName",
    component: ErrorControlTypesComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
    },
  },
  {
    path: "Setup/createInternalControlType",
    component: CreateErrorControlTypeComponent,
    data: { extraParameter: "elementsMenu" },
  },
  {
    path: "Setup/createInternalControlType/:id",
    component: CreateErrorControlTypeComponent,
    data: { extraParameter: "elementsMenu" },
  },
  {
    path: "Setup/ScfDomains",
    component: ScfDomainsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SCFDomains,
    },
  },
  {
    path: "Setup/createorEditScfDomains",
    component: CreateEditScfDomainsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SCFDomains,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/createorEditScfDomains/:id",
    component: CreateEditScfDomainsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SCFDomains,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/chapters",
    component: ChapterComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Chapters,
    },
  },
  {
    path: "Setup/create-or-edit-chapter",
    component: CreateOrEditChapterComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Chapters,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/create-or-edit-chapter/:id",
    component: CreateOrEditChapterComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.Chapters,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/suggested-control-ownership",
    component: SuggestedControlOwnershipComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControlOwnership,
    },
  },
  {
    path: "Setup/create-or-edit-suggested-control-ownership",
    component: CreateSuggestedControlOwnershipComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControlOwnership,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "Setup/create-or-edit-suggested-control-ownership/:id",
    component: CreateSuggestedControlOwnershipComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.SuggestedControlOwnership,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "Setup/import-document",
    component: ImportDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ImportDocument,
    },
  },
  {
    path: "Setup/import-question",
    component: ImportQuestionsComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ImportQuestions,
    },
  },
]


@NgModule({
  declarations: [
    DocumentTypesComponent,
    DocumentsComponent,
    ControlTypesComponent,
    CsAssetTypesComponent,
    CsLevelsComponent,
    ControlFunctionsComponent,
    CreateControlFunctionsComponent,
    PrimaryObjectivesComponent,
    SuggestedControlsComponent,
    ErrorControlTypesComponent,
    ScfDomainsComponent,
    CreateDocumentTypesComponent,
    CreateDocumentComponent,
    CreateControlTypesComponent,
    CreateAssetTypeComponent,
    CreateCsLevelComponent,
    CreatePrimaryObjectiveComponent,
    CreateSuggestedControlComponent,
    CreateErrorControlTypeComponent,
    CreateEditScfDomainsComponent,
    CreateOrEditControlComponent,
    ControlsComponent,
    ChapterComponent,
    CreateOrEditChapterComponent,
    SuggestedControlOwnershipComponent,
    CreateSuggestedControlOwnershipComponent,
    ImportDocumentComponent,
    ImportQuestionsComponent,
    EllipsisPipe,
    DepartmentsComponent,
    CreateOrEditDepartmentsComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    RouterModule.forChild(routes),
    MatTabsModule,
    DragDropModule,
    SharedModule,
    TreeTableModule,
    ButtonModule,
    MultiSelectModule,
    InputTextModule,
    RichTextEditorAllModule,
    ContextMenuModule,
    MatTableModule,
    
  ],

  providers: [DatePipe,
    ConfigActions,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SetupModule { }
