import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { LogoutServices } from "src/app/Services/LogoutServices";

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  token: string;
  omitCalls = [""];
  skipInterceptor = false;
  constructor(private router: Router, private logoutService: LogoutServices) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.omitCalls.forEach((api) => {
      if (req.url.includes(api)) {
        this.skipInterceptor = true;
      }
    });
    this.token = localStorage.getItem("accessToken");
    if (this.token == null) {
      this.router.navigateByUrl("/Login");
    }
    if (this.token || this.skipInterceptor) {
      const tokenizedReq = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${this.token}`),
      });
      return next.handle(tokenizedReq).pipe(
        map((response: HttpEvent<any>) => {
          // Handle the response
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 409 || error.status === 401) {
            this.logoutService.logout();
            return throwError('Logout error');
          } else {
            return throwError(error);
          }
        })
      )
    }
    else {
      this.router.navigateByUrl("/Login");
    }
    return next.handle(req);
  }
}
