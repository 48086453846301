<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button *ngIf="route | hasPermission:'canCreate'" type="button"
                class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Audit/Create">
                <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 1.5V10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 6H10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="ms-2">Create New</span>
                
            </button>
        </div>
    </div>
</div>
<app-filter-function [FilterType]="filterTypeEnum" (tableSettingPopup)="tableSettingPopup()"></app-filter-function>
<div>
 <app-atlas-table [columns]="columns" [dataSource]="data" [customColumnWidths]="true" [hasDeleteIcon]="true"
        [hasPreviewIcon]="true" [hasStartIcon]="true" [onStart]="startAudit.bind(this)" [onEdit]="getEditUrl.bind(this)"
        (SortPageChanged)="sortChange($event)"
        [onDelete]="onDelete.bind(this)" [onCustomButtonIconClick]="DuplicateAudit.bind(this)"
        [onOpen]="OpenAuditDetails.bind(this)" [hasServerPaging]="true" [hasPaginator]="false" [totalItems]="totalItems"
        [customPageSizeOptions]="customPageSizeOptions" [pageChange]="pageChangeHandler.bind(this)">
    </app-atlas-table>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 1000001;">
    <mat-spinner></mat-spinner>
</div>

<app-app-audit-details *ngIf="OpenAuditDetailsPopUp"
    style="position: absolute; top: 20%;width:70%;height: 75vh; z-index: 100000;" [onClose]="onClose.bind(this)"
    [data]="auditDetails"></app-app-audit-details>

<app-table-setting
*ngIf="isTableSettingPopupOpen"
    [columns]="columns"
    [settingType]="settingType"
    (closePopup)="onCloseTableSettingPopup()"
    (saveSettings)="onSaveSettings()"
  ></app-table-setting>