<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Documents</h5>
        <form [formGroup]="documentForm" (ngSubmit)="createDocument()">
          <div class="position-relative mb-3">
            <label for="name" class="form-label">Name</label>
            <input name="name" formControlName="name" id="name" placeholder="Document" type="email"
              class="form-control">

          </div>
          <div class="position-relative mb-3">
            <label for="name" class="form-label">Release Date</label>
            <input name="releaseDate" formControlName="releaseDate" id="releaseDate" placeholder="Date" type="date"
              class="form-control">
          </div>
          <div class="position-relative mb-2">
            <mat-form-field appearance="fill" style="width: 100%;">
              <mat-label>Document Type</mat-label>
              <mat-select formControlName="documentType">
                <mat-option *ngFor="let docTyoe of documentTypeList" [value]="docTyoe.id">
                  {{docTyoe.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="position-relative mb-3">
            <label for="name" class="form-label">Notes</label>
            <textarea formControlName="notes" id="notes" placeholder="Notes" class="form-control"></textarea>
          </div>
          <div class="position-relative mb-3">
            <app-atlas-files-list [filesList]="uploadedFiles" [visible]="documentId ? true : false"
              [visibleView]="documentId ? true : false" [visibleDownload]="documentId ? true : false"
              [visibleLink]="false" [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
              [isDeletable]="isDeletable.bind(this)" [formGroupData]="documentForm"></app-atlas-files-list>
            <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"></app-atlas-file-upload>
          </div>
          <button class="mt-1 btn btn-success btn-atlas" [disabled]="isLoading">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>