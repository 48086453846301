import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserTableSettingService {
  constructor(private http: HttpClient) { }
  userbasePath = environment.apiUrl + "TableSettings";

  create(body): any {
    return this.http.post<any>(this.userbasePath + "/Create", body);
  }

}
