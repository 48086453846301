import { Component, HostListener, OnInit } from "@angular/core";
import { ThemeOptions } from "../../../theme-options";
import { select } from "@angular-redux/store";
import { Observable, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { RolesServices } from "src/app/Services/roles-services";
import { FlagService } from "src/app/Services/flag.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ['./sidebar.component.scss'],
  styles: [' .nav-heading-scroll{overflow-x: auto;}']
})
export class SidebarComponent implements OnInit {
  public extraParameter: any;
  MenuItems = [];
  isSuperAdmin: boolean = false;
  private flagSubscription: Subscription;
  constructor(
    public globals: ThemeOptions,
    private activatedRoute: ActivatedRoute,
    private rolesServices: RolesServices,
    private router: Router,
    private flagService: FlagService,
    private sanitizer: DomSanitizer
  ) {
    this.flagSubscription = this.flagService.flag$.subscribe((flagValue) => {
      this.onFlagChange(flagValue);
    });
  }
  sanitizeIcon(icon: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(icon);
  }
  @select("config") public config$: Observable<any>;

  private newInnerWidth: number;
  private innerWidth: number;
  activeId = "dashboardsMenu";
  getFillColor(route: string): string {
    return this.router.isActive(route, true) ? '#62A0FF' : 'white';
  }
  toggleSidebar() {
    this.globals.toggleSidebar = !this.globals.toggleSidebar;
  }

  sidebarHover() {
    this.globals.sidebarHover = !this.globals.sidebarHover;
  }
  isActiveDashboard() {
    return this.router.url == "/Analytics";
  }
  navigate(){
    this.router.navigate(['/']);
  }
  ngOnInit() {
    let roles = localStorage.getItem("roles");
    if (roles == "SuperAdmin") {
      this.setSuperAdminSideBar()
    } else {
      this.rolesServices.getRoleAccess().subscribe((data) => {
        this.MenuItems = data;
      });
    }

    

    setTimeout(() => {
      this.innerWidth = window.innerWidth;
      if (this.innerWidth < 1200) {
        this.globals.toggleSidebar = true;
      }
    });

    this.extraParameter =
      this.activatedRoute.snapshot.firstChild.data.extraParameter;

  }

  onFlagChange(flagValue: boolean) {
    if (flagValue) {
      this.setSuperAdminSideBar();
    }

  }

  setSuperAdminSideBar() {
    let menuItems = [];
    let superAdminSideBar = {
      "menuId": "e8b08811-662f-4be7-99d2-23b6c75f9272",
      "name": "Tenant",
      "route": null,
      "icon": "vsm-icon pe-7s-rocket",
      "isDelete": false,
      "parentID": null,
      "order": 1,
      "isSideMenuItem": false,
      "menus": [
        {
          "menuId": "fe39ae46-1f03-45c2-bb05-383f7546bebe",
          "name": "Tenant List",
          "route": "/Tenants",
          "icon": null,
          "isDelete": false,
          "parentID": "e8b08811-662f-4be7-99d2-23b6c75f9272",
          "order": 1,
          "isSideMenuItem": false,
          "menus": null,
          "type": "Audits",
          "permissionType": "Audits"
        },


      ],
      "type": null,
      "permissionType": "None"
    };
    menuItems.push(superAdminSideBar);

    this.MenuItems = menuItems;

  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.newInnerWidth = event.target.innerWidth;

    if (this.newInnerWidth < 1200) {
      this.globals.toggleSidebar = true;
    } else {
      this.globals.toggleSidebar = false;
    }
  }
}
