
export const globalConstants = {
  languageCode: "en-us",
  firmName: "Schneider Wallace",
  newLinePattern: /[\r\n]/g,
  customPageSizeOptions: [10,25, 50, 100, 500], // This applies only to Matters, Contacts, Mail Log, Billing and Users| ControlDocumentStatusConstants
  datePattern: /^(0[1-9]|1[012])\/(0[1-9]|[12]\d|3[01])\/(19|20)\d\d$/,
  defaultPageSize : 10,
  defaultPageNumber : 1
};

export class AppConstant  {
 static controlDocumentStatusConstants: ControlDocumentStatusConstants ={
  Draft :1,
  Minor :2,
  Major : 3,
 };

};

export class ControlDocumentStatusConstants  {
  public readonly Draft = 1;
  public readonly Minor = 2;
  public readonly Major = 3;
};