import { Component  } from '@angular/core';


@Component({
  selector: 'app-auditcontrolsorting',
  templateUrl: './auditcontrolsorting.component.html',
  
})
export class AuditcontrolsortingComponent  {
  sortByOptions : any[] =[]

}

