<div class="Inforce-Table-Container" [class.inner-table-container]="hasInnerTable">

	<table matSort mat-table multiTemplateDataRows [dataSource]="_dataSource"
		   [class.customColumnWidths]="customColumnWidths">

		<ng-container matColumnDef="expandIcon" *ngIf="hasInnerTable">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef>
				<button mat-icon-button [matTooltip]="isAllExpanded ? 'Collapse All' : 'Expand All'"
						[matTooltipPosition]="'below'" (click)="expandCollapseAll()">
					<mat-icon class="collapse-expand-icon">
						{{isAllExpanded ? 'expand_more' : 'keyboard_arrow_right'}}
					</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button [matTooltip]="element.expanded ? 'Collapse' : 'Expand'"
						[matTooltipPosition]="'below'" (click)="toggleRow(element)">
					<mat-icon class="collapse-expand-icon">
						{{element.expanded ? 'expand_more' : 'keyboard_arrow_right'}}
					</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="positionOpenIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%">
				<button class="collapsiblebtn mb-1" (click)="getArrowIcon()" *ngIf="haveCollapseIcon">
					<mat-icon class="arrowIcon" *ngIf="!arrowClose">arrow_drop_down</mat-icon>
					<mat-icon class="arrowIcon" *ngIf="arrowClose">arrow_right</mat-icon>
				</button>
			</th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Details" [matTooltipPosition]="'below'" (click)="onOpen(element)"
						data-automation="details-button">
					<mat-icon class="Open-Details-Icon blue-icon">reorder</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container matColumnDef="positionDownloadIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%"></th>
			<td mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Download" [matTooltipPosition]="'below'"
						(click)="downloadURL(element.documentPath)" data-automation="details-button">
					<mat-icon class="Open-Details-Icon blue-icon">cloud_download</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container *ngIf="hascheckBox" matColumnDef="positionCheckBox">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%; text-align: center; padding-left: 3px;">
				<mat-checkbox (change)="onCheckedAll($event)">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let element" style="text-align: center !important;">
				<mat-checkbox [checked]="isAllChecked" (change)="onCheckChanged($event,element)">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container *ngIf="hasPreviewIcon" matColumnDef="positionEditIcon">
			<th [hidden]="!canViewEditIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewEditIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Edit" [matTooltipPosition]="'below'" (click)="onEdit(element)"
						data-automation="edit-button">
					<mat-icon>edit</mat-icon>
				</button>
			</td>
		</ng-container>
		<ng-container *ngIf="haveCommonIcons" matColumnDef="positionActionIcon">
			<th [hidden]="!canViewEditIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 6%">
				Action
			</th>
			<td [hidden]="!canViewEditIcon()" mat-cell *matCellDef="let element">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
					 (click)="onEdit(element)" class="action-icon">
					<g clip-path="url(#clip0_400_3462)">
						<path d="M15.2353 0.765333C14.7821 0.312798 14.1678 0.0586243 13.5273 0.0586243C12.8869 0.0586243 12.2726 0.312798 11.8193 0.765333L0.976677 11.608C0.666178 11.9168 0.419985 12.284 0.252342 12.6885C0.0846994 13.093 -0.00106532 13.5268 9.98748e-06 13.9647V15.3333C9.98748e-06 15.5101 0.0702479 15.6797 0.195272 15.8047C0.320296 15.9298 0.489866 16 0.666677 16H2.03534C2.47319 16.0012 2.90692 15.9156 3.31145 15.7481C3.71597 15.5805 4.08325 15.3344 4.39201 15.024L15.2353 4.18067C15.6877 3.72746 15.9417 3.11331 15.9417 2.473C15.9417 1.83269 15.6877 1.21854 15.2353 0.765333ZM3.44934 14.0813C3.07335 14.4548 2.56532 14.6651 2.03534 14.6667H1.33334V13.9647C1.33267 13.7019 1.38411 13.4417 1.4847 13.199C1.58529 12.9563 1.73302 12.7359 1.91934 12.5507L10.148 4.322L11.6813 5.85533L3.44934 14.0813ZM14.292 3.238L12.6213 4.90933L11.088 3.37933L12.7593 1.708C12.86 1.60754 12.9795 1.52789 13.111 1.47361C13.2424 1.41932 13.3833 1.39146 13.5255 1.39161C13.6678 1.39177 13.8086 1.41994 13.9399 1.47451C14.0712 1.52908 14.1905 1.60899 14.291 1.70967C14.3915 1.81035 14.4711 1.92983 14.5254 2.06129C14.5797 2.19275 14.6076 2.33362 14.6074 2.47585C14.6072 2.61807 14.5791 2.75888 14.5245 2.89022C14.4699 3.02156 14.39 3.14087 14.2893 3.24133L14.292 3.238Z"
							  fill="#2D577C" />
					</g>
					<defs>
						<clipPath id="clip0_400_3462">
							<rect width="16" height="16" fill="white" />
						</clipPath>
					</defs>
				</svg>
				<svg class="ms-2 action-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" (click)="onDelete(element)">
					<g clip-path="url(#clip0_400_3465)">
						<path d="M14 2.66667H11.9334C11.7786 1.91428 11.3693 1.23823 10.7742 0.752479C10.1792 0.266727 9.43484 0.000969683 8.66671 0L7.33337 0C6.56524 0.000969683 5.82092 0.266727 5.22587 0.752479C4.63083 1.23823 4.22144 1.91428 4.06671 2.66667H2.00004C1.82323 2.66667 1.65366 2.7369 1.52864 2.86193C1.40361 2.98695 1.33337 3.15652 1.33337 3.33333C1.33337 3.51014 1.40361 3.67971 1.52864 3.80474C1.65366 3.92976 1.82323 4 2.00004 4H2.66671V12.6667C2.66777 13.5504 3.0193 14.3976 3.64419 15.0225C4.26908 15.6474 5.11631 15.9989 6.00004 16H10C10.8838 15.9989 11.731 15.6474 12.3559 15.0225C12.9808 14.3976 13.3323 13.5504 13.3334 12.6667V4H14C14.1769 4 14.3464 3.92976 14.4714 3.80474C14.5965 3.67971 14.6667 3.51014 14.6667 3.33333C14.6667 3.15652 14.5965 2.98695 14.4714 2.86193C14.3464 2.7369 14.1769 2.66667 14 2.66667ZM7.33337 1.33333H8.66671C9.08022 1.33384 9.48346 1.46225 9.82112 1.70096C10.1588 1.93967 10.4143 2.27699 10.5527 2.66667H5.44737C5.58576 2.27699 5.84131 1.93967 6.17897 1.70096C6.51662 1.46225 6.91986 1.33384 7.33337 1.33333ZM12 12.6667C12 13.1971 11.7893 13.7058 11.4143 14.0809C11.0392 14.456 10.5305 14.6667 10 14.6667H6.00004C5.46961 14.6667 4.9609 14.456 4.58583 14.0809C4.21075 13.7058 4.00004 13.1971 4.00004 12.6667V4H12V12.6667Z"
							  fill="#2D577C" />
						<path d="M6.66667 12C6.84348 12 7.01305 11.9298 7.13807 11.8047C7.2631 11.6797 7.33333 11.5101 7.33333 11.3333V7.33333C7.33333 7.15652 7.2631 6.98695 7.13807 6.86193C7.01305 6.7369 6.84348 6.66667 6.66667 6.66667C6.48986 6.66667 6.32029 6.7369 6.19526 6.86193C6.07024 6.98695 6 7.15652 6 7.33333V11.3333C6 11.5101 6.07024 11.6797 6.19526 11.8047C6.32029 11.9298 6.48986 12 6.66667 12Z"
							  fill="#2D577C" />
						<path d="M9.33329 12C9.5101 12 9.67967 11.9298 9.8047 11.8047C9.92972 11.6797 9.99996 11.5101 9.99996 11.3333V7.33333C9.99996 7.15652 9.92972 6.98695 9.8047 6.86193C9.67967 6.7369 9.5101 6.66667 9.33329 6.66667C9.15648 6.66667 8.98691 6.7369 8.86189 6.86193C8.73686 6.98695 8.66663 7.15652 8.66663 7.33333V11.3333C8.66663 11.5101 8.73686 11.6797 8.86189 11.8047C8.98691 11.9298 9.15648 12 9.33329 12Z"
							  fill="#2D577C" />
					</g>
					<defs>
						<clipPath id="clip0_400_3465">
							<rect width="16" height="16" fill="white" />
						</clipPath>
					</defs>
				</svg>
				<svg (click)="onOpen(element)" class="ms-2 action-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
					<path fill-rule="evenodd" clip-rule="evenodd"
						  d="M8.00016 3.49984C6.61945 3.49984 5.50016 4.61913 5.50016 5.99984C5.50016 7.38055 6.61945 8.49984 8.00016 8.49984C9.38087 8.49984 10.5002 7.38055 10.5002 5.99984C10.5002 4.61913 9.38087 3.49984 8.00016 3.49984ZM6.50016 5.99984C6.50016 5.17141 7.17174 4.49984 8.00016 4.49984C8.82859 4.49984 9.50016 5.17141 9.50016 5.99984C9.50016 6.82826 8.82859 7.49984 8.00016 7.49984C7.17174 7.49984 6.50016 6.82826 6.50016 5.99984Z"
						  fill="#2D577C" />
					<path fill-rule="evenodd" clip-rule="evenodd"
						  d="M8.00016 0.166504C4.99074 0.166504 2.96369 1.9693 1.78721 3.49775L1.76599 3.5253C1.49992 3.87086 1.25487 4.18912 1.08862 4.56544C0.910592 4.96842 0.833496 5.40763 0.833496 5.99984C0.833496 6.59204 0.910592 7.03126 1.08862 7.43424C1.25487 7.81056 1.49992 8.12882 1.76599 8.47438L1.78721 8.50193C2.96369 10.0304 4.99074 11.8332 8.00016 11.8332C11.0096 11.8332 13.0366 10.0304 14.2131 8.50193L14.2343 8.47439C14.5004 8.12883 14.7455 7.81056 14.9117 7.43424C15.0897 7.03126 15.1668 6.59204 15.1668 5.99984C15.1668 5.40763 15.0897 4.96842 14.9117 4.56544C14.7455 4.18911 14.5004 3.87085 14.2343 3.52529L14.2131 3.49775C13.0366 1.9693 11.0096 0.166504 8.00016 0.166504ZM2.57964 4.1077C3.66592 2.69645 5.43374 1.1665 8.00016 1.1665C10.5666 1.1665 12.3344 2.69645 13.4207 4.1077C13.7131 4.48756 13.8843 4.71454 13.997 4.96954C14.1023 5.20785 14.1668 5.49913 14.1668 5.99984C14.1668 6.50055 14.1023 6.79183 13.997 7.03014C13.8843 7.28513 13.7131 7.51211 13.4207 7.89197C12.3344 9.30323 10.5666 10.8332 8.00016 10.8332C5.43374 10.8332 3.66592 9.30323 2.57964 7.89197C2.28725 7.51211 2.11599 7.28513 2.00334 7.03014C1.89805 6.79183 1.8335 6.50055 1.8335 5.99984C1.8335 5.49913 1.89805 5.20785 2.00334 4.96954C2.11599 4.71454 2.28725 4.48756 2.57964 4.1077Z"
						  fill="#2D577C" />
				</svg>
				<!-- <button mat-icon-button matTooltip="Edit" [matTooltipPosition]="'below'" (click)="onEdit(element)"
					data-automation="edit-button">
					<mat-icon>edit</mat-icon>
				</button> -->
			</td>
		</ng-container>
		<!-- to start button on audit screen -->
		<ng-container *ngIf="hasStartIcon" matColumnDef="positionStartIcon">
			<th [hidden]="!canViewStartIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewStartIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Start" [matTooltipPosition]="'below'" (click)="onStart(element)"
						data-automation="start-button">
					<mat-icon>start</mat-icon>
				</button>
			</td>
		</ng-container>
		<!-- end -->
		<!-- to show custom Icon on Screen -->
		<ng-container *ngIf="hasCustomIcon" matColumnDef="positionCustomIcon">
			<th [hidden]="!canViewCustomIcon()" class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef
				style="width: 3%"></th>
			<td [hidden]="!canViewCustomIcon()" mat-cell *matCellDef="let element">
				<button mat-icon-button matTooltip="Control-Details" [matTooltipPosition]="'below'"
						(click)="onCustomIconClick(element)" data-automation="controlDetail-button">
					<mat-icon>open_in_new</mat-icon>
				</button>
			</td>
		</ng-container>
		<!-- end -->

		<ng-container *ngIf="hasDeleteIcon" matColumnDef="positionDeleteIcon">
			<th class="Inforce-Table-Empty header" mat-header-cell *matHeaderCellDef style="width: 3%"></th>
			<td mat-cell *matCellDef="let element">
				<mat-icon class="Delete-Icon material-icons delete-icon mt-1 blue-icon" matTooltip="Delete"
						  [matTooltipPosition]="'below'" (click)="onDelete(element)">
					delete
				</mat-icon>
			</td>
		</ng-container>

		<ng-container *ngFor="let column of columns; let i = index">

			<ng-container *ngIf="!(column.hidden || column.iconColumn || column.isProjected)"
						  matColumnDef="{{ column.elementAttribute }}">

				<th mat-header-cell mat-sort-header (click)="sortApplied($event,column)"
					class="{{column.columnClass == 'text-underline' ? undefined : column.columnClass}} mat-header-cell"
					[style.width]="column.columnWidth || 'auto'" class="header"
					[disabled]="column.disableSorting" *matHeaderCellDef
					attr.data-automation="table-column--{{ column.label }}">
					<span class="headerTextColor d-inline-block">{{column.label}}</span>
					<ng-container *ngIf="!column.sortDirection">
						<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="d-inline-block ms-2 cursor-pointer">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 4.75592C9.31658 5.08136 8.68342 5.08136 8.29289 4.75592L5 2.01184L1.70711 4.75592C1.31658 5.08136 0.683418 5.08136 0.292894 4.75592C-0.0976312 4.43049 -0.0976312 3.90285 0.292894 3.57741L4.29289 0.244078C4.68342 -0.0813593 5.31658 -0.0813593 5.70711 0.244078L9.70711 3.57741C10.0976 3.90285 10.0976 4.43049 9.70711 4.75592Z" fill="#2D577C" />
							<path fill-rule="evenodd" clip-rule="evenodd" d="M9.70711 8.24408C9.31658 7.91864 8.68342 7.91864 8.29289 8.24408L5 10.9882L1.70711 8.24408C1.31658 7.91864 0.683418 7.91864 0.292894 8.24408C-0.0976312 8.56951 -0.0976312 9.09715 0.292894 9.42259L4.29289 12.7559C4.68342 13.0814 5.31658 13.0814 5.70711 12.7559L9.70711 9.42259C10.0976 9.09715 10.0976 8.56951 9.70711 8.24408Z" fill="#2D577C" />
						</svg>
						<!-- <img src="/assets/images/sortIcon.svg" alt="Sort Ascending" class="d-inline-block ms-2 cursor-pointer"> -->
					</ng-container>
					<ng-container *ngIf="column.sortDirection && column.sortDirection =='asc'">
						<svg width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg" class="d-inline-block ms-2 cursor-pointer">
							<path fill-rule="evenodd" clip-rule="evenodd"
								  d="M9.70711 4.75592C9.31658 5.08136 8.68342 5.08136 8.29289 4.75592L5 2.01184L1.70711 4.75592C1.31658 5.08136 0.683418 5.08136 0.292894 4.75592C-0.0976312 4.43049 -0.0976312 3.90285 0.292894 3.57741L4.29289 0.244078C4.68342 -0.0813593 5.31658 -0.0813593 5.70711 0.244078L9.70711 3.57741C10.0976 3.90285 10.0976 4.43049 9.70711 4.75592Z"
								  fill="#6B9CC8" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								  d="M9.70711 8.24408C9.31658 7.91864 8.68342 7.91864 8.29289 8.24408L5 10.9882L1.70711 8.24408C1.31658 7.91864 0.683418 7.91864 0.292894 8.24408C-0.0976312 8.56951 -0.0976312 9.09715 0.292894 9.42259L4.29289 12.7559C4.68342 13.0814 5.31658 13.0814 5.70711 12.7559L9.70711 9.42259C10.0976 9.09715 10.0976 8.56951 9.70711 8.24408Z"
								  fill="#2D577C" />
						</svg>
						<!-- <img src="/assets/images/Ascending.svg" alt="Sort Descending" > -->
					</ng-container>
					<ng-container *ngIf="column.sortDirection && column.sortDirection =='desc'">
						<svg class="d-inline-block ms-2 cursor-pointer" width="10" height="13" viewBox="0 0 10 13" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd"
								  d="M9.70711 4.75592C9.31658 5.08136 8.68342 5.08136 8.29289 4.75592L5 2.01184L1.70711 4.75592C1.31658 5.08136 0.683418 5.08136 0.292894 4.75592C-0.0976312 4.43049 -0.0976312 3.90285 0.292894 3.57741L4.29289 0.244078C4.68342 -0.0813593 5.31658 -0.0813593 5.70711 0.244078L9.70711 3.57741C10.0976 3.90285 10.0976 4.43049 9.70711 4.75592Z"
								  fill="#2D577C" />
							<path fill-rule="evenodd" clip-rule="evenodd"
								  d="M9.70711 8.24408C9.31658 7.91864 8.68342 7.91864 8.29289 8.24408L5 10.9882L1.70711 8.24408C1.31658 7.91864 0.683418 7.91864 0.292894 8.24408C-0.0976312 8.56951 -0.0976312 9.09715 0.292894 9.42259L4.29289 12.7559C4.68342 13.0814 5.31658 13.0814 5.70711 12.7559L9.70711 9.42259C10.0976 9.09715 10.0976 8.56951 9.70711 8.24408Z"
								  fill="#6B9CC8" />
						</svg>

						<!-- <img src="/assets/images/descending.svg" alt="Sort Descending"> -->
					</ng-container>
				</th>
				<td (click)='onCellClick(column.onClickFn, element, $event)'
					[ngStyle]="column.onClickFn && {'cursor': 'pointer'}"
					[ngClass]="{ 'cursor-pointer': column.onClickFn }" [class]="column.columnClass"
					[style.width]="column.columnWidth || 'auto'" *matCellDef="let element" mat-cell>
					<span *ngIf="!column.isHtml && !column.isBadge && !column.isIconButtonColum && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && !column.hasToolTip"
						  matTooltip="{{revealTruncatedText(accessNestedProperty(element, column.elementAttribute), column.formatFn)}}"
						  (click)="column.isLink && onRowClick(element)" [ngClass]="{ 'cursor-pointer': column.isLink }"
						  matTooltipClass="inforce-tooltip">
						{{
						column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
						accessNestedProperty(element, column.elementAttribute)
						}}
						<div *ngIf="column.elementAttribute == 'cardDetail'" style="float: right;">
							<span class="{{ 'icon ' + getBrand(element) }}"></span>
						</div>
						<mat-icon style="font-size: 10px;" *ngIf="column.onClickFn">open_in_new</mat-icon>
					</span>

					<span *ngIf="!column.isHtml && !column.isBadge  && !column.isIconButtonColum && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.hasToolTip"
						  [matTooltip]="accessNestedProperty(element,column.toolTipText)"
						  (click)="column.isLink && onRowClick(element)" [ngClass]="{ 'cursor-pointer': column.isLink }"
						  matTooltipClass="inforce-tooltip">
						{{
						column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
						accessNestedProperty(element, column.elementAttribute)
						}}

					</span>


					<span *ngIf="!column.options && !column.isIconButtonColum && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.isHtml"
						  matTooltipClass="inforce-tooltip">
						<button mat-icon-button [matMenuTriggerFor]="htmlMenu"
								(click)="AssignHtml(accessNestedProperty(element, column.elementAttribute))">
							<mat-icon>open_in_new</mat-icon>
						</button>
					</span>
					<span *ngIf="!column.options && !column.isHtml && !column.isIconButtonColum && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.isBadge "
						  matTooltipClass="inforce-tooltip">

						<span *ngIf="column.elementAttribute === 'status'" [ngClass]="{
								'badge-atlas-warning': accessNestedProperty(element, column.elementAttribute) === 'Pending',
								'badge-atlas-warning': accessNestedProperty(element, column.elementAttribute) === 'Major',
								'badge-atlas-danger': accessNestedProperty(element, column.elementAttribute) === 'Rejected',
								'badge-atlas-danger': accessNestedProperty(element, column.elementAttribute) === 'Draft',
								'badge-atlas-success': accessNestedProperty(element, column.elementAttribute) === 'Accepted',
								'badge-atlas-success': accessNestedProperty(element, column.elementAttribute) === 'Minor'
							}">
							{{ accessNestedProperty(element, column.elementAttribute) }}
						</span>
						<span *ngIf="column.elementAttribute === 'pendingDate'" [ngClass]="{
								'badge-atlas-danger': column.formatFn(accessNestedProperty(element, column.elementAttribute)) == 'Over Due',
								'badge-atlas-primary': column.formatFn(accessNestedProperty(element, column.elementAttribute)) !== 'Over Due'
							}">
							{{ column.formatFn(accessNestedProperty(element, column.elementAttribute)) }}
						</span>
					</span>

					<span *ngIf="!column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && !column.isIconButtonColum && !column.isHtml && !column.isBadge && column.isControlDocument"
						  matTooltipClass="inforce-tooltip">
						{{
						showControlLinkedText(element, column.linkedControlTextCount)
						}}
						<button mat-icon-button [matMenuTriggerFor]="htmlMenuControlDocument"
								*ngIf="hasControlTextLinked(element, column.linkedControlTextCount)"
								(click)="AssignControlDocuments(element ,column.elementAttribute)">
							<mat-icon>add</mat-icon>
						</button>
					</span>

					<!-- Custom Icon Button Column -->
					<span *ngIf="!column.isHtml && !column.isBadge && !column.options && !column.isArray && !(currentElement && element.id == currentElement.id && showCustomizationSave) && column.isIconButtonColum"
						  matTooltipClass="inforce-tooltip">
						<button *ngIf="column.hasToolTip" mat-icon-button [matTooltip]="column.toolTipText"
								[matTooltipPosition]="column.tooltipPosition" (click)="onCustomButtonIconClick(element)">
							<mat-icon>{{column.iconName}}</mat-icon>
						</button>

						<button *ngIf="!column.hasToolTip" mat-icon-button (click)="onCustomButtonIconClick(element)">
							<mat-icon>{{column.iconName}}</mat-icon>
						</button>
					</span>

					<div class="d-flex justify-content-between"
						 *ngIf="currentElement && element.id == currentElement.id && showCustomizationSave">
						<input type=text [(ngModel)]="currentElement[customizedElementAttribute]" />
						<button class="customized-save-btn btn btn-green"
								(click)="onEditSave(currentElement[customizedElementAttribute])">
							Save
						</button>

					</div>
					<div style="padding: 0px 3.125px; text-align: left; border: 1px solid grey; height: 21px; overflow: hidden; text-overflow: ellipsis;"
						 *ngIf="!(!column.options && !column.isArray) && accessNestedProperty(element, column.elementAttribute) && ((column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length < 2)">
						<div *ngIf="column.type">
							<a *ngIf="column.type === 'email'" target="_blank" rel="noopener noreferrer"
							   [href]="'mailto: ' + (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute))[0]">
								{{
								(column.formatFn
								? column.formatFn(accessNestedProperty(element, column.elementAttribute)) :
								accessNestedProperty(element, column.elementAttribute))[0]
								}}
							</a>
						</div>
						<div *ngIf="!column.type">
							{{
							(column.formatFn ? column.formatFn(accessNestedProperty(element,
							column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute))[0]
							}}
						</div>
					</div>

					<select *ngIf="!column.hasChangeFunc && (column.options || column.isArray) && accessNestedProperty(element, column.elementAttribute) && (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length > 1"
							class="Select">
						<option *ngFor="let data of (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute));"
								[value]="data">
							{{data}}
						</option>
					</select>

					<select *ngIf="column.hasChangeFunc && (column.options || column.isArray) && accessNestedProperty(element, column.elementAttribute) && (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute)).length > 1"
							class="Select" (change)="dropdownChange(element,$event)">
						<option *ngFor="let data of (column.formatFn ? column.formatFn(accessNestedProperty(element, column.elementAttribute)) : accessNestedProperty(element, column.elementAttribute));"
								[value]="data.id">
							{{data.name}}
						</option>
					</select>
				</td>
			</ng-container>
		</ng-container>
		<mat-menu #htmlMenu="matMenu" style="min-width: 512px;" class="table-mat-menu-popup">
			<ng-template matMenuContent>
				<span [innerHTML]="getHtml(HtmlOnDescription)" (click)="showControls($event)" class="innerHtmlCard">

				</span>
			</ng-template>
		</mat-menu>

		<mat-menu #htmlMenuControlDocument="matMenu" style="min-width: 512px;" class="table-mat-menu-popup">
			<ng-template matMenuContent>
				<table class="ms-2">
					<thead>
						<tr>
							<th>Name</th>
							<th>Version</th>
							<th>Status</th>
							<th>Created Date</th>
							<th>Control Linked Text</th>
						</tr>

					</thead>
					<tbody>
						<tr *ngFor="let controlDoc of controlDocuments">
							<td>
								<a routerLink="/Documentation/revision-history/{{controlDoc.controlDocumentId}}"
								   target="_blank">{{controlDoc.controlDocumentName}}</a>
							</td>
							<td>{{controlDoc.version}}</td>
							<td>{{controlDoc.status}}</td>
							<td>{{controlDoc.createdDate}}</td>
							<td [matTooltip]="controlDoc.linkedText" [matTooltipPosition]="'below'">
								{{transformText(controlDoc.linkedText)}}
							</td>
						</tr>
					</tbody>
				</table>
			</ng-template>
		</mat-menu>

		<ng-container *ngIf="hasInnerTable" matColumnDef="innerTable">
			<td mat-cell *matCellDef="let element" [attr.colSpan]="columns.length" class="inner-table">
				<app-atlas-table [columns]="innerColumns" [hasPaginator]="false"
								 [dataSource]="accessNestedProperty(element, innerDataSelector)">
				</app-atlas-table>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="filter()"></tr>
		<tr mat-row *matRowDef="let row; columns: filter();"
			[ngClass]="{'active-times-lips': isBilling == true && row.startedOn !== null  }"></tr>
		<ng-container *ngIf="hasInnerTable">
			<tr mat-row *matRowDef="let row; columns: ['innerTable']" [class.d-none]="!row.expanded"></tr>
		</ng-container>
	</table>

</div>

<mat-paginator [class.d-none]="!hasPaginator" (page)="pageEvent = pageChange($event)"
			   [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>

<mat-paginator [class.d-none]="!hasServerPaging" #paginatorServer *ngIf="this.hasServerPaging"
			   (page)="pageEvent = pageChange($event)" [pageSizeOptions]="customPageSizeOptions" [length]="totalItemsCount"
			   showFirstLastButtons>
</mat-paginator>
<mat-menu #appMenu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item>Settings</button>
		<button mat-menu-item>Help</button>
	</ng-template>
</mat-menu>

<app-quill-control-list *ngIf="showControlListPopUp" [onClose]="closeControlListPopUp.bind(this)"
						[LinkControl]="controlsIds"></app-quill-control-list>