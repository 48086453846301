import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonServices {
  controlsSelectedDocument = new BehaviorSubject<any>(null);
  constructor(private http: HttpClient) {}
}
