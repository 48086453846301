<button mat-icon-button class="filter-button" (click)="click()">
    <!-- <mat-icon>filter_list</mat-icon>
    <span>{{ filterCount > 0 ? 'All Filters' : 'Filters' }}</span> -->
    <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="39" height="33" rx="3.5" stroke="#2D577C" />
        <path
            d="M25.8332 9.5H14.1665C12.988 9.5 12.3987 9.5 12.0326 9.8435C11.6665 10.187 11.6665 10.7398 11.6665 11.8456V12.4204C11.6665 13.2853 11.6665 13.7177 11.8828 14.0762C12.0992 14.4347 12.4944 14.6571 13.2849 15.1021L15.7124 16.4687C16.2427 16.7672 16.5079 16.9165 16.6978 17.0813C17.0932 17.4246 17.3366 17.8279 17.4469 18.3226C17.4998 18.5602 17.4998 18.8382 17.4998 19.3941L17.4998 21.6187C17.4998 22.3766 17.4998 22.7556 17.7098 23.0511C17.9197 23.3465 18.2926 23.4923 19.0383 23.7838C20.6038 24.3958 21.3866 24.7018 21.9432 24.3537C22.4998 24.0055 22.4998 23.2099 22.4998 21.6187V19.3941C22.4998 18.8382 22.4998 18.5602 22.5528 18.3226C22.6631 17.8279 22.9065 17.4246 23.3019 17.0813C23.4918 16.9165 23.757 16.7672 24.2873 16.4687L26.7148 15.1021C27.5053 14.6571 27.9005 14.4347 28.1168 14.0762C28.3332 13.7177 28.3332 13.2853 28.3332 12.4204V11.8456C28.3332 10.7398 28.3332 10.187 27.9671 9.8435C27.6009 9.5 27.0117 9.5 25.8332 9.5Z"
            stroke="#2D577C" stroke-width="1.5" />
    </svg>
    <span *ngIf=" filterCount> 0" class="filter-count">{{ filterCount }}</span>

</button>

<ng-container *ngIf="isFilterPopupOpen" (click)="$event.stopPropagation()">
    <div class="popup-overlay" (click)="closeFilterPopup();">
        <div class="pop-up-box" (click)="$event.stopPropagation()">
            <div class="Pop-Up-Header pt-3 pb-2">
                <h1>Filters</h1>
                <span class="spacer"></span>
                <svg (click)="closeFilterPopup()" class="cursor-pointer cross-button" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 9L9 1M1 1L9 9" stroke="#222222" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
            </div>
            <hr class="HR">
            <ng-container>
                <form [formGroup]="filterForm" class="mt-2">
                    <ng-container *ngIf="filterType === filterTypeEnum.Audits">
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Select Customer</mat-label>
                            <mat-select formControlName="customerIds" name="customerListId" multiple
                                style="background-color: none;">
                                <mat-option *ngFor="let item of customers" [value]="item.id">
                                    {{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Audit based on</mat-label>
                            <mat-select formControlName="documentIds" name="documentsListId" multiple
                                style="background-color: none;">
                                <mat-option *ngFor="let item of documents" [value]="item.id">
                                    {{item.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter Start Date</mat-label>
                            <mat-date-range-input [rangePicker]="startPicker">
                                <input matStartDate placeholder="Start date to" formControlName="startDateFrom">
                                <input matEndDate placeholder="start date from" formControlName="startDateTo">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #startPicker></mat-date-range-picker>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter End Date</mat-label>
                            <mat-date-range-input [rangePicker]="endPicker">
                                <input matEndDate placeholder="End date from" formControlName="endDateTo">
                                <input matStartDate placeholder="End date to" formControlName="endDateFrom">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #endPicker></mat-date-range-picker>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option *ngFor="let item of auditStatus; let i=index" [value]="i+1">
                                    {{auditStatusValues[item]}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="filterType === filterTypeEnum.ControlDocuments">

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Select Department</mat-label>
                            <mat-select formControlName="departmentId" name="departmentListId" multiple
                            >
                                <mat-option (click)="$event.preventDefault(); toggleAllSelection(departments, 'departmentId', $event); "
                                    [value]="selectAllLabel">
                                    Select All
                                </mat-option>
                                <mat-option *ngFor="let item of departments" [value]="item.id" (click)="onSelectionChange('departmentId')">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Select Categories</mat-label>
                            <mat-select formControlName="category" name="categoriesListId" multiple
                               >
                                <mat-option (click)="$event.preventDefault(); toggleAllSelection(categoriesControlDocuments, 'category', $event); "
                                [value]="selectAllLabel">
                                    Select All
                                </mat-option>
                                <mat-option *ngFor="let item of categoriesControlDocuments" [value]="item.id" (click)="onSelectionChange('category')">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Select User</mat-label>
                            <mat-select formControlName="userIds" name="userListId" multiple
                                >
                                <mat-option (click)="$event.preventDefault(); toggleAllSelection(users, 'userIds', $event);"
                                [value]="selectAllLabel">
                                    Select All
                                </mat-option>
                                <mat-option *ngFor="let item of users" [value]="item.id" (click)="onSelectionChange('userIds')">
                                    {{ item.fullName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Status</mat-label>
                            <mat-select formControlName="status">
                                <mat-option [value]="0">Any</mat-option>
                                <mat-option [value]="1">Draft</mat-option>
                                <mat-option [value]="2">Minor</mat-option>
                                <mat-option [value]="3">Major</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Version</mat-label>
                            <input matInput formControlName="version">
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter Created Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="createdDatePicker">
                                <input matStartDate placeholder="Created date from" formControlName="createdDateFrom">
                                <input matEndDate placeholder="Created date to" formControlName="createdDateTo">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #createdDatePicker></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="filterType === filterTypeEnum.DPA">
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Title</mat-label>
                            <input matInput formControlName="title">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter Created Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="createdDatePicker">
                                <input matStartDate placeholder="Created date from" formControlName="createdDateFrom">
                                <input matEndDate placeholder="Created date to" formControlName="createdDateTo">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #createdDatePicker></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="filterType === filterTypeEnum.TPM">
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Vendor Name</mat-label>
                            <input matInput formControlName="vendorName">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter Created Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="createdDatePicker">
                                <input matStartDate placeholder="Created date from" formControlName="createdDateFrom">
                                <input matEndDate placeholder="Created date to" formControlName="createdDateTo">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #createdDatePicker></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="filterType === filterTypeEnum.ROPA">
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Processing Name</mat-label>
                            <input matInput formControlName="processingName">
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="form-field-audit">
                            <mat-label>Enter Created Date Range</mat-label>
                            <mat-date-range-input [rangePicker]="createdDatePicker">
                                <input matStartDate placeholder="Created date from" formControlName="createdDateFrom">
                                <input matEndDate placeholder="Created date to" formControlName="createdDateTo">
                            </mat-date-range-input>
                            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="createdDatePicker"></mat-datepicker-toggle>
                            <mat-date-range-picker #createdDatePicker></mat-date-range-picker>
                        </mat-form-field>
                    </ng-container>
                    <ng-container *ngIf="filterType === filterTypeEnum.Questions">
                        <app-question-filter [updateFilters]="AfterLoadData" *ngIf="openAfterLoad" [onClose]="closeFilterPopup.bind(this)" (addedfileters)="assignToForm($event)">
                        </app-question-filter>
                    </ng-container>
                    <div class="buttons-container mb-2 mt-3">
                        <button class="btn  btn-table-filters-clear" [disabled]="applying" mat-button (click)="clear()">Clear</button>
                        <button class="btn  btn-table-filters-Apply" [disabled]="applying" mat-button (click)="save()">Apply</button>
                    </div>
                </form>
            </ng-container>
        </div>
    </div>
</ng-container>