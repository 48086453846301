<div class="notification-wrapper">
    dfsdfsdfsdf
    <div *ngIf="open" class="notification-overlay">
        <div class="notification-content">
            <!-- Your notification content here -->
            <button class="close-button" (click)="toggleNotifications()">✖</button>
            <p> <b>User Details</b> </p>
            <div class="mt-3">
                    <p><b>Full Name:</b> </p>
                    <p>{{ UserDetails.fullName }}</p>
                    <p><b>Role:</b></p>
                    <p> {{ UserDetails.roleNames }}</p>
                    <p><b>Email:</b> </p>
                    <p>{{ UserDetails.email }}</p>

            </div>
        </div>
    </div>
</div>