import { Component, Input, OnInit } from '@angular/core';
import { FilterType } from '../commen/Enums';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FilterFormConfig } from '../commen/Models';
import { FilterService } from '../Services/FilterService';
import { CustomerService } from 'src/app/Services/customer.service';
import { DocumentService } from 'src/app/Services/document.service';
import { DatePipe } from '@angular/common';
import { AuditStatus } from 'src/app/Common/Enums/Atlas-Enums';
import { DepartmentsService } from 'src/app/Services/departments.service';
import { CategoriesControlDocumentsServices } from 'src/app/Services/categories-control-documentsServices';
import { UserService } from 'src/app/Services/user-services';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit {
  isFilterPopupOpen: boolean = false;
  @Input() filterType: FilterType;
  @Input() onApplyFilter: (filters: any) => void;
  applying: boolean = false;
  @Input()
  set inputValue(value: any) {
    if (value) {
      this.updateFilterCount(value);
    }
  }
  filterForm: UntypedFormGroup;
  filterCount: number = 0;
  public filterTypeEnum = FilterType;
  auditStatus: any = [AuditStatus.New, AuditStatus.Ongoing, AuditStatus.Closed, AuditStatus.Reopened];
  auditStatusValues: any = AuditStatus;
  selectAllLabel = '__0__'
  openAfterLoad = false;
  AfterLoadData = null;
  allSelected = {
    departmentId: false,
    category: false,
    userIds: false
  };
  constructor(private formBuilder: UntypedFormBuilder,
    private filterService: FilterService,
    private customService: CustomerService,
    private departmentsService: DepartmentsService,
    private categoriesControlDocumentService: CategoriesControlDocumentsServices,
    private documentService: DocumentService,
    private userService: UserService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initializeForm(false);
  }
  assignToForm(values){
    this.filterForm.patchValue(values);
  }
  
  initializeForm(open): void {

    this.filterForm = this.formBuilder.group({});
    const formControls = FilterFormConfig[this.filterType] || {};
    for (const key in formControls) {
      if (formControls.hasOwnProperty(key)) {
        this.filterForm.addControl(key, this.formBuilder.control(formControls[key]));
      }
    }
    if (open) {
      this.filterService.getFilterData(this.getFilterTypeString(this.filterType)).subscribe(data => {
        this.openAfterLoad = true;
        this.AfterLoadData = data;
        if (data) {
          for (const key in formControls) {
            if (formControls.hasOwnProperty(key) && data.hasOwnProperty(key)) {
              this.filterForm.get(key)?.setValue(data[key]);
            }
          }
        }
      });
    }

  }
  getFilterTypeString(filterType: FilterType): string {
    if (filterType) {
      return FilterType[filterType];
    }
    return FilterType[FilterType.None];
  }

  click() {
   
    this.isFilterPopupOpen = true;
    switch (this.filterType) {
      case FilterType.Audits:
        this.fetchAuditsData();
        this.initializeForm(true);
        break;
      case FilterType.ControlDocuments:
        this.fetchControlDocumentsData();
        this.initializeForm(true);
        break;
      case FilterType.DPA:
        this.initializeForm(true);
        break;
      case FilterType.TPM:
          this.initializeForm(true);
          break;
      case FilterType.Questions:
          this.initializeForm(true);
          break;
      default:
        break;
    }
  }
  closeFilterPopup(): void {
    this.isFilterPopupOpen = false;
    this.AfterLoadData = null;
    this.openAfterLoad = false;
    console.log("executing")
  }
  clear() {
    this.filterForm.reset();
    this.filterCount = 0;
    this.AfterLoadData = null;
  }
  excludeKeys = ['startDateFrom', 'startDateTo', 'endDateTo', 'endDateFrom', 'createdDateFrom', 'createdDateTo', 'pagingParamsDTO', 'pageNumber', 'filterType', 'keyWords','pageSize'];
  save(data = null): void {
    var values = this.filterForm.value;
    this.updateFilterCount(values)
    if (this.filterType == FilterType.Audits) {
      if (values.startDateFrom) {
        values.startDateFrom = this.datePipe.transform(values.startDateFrom, 'yyyy-MM-dd');
      }
      if (values.startDateTo) {
        values.startDateTo = this.datePipe.transform(values.startDateTo, 'yyyy-MM-dd');
      }
      if (values.endDateFrom) {
        values.endDateFrom = this.datePipe.transform(values.endDateFrom, 'yyyy-MM-dd');
      }
      if (values.endDateTo) {
        values.endDateTo = this.datePipe.transform(values.endDateTo, 'yyyy-MM-dd');
      }
    }
    if (this.filterType == FilterType.ControlDocuments || this.filterType == FilterType.DPA || this.filterType == FilterType.TPM) {
      if (values.createdDateFrom) {
        values.createdDateFrom = this.datePipe.transform(values.createdDateFrom, 'yyyy-MM-dd');
      }
      if (values.createdDateTo) {
        values.createdDateTo = this.datePipe.transform(values.createdDateTo, 'yyyy-MM-dd');
      }
    }
    this.applying = true;
    this.filterService.save(this.filterForm.value, this.getFilterTypeString(this.filterType)).subscribe(data => {
      this.applying = false;
      if (this.onApplyFilter) {
        this.isFilterPopupOpen = false;
        this.openAfterLoad = false;
        this.AfterLoadData = null;
        this.onApplyFilter(this.filterForm.value);
      }
    })

  }
  
  updateFilterCount(filters) {
    let count = 0;
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const controlValue = filters[key];
        if (controlValue && !this.excludeKeys.includes(key)) {
          count++;
        }
      }
    }
    if (this.filterType == FilterType.Audits && (filters['startDateFrom'] || filters['startDateTo'])) {
      count++;
    }
    if (this.filterType == FilterType.Audits && (filters['endDateFrom'] || filters['endDateTo'])) {
      count++;
    }
    if ((this.filterType == FilterType.ControlDocuments || this.filterType == FilterType.DPA  || this.filterType == FilterType.TPM) && (filters['createdDateFrom'] || filters['createdDateTo'])) {
      count++;
    }
    this.filterCount = count;
  }
  //getAuditsInformation
  customers = [];
  departments = [];
  documents = [];
  categoriesControlDocuments = [];
  users = [];
  fetchAuditsData() {
    this.customService.getAll().subscribe(data => {
      this.customers = data;
    })
    this.documentService.getAll().subscribe(data => {
      this.documents = data;
    })
  }
  fetchControlDocumentsData() {
    this.departmentsService.getAll().subscribe(data => {
      this.departments = data;
    })
    this.categoriesControlDocumentService.getAll().subscribe(data => {
      this.categoriesControlDocuments = data;
    })
    this.userService.getAllUsers().subscribe(data => {
      this.users = data
    })
  }

  toggleAllSelection(items: any[], controlName: string, event: Event) {
    event.preventDefault();
    const formControl = this.filterForm.get(controlName);
    if (this.allSelected[controlName]) {
      formControl.setValue([]);
    } else {
      var values = items.map(item => item.id);
      values.push(this.selectAllLabel);
      formControl.setValue(values);
    }
    this.allSelected[controlName] = !this.allSelected[controlName];
  }

  onSelectionChange(controlName: string) {
    const formControl = this.filterForm.get(controlName);
    if (formControl.value.includes(this.selectAllLabel)) {
      // Deselect the "Select All" label if any item is deselected
      this.allSelected[controlName] = false;
      const index = formControl.value.indexOf(this.selectAllLabel);
      if (index > -1) {
        formControl.value.splice(index, 1);
        this.filterForm.get(controlName).setValue(formControl.value)
      }
    } else if (!this.allSelected[controlName]) {
      var completeSelected = false;
      if (controlName == 'departmentId') {
        completeSelected = formControl.value.length == this.departments.length;
      }
      if (controlName == 'category') {
        completeSelected = formControl.value.length == this.categoriesControlDocuments.length;
      }
      if (controlName == 'userIds') {
        completeSelected = formControl.value.length == this.users.length;
      }
      if (completeSelected) {
        var values = formControl.value;
        values.push(this.selectAllLabel);
        formControl.setValue(values)
        this.allSelected[controlName] = true;
      }
    }
  }
}
