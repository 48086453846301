<div *ngIf=" !showRevisionHistory">
  <div class="d-flex mb-2">
    <div></div>
    <span class="spacer"></span>
    <button *ngIf="route | hasPermission:'canCreate'" type="button"
      class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas"
      routerLink="/Documentation/CreatePublishDocument">
      <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
      Create New
    </button>
  </div>
  <div>
    <app-atlas-table [columns]="columns" [dataSource]="documents" [onRowClick]="documentDetail.bind(this)"
      [haveCommonIcons]="true" 
      [hasEditIcon]="true && (route | hasPermission:'canUpdate')" 
      [onOpen]="documentDetail.bind(this)" [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
      [hasPreviewIcon]="true" [customColumnWidths]="true"
      [onEdit]="editPublishDocument.bind(this)" [hasServerPaging]="true" [hasPaginator]="false"
      [totalItems]="totalItems" [pageChange]="pageChangeHandler.bind(this)"> >
    </app-atlas-table>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
  <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="isPublishDocumentDetail && !showRevisionHistory" (click)="$event.stopPropagation()">
  <div class="Pop-Up-Box pop-up-box" (click)="closeDetailPopup();">
    <div class="Pop-Up-Header">
      <span (click)="closeDetailPopup()" class="material-icons">highlight_off</span>
      <h1>Publish Document Detail</h1>
    </div>
    <hr class="HR">

    <div *ngFor="let detail of documentDetails" class="card">
      <div class="card-body">
        <div class="d-flex align-items-center justify-content-between">
          <h5>{{ detail.userName }}</h5>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" [checked]="detail.isRead" disabled>
            <label class="form-check-label">
              Read
            </label>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="mt-1 btn btn-success btn-atlas" (click)="viewPublishedDocument()">View Published
      Document</button>
  </div>
</ng-container>
<app-publish-control-document-detail *ngIf="showRevisionHistory" [controlDocumentId]="publishDocumentId"
  [onClose]="onClose.bind(this)"></app-publish-control-document-detail>