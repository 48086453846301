<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Audit/Question">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
  </div>
  
  <div>
    <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                     [onEdit]="getEditUrl.bind(this)"
                  [onDelete]="onDelete.bind(this)"
                        >
    </app-atlas-table>
  </div>
  
