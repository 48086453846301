import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/Services/notification.service';
import { UserSettingsService } from 'src/app/Services/user-setting-services';
@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.sass']
})
export class UserSettingComponent implements OnInit {
  companyPicture: string;
  companyAddress: string;
  sessionTimeout: number;
  picSelected: boolean = false;
  file: any;
  id: any;
  isLoading = false;
  isLoadingButton = false;
  constructor(
    private userSettingsService: UserSettingsService,
    private notify: NotificationService,
  ) {

  }
  ngOnInit(): void {
    this.isLoading = true;
    this.getUserSettings();
  }
  onPictureSelect(event: any): void {
    this.picSelected = true;

    this.file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.companyPicture = reader.result as string;
    };

    if (this.file) {
      reader.readAsDataURL(this.file);
    }
  }
  getUserSettings() {
    this.userSettingsService.getUserSettings().subscribe(data => {
      if (data != null) {
        this.id = data.session.id
        this.companyAddress = data.session.companyAddress
        this.sessionTimeout = data.session.sessionTimeout
      }
      this.isLoading = false;
    })
  }


  onSubmit(): void {
    this.isLoadingButton = true;
    const formData = new FormData();
    formData.append('companyAddress', this.companyAddress);
    formData.append('sessionTimeout', this.sessionTimeout.toString());
    if (this.companyPicture) {
      // const file = this.getFileFromDataUrl(this.companyPicture);
      if (this.file) {
        formData.append('file', this.file);
      }
    }

    if (this.id) {
      formData.append('id', this.id.toString());
      this.userSettingsService.update(formData).subscribe(data => {
        console.log(data);
        if (data.profile) {
          localStorage.setItem("profile", data.profile);
        }
        this.isLoadingButton = false;
        this.notify.showSuccess('User Settings update Successfully ', "Success");
      })
    }
    else {
      this.userSettingsService.create(formData).subscribe(data => {
        localStorage.setItem("profile", data.profile);
        console.log(data);
        this.isLoadingButton = false;
        this.notify.showSuccess('User Settings Added Successfully ', "Success");
        this.getUserSettings();
      })
    }
  }

  private getFileFromDataUrl(dataUrl: string): File | null {
    const regex = /^data:([A-Za-z-+/]+);base64,(.+)$/;
    const matches = regex.exec(dataUrl);

    if (matches && matches.length === 3) {
      const mimeType = matches[1];
      const base64Data = matches[2];

      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new File(byteArrays, 'filename', { type: mimeType });
    }

    return null;
  }
}