<div class="row">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body"><h5 class="card-title">CIS Level</h5>
        <form [formGroup]="cisLevelForm" (ngSubmit)="createDocumentType()">
          <div class="position-relative mb-3">
            <label for="name" class="form-label">Name</label>
            <input name="name" formControlName="name" id="name" placeholder="CIS Level Name" type="text" class="form-control">
          </div>
          <button class="mt-1 btn btn-success btn-atlas" type="submit">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>