import {
  Component,
  HostListener, ViewChild
} from "@angular/core";
import {
  ActivatedRoute,
  NavigationStart,
  Router,
} from "@angular/router";
import { AuditStatus } from "src/app/Common/Enums/Atlas-Enums";
import {
  AddQuestionFromAuditModel,
  ControlSortedDTO,
  GetAuditingDataDTO,
} from "src/app/Models/AudutingModel";
import { ControlModel } from "src/app/Models/ControlModel";
import { AuditService } from "src/app/Services/audit-services";
import { AuditingService } from "src/app/Services/auditing.service";
import { ControlService } from "src/app/Services/control.service";
import { NotificationService } from "src/app/Services/notification.service";
import { AuditcontrolslistComponent } from "src/app/Shared/auditcontrolslist/auditcontrolslist.component";
import { EllipsisPipe } from "src/app/Shared/pipes/ellipsis.pipe";
import Swal from "sweetalert2";



@Component({
  selector: "app-start-audit",
  templateUrl: "./start-audit.component.html",
  styleUrls: ["./start-audit.component.scss"],
  providers: [EllipsisPipe],
})
export class StartAuditComponent {
  AuditId: any;
  auditingId: any;
  documentsListId: any;
  routeChangeRef: any;
  openDetails = false;
  data: any;
  controlsData: any;
  showfilter: boolean = false;
  auditingDataModelDto: GetAuditingDataDTO = new GetAuditingDataDTO();
  AddQuestionPredefinedData: AddQuestionFromAuditModel = null;
  customerId: any;
  isloading: boolean = false;
  controlDetails: any;
  showAddQuestionPopUp: boolean = false;
  showSortingPopUp: boolean = false;
  auditProgress: any = null;
  showCLoseAuditButton: boolean = false;
  isSaveOnRouteChange: boolean = false;
  changedRoute: string = "";
  scoreList: any = [];
  auditingStatus: boolean = false;
  auditingStatusBtn: boolean = false;
  auditName:any = null;
  @ViewChild(AuditcontrolslistComponent) child: AuditcontrolslistComponent;

  constructor(
    private activatedRouter: ActivatedRoute,
    private auditServices: AuditService,
    private auditingService: AuditingService,
    private notify: NotificationService,
    private controlServices: ControlService,
    private router: Router
  ) {
    this.AuditId = this.activatedRouter.snapshot.paramMap.get("Id");

    this.routeChangeRef = this.router.events.subscribe((event) => {
      if (this.auditingId) {
        this.router.navigate([this.router.url]);
        if (event instanceof NavigationStart) {
          this.changedRoute = event.url;


          this.CloseAuditConfermation(this.auditingId, true);
        }
      }
    });

    if (this.AuditId) {
      this.auditingService.getAuditingId(this.AuditId).subscribe((res) => {
        this.auditingId = res;
        console.log('====324== res =======', res)
        this.auditingService
          .GetAuditingStatus(this.auditingId)
          .subscribe((res) => {
            this.auditingStatus = res;
            console.log('====== res =======', res)
            console.log(this.auditingStatus);
          });
        this.getAll();
      });
    }

    if (
      this.auditingId != null &&
      this.customerId != null &&
      this.documentsListId != null
    ) {
      this.auditingDataModelDto.AuditingId = this.auditingId;
      this.auditingDataModelDto.CustomerId = this.customerId;
      this.auditingDataModelDto.DocIds = this.documentsListId;
    }
  }

  OpenControlDetails(event) {
    let controlId = event.id;
    this.controlServices.get(controlId).subscribe((data) => {
      this.openDetails = true;
      this.controlDetails = data;
      console.log('====== data =======', data)
    });
  }
  @HostListener("document:keydown.escape") close() {
    this.openDetails = false;
    this.showfilter = false;
    this.showAddQuestionPopUp = false;
  }



  ngOnInit(): void {
    this.auditingService.getDocumentationScore().subscribe((res) => {
      this.scoreList = res;
      console.log('====== this.scoreList =======', this.scoreList)

      console.log(res);
      console.log("score list");
    });
  }



  ngOnDestroy(): void {
    this.routeChangeRef.unsubscribe();
  }
  showAddQuestionPopUpModel(event) {
    console.log(event);
    this.AddQuestionPredefinedData = event;
    this.showAddQuestionPopUp = true;
  }

  showFilters() {
    this.showfilter = true;
  }
  refresh() {
    this.child.showSpinner = true;
    this.getAll();
    this.child.showSpinner = false;
  }

  downloadAuditToPDF() {
    let body = ""
    for (const control of this.child.controls) {


      let implementationScore = "None"
      let documentationScore = "None"

      const implementationScoreStatus = this.scoreList.find(scoreList => scoreList.value === control?.implementationScore)
      if (implementationScoreStatus?.name)
        implementationScore = implementationScoreStatus?.name

      const documentationScoreStatus = this.scoreList.find(scoreList => scoreList.value === control?.documentationScore)
      if (documentationScoreStatus?.name)
        documentationScore = documentationScoreStatus?.name

      body += `
        <div class="container page-break-avoid">
        <h1>${control?.documentName} - ${control?.chapter}</h1>
        <ul>
            <li>
                <p class="question">Chapter</p>
                <p>${control?.documentName || "N/A"} </p>
            </li>

            <li>
                <p class="question">Chapter Description</p>
                ${control?.chapterDescription || "N/A"} 
            </li>

            <li>
              <p class="question">Document</p>
              <p>${control?.document || "N/A"} </p>
            </li>

            <li>
              <p class="question">SCF Domain</p>
                      
                      ${control?.scfDomainIds?.length > 0 ?
          control?.scfDomainIds?.map((scfDomainIds, index) =>
            `
                          <li>
                              <p class="scfDomainIds"> <strong> ${++index}. </strong> ${scfDomainIds?.name || "N/A"} </p>
                          </li>
                      `
          ).join(" ") :
          "N/A"
        }
            </li>
            <br>

            <li>
              <p class="question">Description</p>
             ${control?.description || "N/A"} 
            </li>


            <li>
              <p class="question">Objective</p>
              ${control?.objective || "N/A"} 
            </li>

            <li>
              <p class="question">Auditing Guidelines</p>
              ${control?.auditingGuideLines || "N/A"} 
            </li>

            <li>
              <p class="question">Other Information</p>
              ${control?.otherInformation || "N/A"} 
            </li>
            <br>

            <li>
            <p class="question">Questions</p>
                    
                    ${control?.questionsViewModels?.length > 0 ?
          control?.questionsViewModels?.map((questionsViewModel, index) =>
            `
                        <li>
                          <p class="question">${++index}. ${questionsViewModel?.question}</p>
                          <p>${questionsViewModel?.answer || "N/A"} </p>
                        </li>
                    `
          ).join(" ") :
          "N/A"
        }
          </li>

          <br>

          <li>
          <p class="question">Implementation Score</p>
          ${implementationScore} 
        </li>

        <li>
        <p class="question">Documentation Score</p>
        ${documentationScore} 
      </li>


      <li>
      <p class="question">Identified Criticality</p>
      ${control?.identifiedCriticality || "N/A"} 
    </li>


          <li>
          <p class="question">Observations</p>
          ${control?.observation || "N/A"} 
        </li>

        <li>
          <p class="question">Suggested Action</p>
          ${control?.suggestedAction || "N/A"} 
        </li>




        </ul>
      </div>`
    }

    const auditPDFHtml = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <title>Chapter Page</title> -->
        <style>
            body {
                font-family: 'Arial', sans-serif;
                margin: 0;
                padding: 0;
            }
    
            .container {
                max-width: 800px;
                margin: 20px auto;
                padding: 20px;
                border-radius: 5px;
            }
    
            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #000;
                padding: 10px;
                border-radius: 5px;
                margin-bottom: 20px;
            }
    
            ul {
                list-style: none;
                padding: 0;
            }
    
            li {
                margin-bottom: 10px;
            }
    
            .question {
                font-weight: bold;
            }
            .scfDomainIds {
              font-weight: normal;
          }
    
          .page-break-avoid {
            page-break-inside: avoid;
        }
            @media only screen and (max-width: 600px) {
                .container {
                    padding: 10px;
                }
            }
        </style>
    </head>
    
    <body>
    ${body}
    </body>

</html>
    `


  this.auditServices.downloadPdf(auditPDFHtml, this.auditName).subscribe(
    (response: any) => {
      // Convert the Base64-encoded PDF content to a Uint8Array
      // const pdfData = new Uint8Array(atob(response.content).split('').map(char => char.charCodeAt(0)));
  
      // Create a Blob from the PDF data
      const blob = new Blob([response], { type: 'application/pdf' });
  
      // Create a download link for the blob
      const url = window.URL.createObjectURL(blob);
  
      // Create an anchor element and set its attributes
      const a = document.createElement('a');
      a.href = url;
      a.download = this.auditName + '.pdf';
  
      // Append the anchor element to the body and click it to trigger the download
      document.body.appendChild(a);
      a.click();
  
      // Remove the anchor element from the body
      document.body.removeChild(a);
  
      // Clean up by revoking the object URL
      window.URL.revokeObjectURL(url);
    },
    error => {
      // Handle error appropriately
      console.error('Failed to download PDF:', error);
    }
  );
}  
  saveAuditToPDF() {
    let body = ""
    console.log('====== this.child =ss======', this.child)
    for (const control of this.child.controls) {


      let implementationScore = "None"
      let documentationScore = "None"

      const implementationScoreStatus = this.scoreList.find(scoreList => scoreList.value === control?.implementationScore)
      if (implementationScoreStatus?.name)
        implementationScore = implementationScoreStatus?.name

      const documentationScoreStatus = this.scoreList.find(scoreList => scoreList.value === control?.documentationScore)
      if (documentationScoreStatus?.name)
        documentationScore = documentationScoreStatus?.name

      body += `
        <div class="container page-break-avoid">
        <h1>${control?.documentName} - ${control?.chapter}</h1>
        <ul>
            <li>
                <p class="question">Chapter</p>
                <p>${control?.documentName || "N/A"} </p>
            </li>

            <li>
                <p class="question">Chapter Description</p>
                ${control?.chapterDescription || "N/A"} 
            </li>

            <li>
              <p class="question">Document</p>
              <p>${control?.document || "N/A"} </p>
            </li>

            <li>
              <p class="question">SCF Domain</p>
                      
                      ${control?.scfDomainIds?.length > 0 ?
          control?.scfDomainIds?.map((scfDomainIds, index) =>
            `
                          <li>
                              <p class="scfDomainIds"> <strong> ${++index}. </strong> ${scfDomainIds?.name || "N/A"} </p>
                          </li>
                      `
          ).join(" ") :
          "N/A"
        }
            </li>
            <br>

            <li>
              <p class="question">Description</p>
             ${control?.description || "N/A"} 
            </li>


            <li>
              <p class="question">Objective</p>
              ${control?.objective || "N/A"} 
            </li>

            <li>
              <p class="question">Auditing Guidelines</p>
              ${control?.auditingGuideLines || "N/A"} 
            </li>

            <li>
              <p class="question">Other Information</p>
              ${control?.otherInformation || "N/A"} 
            </li>
            <br>

            <li>
            <p class="question">Questions</p>
                    
                    ${control?.questionsViewModels?.length > 0 ?
          control?.questionsViewModels?.map((questionsViewModel, index) =>
            `
                        <li>
                          <p class="question">${++index}. ${questionsViewModel?.question}</p>
                          <p>${questionsViewModel?.answer || "N/A"} </p>
                        </li>
                    `
          ).join(" ") :
          "N/A"
        }
          </li>

          <br>

          <li>
          <p class="question">Implementation Score</p>
          ${implementationScore} 
        </li>

        <li>
        <p class="question">Documentation Score</p>
        ${documentationScore} 
      </li>


      <li>
      <p class="question">Identified Criticality</p>
      ${control?.identifiedCriticality || "N/A"} 
    </li>


          <li>
          <p class="question">Observations</p>
          ${control?.observation || "N/A"} 
        </li>

        <li>
          <p class="question">Suggested Action</p>
          ${control?.suggestedAction || "N/A"} 
        </li>




        </ul>
      </div>`
    }



    console.log('====== body =======', body)




    const auditPDFHtml = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <title>Chapter Page</title> -->
        <style>
            body {
                font-family: 'Arial', sans-serif;
                margin: 0;
                padding: 0;
                background-color: #f2f2f2;
            }
    
            .container {
                max-width: 800px;
                margin: 20px auto;
                padding: 20px;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border-radius: 5px;
            }
    
            h1 {
                text-align: center;
                font-size: 1.5em;
                color: #000;
                padding: 10px;
                border-radius: 5px;
                margin-bottom: 20px;
            }
    
            ul {
                list-style: none;
                padding: 0;
            }
    
            li {
                margin-bottom: 10px;
            }
    
            .question {
                font-weight: bold;
            }
            .scfDomainIds {
              font-weight: normal;
          }
    
          .page-break-avoid {
            page-break-inside: avoid;
        }
            @media only screen and (max-width: 600px) {
                .container {
                    padding: 10px;
                }
            }
        </style>
    </head>
    
    <body>
    ${body}
    </body>

</html>
    `
    const printWindow = window.open('', '');
    printWindow.document.write(auditPDFHtml)
    printWindow.document.close();
    printWindow.print();

  }
  applyFilter(event: ControlModel) {

    this.child.showSpinner = true;
    if (event == null) {
      this.getAll();
      return;
    }
    event.customerId = this.customerId;
    event.auditingId = this.auditingId;
    console.log(event)
    this.auditingService.ApplyFilters(event).subscribe((res) => {
      console.log(res);
      this.controlsData = res;
      this.child.showSpinner = false;
    },
      () => {
        this.child.showSpinner = false;
      });
  }
  getAll() {



    console.log('====== this.child =======', this.child)
    this.auditServices.get(this.AuditId).subscribe(
      (data) => {
        console.log('====== data.status =======', data.status)
        console.log(data.status);
        if (AuditStatus.Closed != data.status) {
          this.auditingStatusBtn = false;
        } else {
          this.auditingStatusBtn = true;
        }
        this.documentsListId = data.documentId;
        this.customerId = data.customerId;
        this.auditName = data.name;
        this.auditingDataModelDto.AuditingId = this.auditingId;
        this.auditingDataModelDto.CustomerId = this.customerId;
        this.auditingDataModelDto.DocIds = this.documentsListId;
        this.auditingService.getData(this.auditingDataModelDto).subscribe(
          (res) => {
            console.log(res);
            this.controlsData = res;
            this.auditingService.getAuditProgress(res).subscribe((res) => {
              this.auditProgress = res;
            });
            this.isloading = false;
            this.child.showSpinner = false;
          },
          (error) => {
            console.log(error);
            this.isloading = false;
            this.child.showSpinner = false;
          }
        );
      },
      () => {
        alert("api failed cant call");
        this.isloading = false;
        this.child.showSpinner = false;
      }
    );
  }
  onClose(event) {
    this.openDetails = false;
    this.showfilter = false;
    this.showAddQuestionPopUp = false;
    this.showSortingPopUp = false;
  }

  CreateAuditQuestionAnswer(auditQASaveModel) {
    let auditQAModel = auditQASaveModel.value;
    auditQAModel.AuditingAnswers.forEach((x) => {
      x.CustomerId = this.customerId;
      x.AuditingId = this.auditingId;
    });
    auditQAModel.AuditingId = this.auditingId;
    this.auditingService.createAuditQuestionAnswer(auditQAModel).subscribe(
      () => {
        console.log("saved succesfully");
        if (auditQASaveModel && !auditQASaveModel.isAutoSave) {
          this.child.showSpinner = false;
          this.notify.showSuccess(
            "Created successfully",
            "Audit Question Answer"
          );
        }
      },
      () => {
        if (auditQASaveModel && !auditQASaveModel.isAutoSave) {
          this.notify.showError(
            "Error while creating",
            "Audit Question Answer"
          );
        }
      }
    );
    console.log(auditQAModel);
  }

  isQuestionCreated(event) {
    if (event) {
      let auditingDTO = new GetAuditingDataDTO();
      auditingDTO.AuditingId = this.AuditId;
      auditingDTO.ControlId = this.AddQuestionPredefinedData.controlId;
      auditingDTO.CustomerId = this.customerId;

      this.notify.showSuccess("Created successfully", "Audit Question");
      this.auditingService
        .getData(this.auditingDataModelDto)
        .subscribe((res) => {
          if (res) {
            this.controlsData = res;
            console.log('====== this.controlsData =======', this.controlsData)
          }
        });
      this.onClose(event);
    } else {
      this.notify.showError("Error while creating", "Audit Question");
    }
  }

  isQuestionEdited(event) {
    if (event) {
      this.notify.showSuccess("Edited successfully", "Audit Question");
      this.auditingService
        .getData(this.auditingDataModelDto)
        .subscribe((res) => {
          if (res) {
            this.controlsData = res;
          }
        });
      this.onClose(event);
    } else {
      this.notify.showError("Error while Editing", "Audit Question");
    }
  }

  showSortingDialog() {
    this.showSortingPopUp = true;
  }

  applySorting(event: ControlSortedDTO) {
    this.showSortingPopUp = false;
    this.child.showSpinner = true;
    event.controlsViewModel = this.controlsData;
    console.log(event);

    this.auditingService.applySorting(event).subscribe((res) => {
      this.controlsData = res;
      console.log("sorited data", res);
    });
  }

  CloseAuditConfermation(auditingId, changeroute: boolean = false) {
    if (!auditingId) {
      return;
    }
    if (this.child.onClosAuditCheckChange()) {
      this.showSwal(auditingId, changeroute);
    } else {
      this.routeChangeRef.unsubscribe();

      if (!changeroute) {
        this.CloseAudit(auditingId);
      } else {
        this.router.navigate([this.changedRoute]);
      }
    }
  }

  showSwal(auditingId, changeroute: boolean = false) {
    Swal.fire({
      title: "Do you want to Save Changes?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Save it!",
      cancelButtonText: "No, Don't Save",
    }).then((result) => {
      if (result.value) {
        this.child.autoSaveAudit();
        this.routeChangeRef.unsubscribe();
        if (!changeroute) {
          setTimeout(() => {
            this.CloseAudit(auditingId);
          }, 1000);
        } else {
          setTimeout(() => {
            this.router.navigate([this.changedRoute]);
          }, 1000);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.routeChangeRef.unsubscribe();

        if (!changeroute) {
          this.CloseAudit(auditingId);
        } else {
          this.router.navigate([this.changedRoute]);
        }
      }
    });
  }
  CloseAudit(auditingId) {
    if (!auditingId) {
      return;
    }
    this.auditServices
      .updateAuditStatus(AuditStatus.Closed, this.AuditId)
      .subscribe(() => { });
    this.auditingService.closeAuditing(1, auditingId).subscribe(() => {
      this.notify.showSuccess("Auditing Close Successfully", "Audit");
      this.router.navigate(["/Audit/Audits"]);
    });
  }
  SaveAuditAll() {
    this.child.autoSaveAudit();
    this.notify.showSuccess("Save Successfully", "");
  }
  ReopenAudit(auditingId) {
    Swal.fire({
      title: "Are you sure want to Reopen Audit?",
      text: "New questions will be added and no existing questions and answers will be changed!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Reopen Audit!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        Swal.fire({
          title: "Do you want to add additional Controls and Questions?",
          // text: "New questions will be added and no existing questions and answers will be changed!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes!",
          cancelButtonText: "No",
        }).then((result) => {
          if (result.value) {
            this.auditServices
              .updateAuditStatus(AuditStatus.Reopened, this.AuditId)
              .subscribe(() => { });
            this.auditingService
              .closeAuditing(0, auditingId)
              .subscribe(() => {
                this.notify.showSuccess(
                  "Auditing Reopen Successfully",
                  "Audit"
                );
                window.location.reload();
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            this.auditServices
              .updateAuditStatus(AuditStatus.Reopened, this.AuditId)
              .subscribe(() => {
                this.notify.showSuccess(
                  "Auditing Reopen Successfully",
                  "Audit"
                );
                window.location.reload();
              });
          }
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Audit is still closed :)", "error");
      }
    });
  }
}
