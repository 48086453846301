import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-questions-listing',
  templateUrl: './questions-listing.component.html',
  styleUrls: ['./questions-listing.component.sass']
})
export class QuestionsListingComponent  {

  constructor(private router: Router,) { }
  heading = "Audit Questions";
  subheading = "Audit Questions inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  cisLevelId: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;

  

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionDeleteIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionEditIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];

  
  getEditUrl(event: any) {
    this.router.navigate(["/Dashboard/Setup/CreateCISLevel/" + event.id]);
  }

  onDelete(event) {
    this.cisLevelId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
      // call the delete api here
        
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }
}
