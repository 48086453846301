import { Component, Input, OnInit } from "@angular/core";
import { QuestionsViewModel } from "src/app/Models/Audit.Models";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-question-list",
  templateUrl: "./question-list.component.html",
  styleUrls: ["./question-list.component.scss"],
})
export class QuestionListComponent implements OnInit {
  constructor(protected _sanitizer: DomSanitizer) {}
  description2: SafeHtml;
  Question: any;
  description: any;
  @Input() Questions: any;
  @Input() IncomingQuestions: QuestionsViewModel[] = [];
  @Input()
  onEdit: (event) => {};
  @Input()
  onDelete(event) {}
  ngOnInit(): void {
    const allQuestions = {
      question: this.Question,
      description: this.description,
    };

    for (let incomingQuestion of this.IncomingQuestions) {
      allQuestions.question = incomingQuestion.question;
      allQuestions.description = this._sanitizer.bypassSecurityTrustHtml(
        incomingQuestion.description
      );
      this.Questions.push(allQuestions);
    }

  }
}
