import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule, Routes } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MonacoEditorModule } from "ngx-monaco-editor";
import { PermissionAction, PermissionType } from "../Enum/permission-type";
import { PermissionsGuard } from "../Guard/permissions-guard";
import { SharedModule } from "../Shared/Shared.module";
import { ControlDocumentsCreateComponent } from "./control-documents/control-documents-create/control-documents-create.component";
import { ControlDocumentsDetailsComponent } from "./control-documents/control-documents-details/control-documents-details.component";
import { ControlDocumentsListComponent } from "./control-documents/control-documents-list/control-documents-list.component";
import { CreateKnowledgeDataBaseComponent } from "./create-knowledge-data-base/create-knowledge-data-base.component";
import { KnowledgeDataBaseListComponent } from "./knowledge-data-base-list/knowledge-data-base-list.component";
import { ComplianceComponent } from './compliance/compliance.component';
import { RevisionHistoryComponent } from './control-documents/revision-history/revision-history.component';
import { ControlDocumentLogChangePopupComponent } from './control-documents/control-document-log-change-popup/control-document-log-change-popup.component';
import { DateHelper } from "../Common/Helpers/date.helper";
import { RichTextEditorAllModule } from "@syncfusion/ej2-angular-richtexteditor";
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ContextMenuModule } from "@syncfusion/ej2-angular-navigations";
import { PublishControlDocumentComponent } from './publish-control-document/publish-control-document.component';
import { PublishControlDocumentCreateComponent } from './publish-control-document/publish-control-document-create/publish-control-document-create.component';
import { PublishControlDocumentDetailComponent } from './publish-control-document/publish-control-document-detail/publish-control-document-detail.component';
const routes: Routes = [
  {
    path: "PublishedControlDocument",
    component: PublishControlDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PublishDocument,
    },
  },
  {
    path: "PublishedControlDocument/:id",
    component: PublishControlDocumentComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PublishDocument,
    },
  },
  {
    path: "CreatePublishDocument",
    component: PublishControlDocumentCreateComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PublishDocument,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "CreatePublishDocument/:id",
    component: PublishControlDocumentCreateComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.PublishDocument,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "KnowledgeDataBase",
    component: KnowledgeDataBaseListComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.KnowledgeDataBase,
    },
  },
  {
    path: "KnowledgeDataBaseCreate",
    component: CreateKnowledgeDataBaseComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.KnowledgeDataBase,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "KnowledgeDataBaseCreate/:id",
    component: CreateKnowledgeDataBaseComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.KnowledgeDataBase,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "ControlDocumentsList",
    component: ControlDocumentsListComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlDocuments,
    },
  },
  {
    path: "ControlDocumentsCreate",
    component: ControlDocumentsCreateComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlDocuments,
      permissionAccess: PermissionAction.Create
    },
  },
  {
    path: "ControlDocumentsCreate/:id",
    component: ControlDocumentsCreateComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlDocuments,
      permissionAccess: PermissionAction.Update
    },
  },
  {
    path: "compliance",
    component: ComplianceComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlDocuments,
    },
  },
  {
    path: "revision-history/:id",
    component: RevisionHistoryComponent,
    canActivate: [PermissionsGuard],
    data: {
      extraParameter: "elementsMenu",
      permissionType: PermissionType.ControlDocuments,
      permissionAccess: PermissionAction.Update
    },
  },
];
@NgModule({
  declarations: [
    KnowledgeDataBaseListComponent,
    CreateKnowledgeDataBaseComponent,
    ControlDocumentsListComponent,
    ControlDocumentsCreateComponent,
    ControlDocumentsDetailsComponent,
    ComplianceComponent,
    RevisionHistoryComponent,
    ControlDocumentLogChangePopupComponent,
    PublishControlDocumentComponent,
    PublishControlDocumentCreateComponent,
    PublishControlDocumentDetailComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    RichTextEditorAllModule,
    DialogModule,
    ContextMenuModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MonacoEditorModule.forRoot(),
    RouterModule.forChild(routes),
    MatTabsModule,
    MatMenuModule,

  ],
  providers: [
    DateHelper,
  ]
})
export class DocumentationModule { }
