import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentTypeService } from 'src/app/Services/document-type.services';
import { TitleService } from 'src/app/Services/title.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';

@Component({
	selector: 'app-document-types',
	templateUrl: './document-types.component.html',
	styleUrls: ['./document-types.component.sass'],
	providers: [HasPermissionPipe]
})
export class DocumentTypesComponent implements OnInit {
	heading = 'Document Types';
	subheading = 'Document Types that you will be uploading inside system';
	icon = 'pe-7s-document icon-gradient bg-premium-dark';
	constructor(private docTypeService: DocumentTypeService,
		private activeRoute: ActivatedRoute,
		private hasPermissionPipe: HasPermissionPipe,
		private router: Router, private modalService: NgbModal,
		private titleService: TitleService
	) {
		this.titleService.setTitle('Document Types');
	}
	data = [];
	closeResult: string;
	faStar = faStar;
	faPlus = faPlus;
	docTypeId: any;
	route: any;
	customPageSizeOptions = globalConstants.customPageSizeOptions;
	columns: Partial<Column>[] = [];
	initColumns() {
		this.columns = [
			{
				label: 'Name',
				elementAttribute: 'name',
				columnClass: 'matters-TypeWidth',
				isLink: false,
				columnWidth: '200px',
			},
			{
				iconColumn: true,
				elementAttribute: "positionActionIcon",
				columnWidth: "100px",
			  },


		];
	}
	ngOnInit(): void {
		this.getAllDocType();
		this.route = this.activeRoute.snapshot;
		this.initColumns();

	}
	getAllDocType() {
		this.docTypeService.getAllDocumentTypes().subscribe(data => {
			this.data = data;
		})
	}
	getEditUrl(event: any) {
		this.router.navigate(['/Dashboard/Setup/createDocumentTypes/' + event.id])
	}
	onDelete(event) {
		this.docTypeId = event.id;
		Swal.fire({
			title: 'Are you sure want to remove?',
			text: 'You will not be able to recover this file!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Yes, delete it!',
			cancelButtonText: 'No, keep it'
		}).then((result) => {
			if (result.value) {
				this.docTypeService.delete(
					this.docTypeId
				).subscribe(data => {
					this.getAllDocType();
					this.modalService.dismissAll();
				})
				Swal.fire(
					'Deleted!',
					'Selected record has been deleted.',
					'success'
				)
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					'Cancelled',
					'Your record is safe :)',
					'error'
				)
			}
		})
	}
	open(content) {
		this.modalService.open(content).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
	private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	delete() {
		this.docTypeService.delete(this.docTypeId).subscribe(data => {
			this.getAllDocType();
			this.modalService.dismissAll();
		})
	}
	ngOnDestroy() {
		this.titleService.setTitle('');
	}
}
