import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../environments/environment";

const basePath = environment.apiUrl + "Events";

@Injectable({
  providedIn: "root",
})
export class PublishControlDocumentServices {
  publishDocumentBasePath = environment.apiUrl + "PublishDocument";

  constructor(private http: HttpClient) { }

  getAll(body): any {
    return this.http.post<any>(this.publishDocumentBasePath + "/GetAll", body);
  }
  create(body): any {
    return this.http.post<any>(this.publishDocumentBasePath + "/Create", body);
  }
  update(body): any {
    return this.http.post<any>(this.publishDocumentBasePath + "/Update", body);
  }
  delete(body): any {
    return this.http.post<any>(this.publishDocumentBasePath + "/Delete", body);
  }
  get(id): any {
    return this.http.get<any>(this.publishDocumentBasePath + "/Get/" + id);
  }
  getDetail(id): any {
    return this.http.get<any>(this.publishDocumentBasePath + "/GetDetail/" + id);
  }
  getPublishedDocument(id): any {
    return this.http.get<any>(this.publishDocumentBasePath + "/PublishDocumentDetail/" + id);
  }
  readAssignedDocument(id): any {
    return this.http.get<any>(this.publishDocumentBasePath + "/MarkRead/" + id);
  }
  checkRead(id): any {
    return this.http.get<any>(this.publishDocumentBasePath + "/CheckRead/" + id);
  }
}
