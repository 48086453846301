import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { dateTimeFormats } from "src/app/Common/consntants";
import { DocumentationService } from "src/app/Services/documentationServices";
import { TitleService } from "src/app/Services/title.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-knowledge-data-base-list",
  templateUrl: "./knowledge-data-base-list.component.html",
  styleUrls: ["./knowledge-data-base-list.component.sass"],
  providers: [HasPermissionPipe]

})
export class KnowledgeDataBaseListComponent implements OnInit {
  knowLedgeDataBaseId: any;
  constructor(
    private documentService: DocumentationService,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe,
    private titleService: TitleService
  ) {
    this.titleService.setTitle("Knowledge Database");
  }

  heading = "Knowledge Database";
  dateFormat = dateTimeFormats.date;
  subheading = "Knowledge Database That you will be creating";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  route: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  columns: Partial<Column>[] = [];

  initColumns() {
    this.columns = [
      {
        label: "Name",
        elementAttribute: "name",
        columnClass: "matters-TypeWidth",
        isLink: false,
        columnWidth: "150px",
      },
      {
        label: "Category",
        elementAttribute: "categories",
        columnClass: "matters-TypeWidth",
        formatFn: (categories) => categories.map((c) => c.name),
        isLink: false,
        isArray: true,
        columnWidth: "200px",
      },
      {
        label: "Description",
        elementAttribute: "description",
        columnClass: "matters-TypeWidth",
        isLink: false,
        isHtml: true,
        columnWidth: "200px",
      },
      {
        iconColumn: true,
        elementAttribute: "positionActionIcon",
        columnWidth: "100px",
      },
    ];
  }
  ngOnInit(): void {
    this.getAll();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }
  ngOnDestroy() {
    this.titleService.setTitle('');
  }
  getAll() {
    this.documentService.getAllKnowledgeDataBase().subscribe((res) => {
      this.data = res;
    });
  }
  getEditUrl(event: any) {
    this.router.navigate([
      "/Documentation/KnowledgeDataBaseCreate/" + event.id,
    ]);
  }
  onDelete(event) {
    this.knowLedgeDataBaseId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.documentService
          .deleteKnowledgeDataBase(this.knowLedgeDataBaseId)
          .subscribe((data) => {
            this.getAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

}
