export interface NotificationModel {
    id: number;
    content: string;
    itemUrl: string;
    createdOn: string;
    seen: boolean;
}
export function toQueryString(elements: {}): string {
    let pairs = [];

    for (const key in elements) {
        if (elements.hasOwnProperty(key)) {
            const element = elements[key];
            pairs.push(`${key}=${element !== null && element !== undefined ? element : ''}`);
        }
    }

    if (pairs.length === 0) {
        return '';
    }

    return '?' + pairs.join('&');
}