<div class="container">
    <!-- SignUp Email Form -->
    <div *ngIf="showEmailForm">
        <div class="alert alert-success" role="alert" *ngIf="sucessEmailSent">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path
                    d="M8 0a8 8 0 1 0 8 8A8 8 0 0 0 8 0zm2.97 5.282a.277.277 0 0 1 .001-.392l.645-.645a.278.278 0 0 1 .391-.001l.002.002 1.536 1.536a.278.278 0 0 1-.39.392l-.002-.002-1.145-1.144-3.577 3.577a.278.278 0 0 1-.392 0L4.4 7.044a.278.278 0 0 1 0-.392l.645-.645a.278.278 0 0 1 .391-.001l.002.002 1.145 1.145 2.237-2.236a.278.278 0 0 1 .392 0z" />
            </svg>
            Please check and verify your email.
        </div>
        <div class="main-card mb-3 card">
            <div class="card-body">
                <form [formGroup]="emailFormGroup">
                    <h5 class="card-title">Register Email</h5>
                    <div class="position-relative mb-3">
                        <label for="userEmail" class="form-label">User Email</label>
                        <input name="userEmail" id="userEmail" formControlName="userEmail" type="email"
                            placeholder="User Email" class="form-control" [ngClass]="{ 'invalid-input': emailFormGroup.get('userEmail').invalid &&
                    emailFormGroup.get('userEmail').touched}" required>
                        <small *ngIf="emailFormGroup.get('userEmail').hasError('invalidEmail')"
                            class="text text-danger">
                            Please Provide Proper Email As it is Compulsory
                        </small>
                    </div>

                    <div class="position-relative mb-3">
                        <ngx-recaptcha2 [siteKey]="'6LeEpWUmAAAAAEhSHJdsAPvaZQKAsbTVr6UvB_NN'"
                            formControlName="recaptcha" (resolved)="checkCaptcha($event)">
                        </ngx-recaptcha2>
                    </div>
                    <div class="position-relative mb-3" *ngIf="errorMessage">
                        <p class="error-message">{{errorMessage}}</p>
                    </div>
                    <div class="button-container">
                        <button [disabled]="isLoading || submitted" class="btn btn-success btn-atlas ms-2" mat-button
                            (click)="submit()" type="button">Submit</button>
                        <button class="ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="main-card mb-3 card" *ngIf="showPasswordForm || showTenantAdminForm || joinExistingPage">
        <div class="card-body">
            <!-- Password Form -->
            <div *ngIf="showPasswordForm">
                <h5 class="card-title">Set Password</h5>
                <form [formGroup]="passwordFormGroup" autocomplete="off">
                    <label for="password" class="form-label">Password</label>
                    <input name="password" (change)="setValidation()" (focus)="showValidation()"
                        (focusout)="checkValidation($event)" id="password" formControlName="password" type="password"
                        placeholder="Password" class="form-control">
                    <div class="position-relative validationMsg mt-2" *ngIf="displayPasswordValidation"
                        style="background-color: #f1f4f6; border: 1px solid #f1f4f6;">
                        <ul>
                            <li *ngIf="isPasswordLengthValid()" class="text-success">14 characters long.</li>
                            <li *ngIf="!isPasswordLengthValid()" class="text-danger">14 characters long.</li>
                            <li *ngIf="!isPasswordSpecialCharactersValid()" class="text-danger">Must have special
                                characters like (#*&).</li>
                            <li *ngIf="isPasswordSpecialCharactersValid()" class="text-success">Must have special
                                characters like (#*&).</li>
                            <li *ngIf="isPasswordCaseValid()" class="text-success">Must have upper and lower case
                                characters.</li>
                            <li *ngIf="!isPasswordCaseValid()" class="text-danger">Must have upper and lower case
                                characters.</li>
                            <li *ngIf="isPasswordNumericValid()" class="text-success">Must have a numeric value.
                            </li>
                            <li *ngIf="!isPasswordNumericValid()" class="text-danger">Must have a numeric value.
                            </li>
                            <li *ngIf="passwordFormGroup.get('password').value === passwordFormGroup.get('confirmPassword').value && passwordFormGroup.get('password').value"
                                class="text-success">Password and Confirm Password must match.</li>
                            <li *ngIf="passwordFormGroup.get('password').value != passwordFormGroup.get('confirmPassword').value || !passwordFormGroup.get('password').value"
                                class="text-danger">Password and Confirm Password must match.</li>
                        </ul>
                    </div>

                    <label for="confirmPassword" class="form-label">Confirm Password</label>
                    <input name="confirmPassword" (change)="setValidation()" id="confirmPassword"
                        formControlName="confirmPassword" type="password" placeholder="Confirm Password"
                        class="form-control">

                    <div class="button-container">
                        <button class="btn btn-success btn-atlas" type="button" (click)="MoveToCreateOrJoin()" [disabled]="!areAllCriteriaMet()">Next</button>
                        <button class="ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>
                    </div>
                </form>
            </div>
            <!-- Tenant Information -->
            <div *ngIf="showTenantAdminForm">
                <h5 class="card-title">Tenant & Administrator Information</h5>
                <form [formGroup]="signupFormGroup">
                    <div class="position-relative mb-3">
                        <label for="tenantName" class="form-label">Tenant Name</label>
                        <input name="tenantName" id="tenantName" formControlName="tenantName" type="text"
                            placeholder="Tenant Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('tenantName').invalid &&
                                        signupFormGroup.get('tenantName').touched }" required>
                    </div>
                    <div class="position-relative mb-3">
                        <label for="tenantEmail" class="form-label">Tenant Email</label>
                        <input name="tenantEmail" id="tenantEmail" formControlName="tenantEmail" type="email"
                            placeholder="Tenant Email" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('tenantEmail').invalid &&
                                    signupFormGroup.get('tenantEmail').touched}" required>
                        <small *ngIf="signupFormGroup.get('tenantEmail').hasError('invalidEmail')"
                            class="text text-danger">
                            Please Provide Proper Email As it is Compulsory
                        </small>

                    </div>
                    <div class="position-relative mb-3">
                        <label for="firstName" class="form-label">First Name</label>
                        <input name="firstName" id="firstName" formControlName="firstName" type="text"
                            placeholder="First Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('firstName').invalid &&
                                    signupFormGroup.get('firstName').touched }" required>

                    </div>
                    <div class="position-relative mb-3">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input name="lastName" id="lastName" formControlName="lastName" type="text"
                            placeholder="Last Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('lastName').invalid &&
                                    signupFormGroup.get('lastName').touched }" required>

                    </div>

                    <div class="position-relative mb-3" *ngIf="errorMessage">
                        <p class="error-message">{{errorMessage}}</p>
                    </div>
                    <div class="button-container">
                        <button class="ms-2 btn btn-atlas-cancel" type="button" (click)="backToJoin()">Back</button>
                        <button type="button" class="btn btn-success btn-atlas" [disabled]="isLoading || submitted" mat-button
                            (click)="submitSignup()">Submit</button>
                    </div>
                </form>
            </div>
            <!-- Join Existing page -->
            <div *ngIf="joinExistingPage">

                <h5 class="card-title">Join Existing Tenant</h5>
                <div *ngIf="invitationList && invitationList.length > 0; else noTenants">
                    <div *ngFor="let invite of invitationList" class="mb-2">
                        <div class="d-flex justify-content-between align-items-center">
                            <span class="me-2">{{ invite.tenantName }}</span>
                            <button type="button" class="btn btn-success btn-atlas" [disabled]="isLoading || submitted" mat-button
                                (click)="acceptTenant(invite.tenantId, invite.id, invite.tenantName)">Accept</button>
                        </div>
                    </div>
                </div>

                <ng-template #noTenants>
                    <p class="mt-3">There are no existing tenants to join.</p>
                </ng-template>
                <div class="button-container">
                    <button class="btn btn-atlas-cancel" type="button" (click)="backToJoin()">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>





<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;">
    <mat-spinner></mat-spinner>
</div>
<div class="d-flex justify-content-center" *ngIf="showNamePopup ||createOrJoin">
    <div class="Pop-Up-Box pop-up-box">
        <div class="Pop-Up-Header" *ngIf="createOrJoin">
            <h1>Join Atlas</h1>
        </div>
        <div class="Pop-Up-Header" *ngIf="showNamePopup">
            <h1>User Information</h1>
        </div>
        <hr class="HR">
        <!-- Show exsting or create now popup -->
        <div class="position-relative row" *ngIf="createOrJoin">
            <div class="position-relative mb-1">
                <div class="col-sm-12 text-center mb-3 mt-3">
                    <button class="btn btn-success btn-atlas btn-lg" (click)="joinExistingTenant()">Join Existing
                        Tenant</button>
                    <button class="btn btn-primary btn-lg ms-2" (click)="createNewTenant()">Create New
                        Tenant</button>
                </div>
                <div class="col-sm-12 text-center mt-3">
                    <button class="btn btn-danger btn-sm" (click)="MoveBack()">Close</button>
                </div>
            </div>
        </div>
        
        <!-- Show exsting or create now popup -->

        <div class="position-relative row" *ngIf="showNamePopup">
            <div class="position-relative mb-1">
                <form [formGroup]="signupFormGroup">
                  
                    <div class="position-relative mb-3">
                        <label for="firstName" class="form-label">First Name</label>
                        <input name="firstName" id="firstName" formControlName="firstName" type="text"
                            placeholder="First Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('firstName').invalid &&
                                    signupFormGroup.get('firstName').touched }" required>

                    </div>
                    <div class="position-relative mb-3">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input name="lastName" id="lastName" formControlName="lastName" type="text"
                            placeholder="Last Name" class="form-control" [ngClass]="{ 'invalid-input': signupFormGroup.get('lastName').invalid &&
                                    signupFormGroup.get('lastName').touched }" required>

                    </div>
                    <div class="button-container">
                        <button type="button" class="btn btn-success btn-atlas" [disabled]="isLoading || submitted" mat-button
                            (click)="acceptTenant()">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center" *ngIf="duplicateTenant">
    <div class="Pop-Up-Box pop-up-box">
        <div class="Pop-Up-Header">
            <h1>Duplicate Tenant</h1>
        </div>
        <hr class="HR">
        <div class="position-relative row">
            <div class="position-relative mb-1">
                <p>
                    {{dupplicationErrorMessage}}
                </p>
                <div class="col-sm-1">
                    <button class="mb-2 btn btn-success btn-atlas btn-sm small text-small" (click)="MoveBack()">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>