import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ChapterService } from 'src/app/Services/chapter.service';
import { ComplainceService } from 'src/app/Services/complaince.service';
import { ControlService } from 'src/app/Services/control.service';
import { DocumentService } from 'src/app/Services/document.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { globalConstants } from 'src/constants/global-constants';

@Component({
  selector: 'app-compliance',
  templateUrl: './compliance.component.html',
  styleUrls: ['./compliance.component.scss']
})
export class ComplianceComponent implements OnInit {

  documentlist: any;
  chaptersList: any;

  documentId: string;
  documnetIdList: string[] = [];
  chaptersId: string;
  chaptersIdList: string[] = [];
  controlId: string;
  controlIdList: string[] = [];
  controlDocumentsList: any;
  showControlDocumentsSection: boolean = false;
  isLoading: boolean = false;
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  isButtonDisabled: boolean = true;
  isDraft: boolean = false;
  isMinor: boolean = false;
  isManjor: boolean = false;
  openControlDetails: boolean = false;
  controlDetails: any;

  constructor(private documentService: DocumentService,
    private chapterService: ChapterService,
    private controlService: ControlService,
    private complainceService: ComplainceService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.documentService.getList().subscribe((result) => {
      this.documentlist = result;
    });
  }

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      label: "Chapter",
      elementAttribute: "chapterTitle",
      columnClass: "matters-TypeWidth paddingLeft",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Control",
      elementAttribute: "controlTitle",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      iconColumn: true,
      elementAttribute: "positionCustomIcon",
      columnWidth: "2%",
    },

    {
      label: "Linked Control Text",
      elementAttribute: "controlDocumentsList.ControlDocuments",
      columnClass: "matters-TypeWidth",
      linkedControlTextCount: "linkedTextCount",
      isLink: false,
      columnWidth: "200px",
      isControlDocument: true,
    },

  ];




  showControlDocumentDetailPopup(event: any) {

    this.isLoading = true;
    let controlId = event.controlId;
    this.controlService.get(controlId).subscribe(data => {

      this.controlDetails = data;
      this.openControlDetails = true;
      console.log(data)
    },
      error => console.log("api failed"));

    this.isLoading = false;

  }

  onClose(event) {
    this.openControlDetails = false;
  }

  loadChapterForMultipleDocuments() {

    console.log(this.isDraft, this.isMinor)
    if (this.documnetIdList) {
      this.isDisabled();
      this.chapterService.getChaptersForMultipleDocments(this.documnetIdList).subscribe(res => {
        this.chaptersList = res;
      })
    }

    else {
      this.isDisabled();
    }
  }

  isDisabled() {
    this.isButtonDisabled = !(this.documnetIdList.length > 0);
  }

  ShowControlDocuments() {

    let obj = {
      documentIds: this.documnetIdList,
      chapterIds: this.chaptersIdList,
      draft: this?.isDraft ? 1 : null,
      minor: this?.isMinor ? 2 : null,
      major: this?.isManjor ? 3 : null,
    }



    this.isLoading = true
    this.showControlDocumentsSection = false;
    this.complainceService.getControlDocuments(obj).subscribe(res => {
      this.controlDocumentsList = res;
      this.isLoading = false;
      this.showControlDocumentsSection = true
    });

  }

}
