import {
  Component, Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";

import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NotificationService } from "src/app/Services/notification.service";
import { CategoriesControlDocumentsServices } from "src/app/Services/categories-control-documentsServices";
import { ControlDocumentsServices } from "src/app/Services/control-documents.services";
import { ActivatedRoute, Router } from "@angular/router";
import { DiffEditorModel } from "ngx-monaco-editor";
import { CommonFunction } from "src/app/Shared/commen/commen-function";
import { MatMenuTrigger } from "@angular/material/menu";
import { linkQuestionModelForLists } from "src/app/Models/Audit.Models";
import { ControlService } from "src/app/Services/control.service";
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';
import { ContextMenuComponent, MenuEventArgs } from "@syncfusion/ej2-angular-navigations";
import Swal from 'sweetalert2';
import { DepartmentsService } from "src/app/Services/departments.service";

@Component({
  selector: "app-control-documents-create",
  templateUrl: "./control-documents-create.component.html",
  styleUrls: ["./control-documents-create.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class ControlDocumentsCreateComponent implements OnInit {
  controlDocumentForm: UntypedFormGroup;
  heading = "Questions";
  subheading = "Customers inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  faPlus = faPlus;
  descriptionHTMLFormate: string = "";
  description: string = "";
  descriptiondescription: string = "";
  isLoading = false;
  submitForm = false;
  addCat: boolean;
  data: any;
  compareVersionIdFirst: string = "";
  compareVersionIdSecond: string = "";
  historyDocumentationModel: boolean = false;
  versionModelPopUp: boolean = true;
  isEditMode: boolean = false;
  categoriesControlDocumentList = [];
  departmentsList = [];
  AllAuditTrailsList = [];
  linkedControlsList: any
  showControlListPopUp: boolean = false;
  showLogChangePopup: boolean = false;
  linkQuestion: boolean = false;
  @ViewChild(MatMenuTrigger)
  contextMenuPosition = { x: "0px", y: "0px" };
  isTextSelected: boolean = false;
  controlDetails: any;
  controldocumentId: any
  linkedClicked: any;
  openConfirmation = false;
  Title = "Confirmation Publish Control Document";
  ConfirmationMessage = "Do you want to Publish this Control Document?";
  createdId = null;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private notify: NotificationService,
    private router: Router,
    private controlServices: ControlService,
    private route: ActivatedRoute,
    private categoriesControlDocumentsServices: CategoriesControlDocumentsServices,
    private departmentsService: DepartmentsService,
    private controlDocumentsServices: ControlDocumentsServices,
  ) {
    this.controlDocumentForm = this.formBuilder.group({
      id: [""],
      name: ["", [Validators.required]],
      descriptionHTMLFormate: [""],
      description: [""],
      version: ["1.0"],
      status: [null],
      categoriesControlDocumentId: ["", [Validators.required]],
      departmentId: ["", [Validators.required]],
    });
  }
  ngAfterViewInit() {
    const editorElement = this.rteObj.element;
    editorElement.addEventListener('click', this.createdescription.bind(this));
  }
  onConfirm(closeBoth) {
    this.notify.showSuccess("Created Successfully", "Control Documents");
    this.router.navigate(
      ["/Documentation/CreatePublishDocument"],
      { queryParams: { controlDocumentId: this.createdId } }
    );
  }
  ViewControl(Link: string) {
    if (Link) {
      let LinkControl = Link.split('Control-');
      LinkControl.shift();
      this.controlServices.getControlsTitle(LinkControl).subscribe(res => {
        this.linkedControlsList = res;
      })
      this.showControlListPopUp = true
    }
  }
  openDetails = false;
  OpenControlDetails(Id) {
    this.isLoading = true;
    this.controlServices.get(Id).subscribe((data) => {
      this.isLoading = false;
      this.openDetails = true;
      this.controlDetails = data;
    });
  }

  showlinkComponent() {
    this.linkQuestion = true;
  }
  onSpanClick(event: MouseEvent, Id): void {
    this.contextMenuObjView.open(event.pageY, event.pageX);
    this.LinkForLink = Id
  }
  linkQuestionfunc(event: linkQuestionModelForLists) {
    let url = "";
    event.controlId.forEach(element => {
      url = url + `Control-${element}`;
    });
    if (this.selectionRange) {
      // Create a new 'span' element

      const span = document.createElement('span');
      span.setAttribute('rel', 'noopener noreferrer');
      span.setAttribute('id', url);
      span.setAttribute('class', 'linkClass');
      span.setAttribute('style', 'cursor:pointer;background-color:rgb(128, 125, 125);color:white;');
      // Use the NodeSelection module from the RichText Editor package
      const selectedContent = this.selectionRange.cloneContents();

      // Appending the selected content to the span
      span.appendChild(selectedContent);

      this.selectionRange.deleteContents(); // Remove the original content
      this.selectionRange.insertNode(span);

      this.descriptionHTMLFormate = this.rteObj.getHtml();
      this.selectionRange = null;
    }
  }
  onClose() {
    this.linkQuestion = false;
    this.openDetails = false;
    this.historyDocumentationModel = false;
    this.showLogChangePopup = false;
    this.openConfirmation = false;

  }
  onReject() {
    this.openConfirmation = false;
    this.notify.showSuccess("Created Successfully", "Control Documents");
    this.router.navigate(["/Documentation/ControlDocumentsList"]);
  }
  closeComparisonPopup() {
    this.historyDocumentationModel = false;
  }
  EditMode = false;
  ngOnInit(): void {

    this.versionModelPopUp = false;
    this.descriptionHTMLFormate = "";
    this.description = "";
    this.route.params.subscribe((qp) => {
      let databaseId = qp.id;
      this.controldocumentId = qp.id;
      if (databaseId) {
        this.EditMode = true;
        this.isLoading = true;
        this.controlDocumentsServices.get(databaseId).subscribe(
          (respone) => {
            if (respone) {
              this.setValue(respone);
              this.GetAllAuditTrails(respone.id);
              this.isEditMode = true;
              this.isLoading = false;
            }
          },
          (error: any) => {
            console.log(error);
            console.log("error in control document api response");
          }
        );
      }
    });
    this.getAllCats();
    this.getAllDepartments();
  }
  setValue(data: any) {
    this.controlDocumentForm.patchValue({
      id: data.id,
      name: data.name,
      version: CommonFunction.fixedControlDocumentVersion(
        CommonFunction.getControlDocumentStatusIdByName(data.status),
        data.version
      ),
      status: data.status,
      categoriesControlDocumentId: data.categoriesControlDocumentId,
      departmentId: data.departmentId
    });
    this.descriptionHTMLFormate = data.descriptionHTMLFormate ? data.descriptionHTMLFormate : "";
    this.description = data.description ? data.description : "";
    this.data = data;
  }
  getAllCats() {
    this.isLoading = true;
    this.categoriesControlDocumentList = [];
    this.categoriesControlDocumentsServices.getAll().subscribe((data) => {
      this.isLoading = false;
      if (data) {
        this.categoriesControlDocumentList = data;
      }
    });
  }

  getAllDepartments() {
    this.departmentsService.getAll().subscribe((res) => {
      this.departmentsList = res;
    });
  }
  GetAllAuditTrails(controldocumentId: string) {
    this.isLoading = true;
    this.AllAuditTrailsList = [];
    this.controlDocumentsServices.GetAllTrailAuditListByControlDocumentId(controldocumentId)
      .subscribe((data) => {
        this.isLoading = false;
        if (data && data.length > 0) {
          data.forEach((element) => {
            element.createdDate = CommonFunction.formatDate(
              element.createdDate,
              "dd-MM-yyyy hh:mm:ss"
            )
            element.version = CommonFunction.fixedControlDocumentVersion(
              CommonFunction.getControlDocumentStatusIdByName(element.status),
              element.version
            );
          });
          this.AllAuditTrailsList = data;
        }
      },
        (error) => {
          console.log("error has been occured");
          console.log(error);
        }
      );
  }
  SubmitWithStatus(status: number) {
    if (this.controlDocumentForm.invalid) {
      //Message
      this.notify.showError("Invalid Information", "Control Documents");
      return;
    }
    this.controlDocumentForm.controls["status"].setValue(status);
    if (this.EditMode) {
      this.showLogChangePopup = true;
    }
    else {
      this.SubmitControlDocument();
    }
  }
  SaveWithLog(logs) {
    this.isLoading = true;
    this.controlDocumentsServices.CreateControlDocumentChangeLog(
      {
        controlDocumentId: this.controldocumentId,
        logChange: logs
      }).subscribe(res => {
        this.SubmitControlDocument();
      });
  }
  SubmitControlDocument() {
    if (this.controlDocumentForm.invalid) {
      //Message
      this.notify.showError("Invalid Information", "Control Documents");
      return;
    }
    this.description = this.rteObj.getText();
    let saveObj = this.data || {};
    saveObj.name = this.controlDocumentForm.controls["name"].value;
    saveObj.descriptionHTMLFormate = this.descriptionHTMLFormate;
    saveObj.description = this.description;
    saveObj.version = this.controlDocumentForm.controls["version"].value;
    saveObj.status = this.controlDocumentForm.controls["status"].value;
    saveObj.isAcitve = true;
    saveObj.isDelete = false;
    saveObj.categoriesControlDocumentId =
      this.controlDocumentForm.controls["categoriesControlDocumentId"].value;
    saveObj.departmentId =
      this.controlDocumentForm.controls["departmentId"].value;
    if (saveObj?.id && saveObj?.id != "") {
      this.createdId = saveObj.id;
      this.UpdateControlDocument(saveObj);
    } else {
      this.SaveControlDocument(saveObj);
    }
  }

  openConfirmationPublishPopup() {
    this.openConfirmation = true;
    this.Title = "Confirmation Message";
    this.ConfirmationMessage = "Do you want to publish this Control Document?"

  }
  SaveControlDocument(data: any) {
    this.isLoading = true;
    this.controlDocumentsServices.create(data).subscribe(
      (response) => {
        this.isLoading = false;
        if (this.controlDocumentForm.controls["status"].value != 1) {
          this.createdId = response;
          this.openConfirmationPublishPopup();
        }
        else {
          this.notify.showSuccess("Created Successfully", "Control Documents");
          this.router.navigate(["/Documentation/ControlDocumentsList"]);
        }
      },
      (error: any) => {
        this.notify.showError(error?.message, "Control Documents");
        //Message
      }
    );
  }
  UpdateControlDocument(data: any) {
    this.isLoading = true;
    this.controlDocumentsServices.update(data).subscribe(
      (response) => {
        if (response) {
          this.isLoading = false;
          if (this.controlDocumentForm.controls["status"].value != 1) {
            this.createdId = response;
            this.openConfirmationPublishPopup();
          }
          else {
            this.notify.showSuccess("Update Successfully", "Control Documents");
            this.showLogChangePopup = false;
            this.router.navigate(["/Documentation/ControlDocumentsList"]);
          }
        }
      },
      (error: any) => {
        this.router.navigate(["/Documentation/ControlDocumentsList"]);
      }
    );
  }
  MoveBack() {
    this.router.navigate(["/Documentation/ControlDocumentsList"]);
  }




  descriptionChange($event) {
    if ($event.source != 'api') {
      this.descriptionHTMLFormate = $event.html;
      console.log(this.descriptionHTMLFormate)
      this.description = $event.text;
    }
  }

  LinkForLink = null;
  showDocumentControlHistoryModel(isShow: boolean) {
    this.historyDocumentationModel = isShow;
  }
  versionModelPopUpShow(isShow: boolean) {
    this.versionModelPopUp = isShow;
  }

  closeControlListPopUp(isShow: boolean) {
    this.showControlListPopUp = isShow;
  }

  CompareVersion() {
    let obj1 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdFirst)
        ?.newValue
    );
    let obj2 = JSON.parse(
      this.AllAuditTrailsList.find((e) => e.id == this.compareVersionIdSecond)
        ?.newValue
    );
    if (obj1 && obj2) {
      this.text1 = `${CommonFunction.removeTags(obj1.Description)}`;
      this.text2 = `${CommonFunction.removeTags(obj2.Description)}`;
      this.onCompare();
    }
  }


  ////////////////////////////////////////////////////////////////////////////////////////


  /////////////////////////////////////////////////////////////////////////////////////////////////

  text1 = "";
  text2 = "";
  isCompared = true;

  @Output()
  selectedLang = "plaintext";
  @Output()
  selectedTheme = "vs";

  @Input()
  languages = ["plaintext", "postiats"];

  @Input()
  themes = [
    {
      value: "vs",
      name: "Default",
    },
    {
      value: "vs-dark",
      name: "Dark",
    },
    {
      value: "hc-black",
      name: "High Dark",
    },
  ];

  // input
  inputOptions = { theme: "vs", language: "plaintext" };
  // compare, output
  diffOptions = {
    theme: "vs",
    language: "plaintext",
    readOnly: true,
    renderSideBySide: true,
  };
  originalModel: DiffEditorModel = {
    code: "",
    language: "plaintext",
  };

  modifiedModel: DiffEditorModel = {
    code: "",
    language: "plaintext",
  };

  onChangeLanguage(language) {
    this.inputOptions = {
      ... this.inputOptions,
      language: language,
    }
    this.originalModel = {
      ... this.originalModel,
      language: language,
    }
    this.modifiedModel = {
      ... this.modifiedModel,
      language: language,
    }
  }
  onChangeTheme(theme) {
    this.inputOptions = { ... this.inputOptions, theme: theme }
    this.diffOptions = { ... this.diffOptions, theme: theme }
  }

  onChangeInline(checked) {
    this.diffOptions = {
      ... this.diffOptions,
      renderSideBySide: !checked,
    }
  }

  onCompare() {
    this.originalModel = {
      ... this.originalModel,
      code: this.text1,
    }
    this.modifiedModel = {
      ... this.originalModel,
      code: this.text2,
    }
    this.isCompared = true;
    this.versionModelPopUpShow(true);
  }

  RemoveLinking(event) {
    let innerHtml = this.rteObj.getHtml();
    innerHtml = innerHtml.replace(this.selectedSpan.outerHTML, this.selectedSpan.innerHTML)
    this.rteObj.writeValue(innerHtml)
    setTimeout(() => {
      this.descriptionHTMLFormate = this.rteObj.getHtml();
    }, 2000);
  }
  public tools = {
    items: [
      'Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen',
      {
        tooltipText: 'Link Controls',
        template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%">'
          + '<div class="e-tbar-btn-text" style="font-weight: 500;">&#x263F;</div></button>'
      }// Add your custom button here
    ]
  };
  public customBtn: HTMLElement;

  public actionCompleteHandler(e: any): void {
    if (e.requestType === 'SourceCode') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.add('e-overlay');
    } else if (e.requestType === 'Preview') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.remove('e-overlay');
    }
  }

  // In your component initialization

  // Custom command handler
  customCommandHandler(): void {
    // Implement your custom button action here
  }
  @ViewChild('contextMenuObj') contextMenuObj: ContextMenuComponent;
  @ViewChild('contextMenuObjView') contextMenuObjView: ContextMenuComponent;
  dataContext = [
    {
      text: 'Link Control'
    },
    {
      text: 'Remove Control'
    }
  ];
  dataContextView = [
    {
      text: 'View Linked Control'
    },
    {
      text: 'Remove Control'
    }
  ];
  public height: number = 200;
  private selectionRange: Range | null = null;
  getSelectedHtml() {
    let selection = window.getSelection();
    if (selection.rangeCount) {
      let range = selection.getRangeAt(0);
      let div = document.createElement('div');
      div.appendChild(range.cloneContents());
      return div.innerHTML;
    }
    return '';
  }


  @ViewChild('rteObj') rteObj: RichTextEditorComponent;
  onRteRightClick(event: MouseEvent): void {
    const htmlContent = this.getSelectedHtml();

    if (htmlContent && htmlContent.length > 0) {
      if (this.rteObj.getRange()) {
        this.selectionRange = this.rteObj.getRange();
      }
      event.preventDefault();
      this.contextMenuObj.open(event.pageY, event.pageX);
    }
    else {
      this.contextMenuObj.close();
    }
  }
  itemSelect(args: MenuEventArgs) {
    if (args.item.text === 'Link Control') {
      this.showlinkComponent();
      this.contextMenuObj.close();
    }
    if (args.item.text === 'View Linked Control') {
      this.contextMenuObjView.close();
      this.ViewControl(this.LinkForLink)
    }
    if (args.item.text === 'Remove Control') {
      this.contextMenuObjView.close();
      this.RemoveLinking(args);
    }

  }
  onContextMenuSelect(event): void {
  }
  public onCreate(): void {
    this.customBtn = document.getElementById('custom_tbar') as HTMLElement;
    this.customBtn.onclick = (e: Event) => {
      const text = this.rteObj.getSelection();
      if (text && text.length > 0) {
        if (this.rteObj.getRange()) {
          this.selectionRange = this.rteObj.getRange();
          this.showlinkComponent();
        }
      }
      else {
        Swal.fire("Information", "Please select some text to link the controls", "info");
      }
    };
  }
  selectedSpan = null;
  createdescription(mouseEvent: any) {
    const linkEl = mouseEvent.target.closest('span');
    let ids = [];
    let currentElement = linkEl;
    while (currentElement) {
      if (currentElement?.id?.includes('Control-')) {
        ids.push(currentElement.id.replace('Control-', ''));
      }
      currentElement = currentElement.parentNode;
    }
    const idsString = 'Control-' + ids.join('Control-');
    if (linkEl?.id.includes('Control-')) {
      mouseEvent.preventDefault();
      this.onSpanClick(mouseEvent, idsString)
      this.selectedSpan = linkEl;
    }
    else {
      this.LinkForLink = null;
      this.selectedSpan = null;
    }
  }

}




