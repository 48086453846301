<div class="Pop-Up-Box pop-up-box">
    <div class="Pop-Up-Header">
        <span (click)="onClose(false)" class="material-icons">highlight_off</span>
        <h1>Change Log</h1>
    </div>
    <div class="container">
        <div class="row">
            <div class="main-card mb-3 card">
                <div class="card-body">
                    <h3>What have been changed in this version</h3>
                    <textarea class="changeLogTextArea" [(ngModel)]="_changeLog"></textarea>
                    <hr class="HR">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <h3 class="mt-2">Changes since last minor or major version</h3>
                            <button (click)="Save()" class="btn btn-success btn-atlas">Save</button>
                        </div>
                    </div>
                    <div class="row">
                        <!-- <div class="col-md-2"> {{log.status}}</div>
                        <div class="col-md-2"> {{log.version}}</div>
                        <div class="col-md-2"> {{log.createdDate}}</div>
                        <div class="col-md-6"> {{log.logChange}}</div> -->
                        <app-atlas-table [columns]="columns" [dataSource]="changeLogs" [customColumnWidths]="true"
                            [customPageSizeOptions]="customPageSizeOptions"></app-atlas-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>