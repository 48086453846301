import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { globalConstants } from "../../../constants/global-constants";
import { UserService } from "../../Services/user-services";
import { Column } from "../../Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import {FilterFunctionComponent} from "../../Shared/filter-function/filter-function.component"
@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
  providers: [
    HasPermissionPipe,
  ],})
export class UsersComponent implements OnInit {
  constructor(
    private userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal,
    private hasPermissionPipe: HasPermissionPipe
  ) { 
  }

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  userId: any;
  heading = "Users";
  isLoading = false;
  columns: Partial<Column>[] = [];
  subheading = "Users Inside System";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  customPageSizeOptions = globalConstants.customPageSizeOptions;
  route :any;

  initColumns() {
    this.columns = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
    },
    {
      label: "Email",
      elementAttribute: "email",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Name",
      elementAttribute: "fullName",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Role",
      elementAttribute: "roleNames",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];
}

  ngOnInit(): void {
    this.getAllUsers();
    this.route = this.activeRoute.snapshot;
    this.initColumns();
  }
  parentData: any = 'Data from parent';
  userDetails = null;
  openUserDetails = false;
  onOpenDetails(user){
    this.userDetails =user;
    this.openUserDetails = true;
  }
  handleChildEvent(data: any) {
    console.log('Data received from child:', data);
  }
  onClose(){
    this.openUserDetails =false;
    this.userDetails = null;
  }
  getAllUsers() {
    this.isLoading = true;
    this.userService.getAllUsers().subscribe((res) => {
      this.isLoading = false;
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate(["/Settings/create-user/" + event.id]);
  }

  onDelete(event) {
    this.userId = event.id;
    // (document.getElementById("btnDelete") as HTMLInputElement).click();
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.userService
          .delete({
            id: this.userId,
          })
          .subscribe((data) => {
            this.getAllUsers();
            // this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  // open(content) {
  //   this.modalService.open(content).result.then(
  //     (result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     }
  //   );
  // }

  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return "by pressing ESC";
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return "by clicking on a backdrop";
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }

  // delete() {
  //   this.userService
  //     .delete({
  //       id: this.userId,
  //     })
  //     .subscribe((data) => {
  //       this.getAllUsers();
  //       this.modalService.dismissAll();
  //     });
  // }
}
