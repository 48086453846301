import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPlus, faStar } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/Services/customer.service';
import { Column } from 'src/app/Shared/form-module/atlas-forms.component';
import { HasPermissionPipe } from 'src/app/Shared/pipes/HasPermissionPipe';
import { globalConstants } from 'src/constants/global-constants';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.sass'],
  providers:[HasPermissionPipe]
})
export class CustomerComponent implements OnInit {
  heading = "Customers";
  subheading = "Customers inside the system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";
  showDetailsPopup : boolean = false;
  isLoading: boolean = false;
  customerDetails : any;
  columns: Partial<Column>[] = [];
route: any;
  constructor(
    private customer : CustomerService,
    private router: Router,
    private modalService: NgbModal,
    private activeRoute: ActivatedRoute,
    private hasPermissionPipe: HasPermissionPipe
  ) {}

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  customerId: any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;


  initColumns() {
    this.columns = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canDelete'),
      elementAttribute: "positionDeleteIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canDelete')
    },
    {
      iconColumn: this.hasPermissionPipe.transform(this.route, 'canUpdate'),
      elementAttribute: "positionEditIcon",
      columnWidth: '2%',
      hidden: !this.hasPermissionPipe.transform(this.route, 'canUpdate')
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];
  }
  ngOnInit(): void {
    this.isLoading = true;
    this.getAll();
    this.route = this.activeRoute.snapshot;
    this.initColumns(); 
  }

  getAll() {
    this.customer.getAll().subscribe((res) => {
      this.data = res;
      this.isLoading = false;
    },
    error => {  this.isLoading = false;}
    );
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Audit/Create-or-edit-customer/" + event.id,
    ]);
  }

  onDelete(event) {
    this.customerId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.customer
          .delete(this.customerId)
          .subscribe((data) => {
            this.getAll();
            this.modalService.dismissAll();
          });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  OpenCustomerDetails(event)
  {
    this.isLoading = true;
    this.customer.get(event.id).subscribe(res =>{
      this.customerDetails = res;
      this.showDetailsPopup = true;
      this.isLoading = false;
    },
    error => {  this.isLoading = false;})
  }

  onClose(show)
  {
    this.showDetailsPopup = show;
  }
}
