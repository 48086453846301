import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "src/app/Services/notification.service";
import { UserService } from "src/app/Services/user-services";
import { CurrentTenantService } from "src/app/Services/admin-currentTenant";
import { SuperAdminServices } from "src/app/Services/super-admin-services";

@Component({
  selector: 'app-tenant-user-create',
  templateUrl: './tenant-user-create.component.html',
  styleUrls: ['./tenant-user-create.component.scss']
})

export class TenantUserCreateComponent implements OnInit {
  userId: number;
  userForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  DOB?: string = null;
  password: string;
  cpassword: string;
  hasSpecialCharacter: boolean = false;
  hasUpperCase: boolean = false;
  hasLowerCase: boolean = false;
  hasNumeric: boolean = false;
  displayPasswordValidation: boolean = false;
  roles: any[];
  checkPassword: boolean = false;
  duplicateUser: boolean = false;
  userName: string;
  isLoading = false;
  isSubmitting = false;
  isEditForm = false;
  currentTenant: string;
  resetPasswordForm: boolean = false;
  newPassword: string;
  showRecoverUserButton: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private notify: NotificationService,
    private locationService: Location,
    private currentTenantService: CurrentTenantService,
    private superAdminService: SuperAdminServices
  ) {
    this.route.params.subscribe(params => {
      this.userId = params['id'];
      this.currentTenant = this.route.snapshot.queryParams['tenantId'];
      if (this.userId) {
        this.isEditForm = true;
      }
      this.userForm = this.formBuilder.group({
        email: [{ value: "", disabled: this.isEditForm }, [Validators.required], [this.emailValidator]],
        firstName: ["", [Validators.required]],
        lastName: ["", [Validators.required]],
        roleIds: ["", [Validators.required]],
      });
      this.passwordForm = this.formBuilder.group({
        password: ["", [Validators.required]],
        confirmPassword: ["", [Validators.required]]
      });
      this.fetchRoles();
      if (this.userId) {
        this.isLoading = true;
        this.superAdminService.getUser(this.userId, this.currentTenant).subscribe((data) => {
          this.userForm.patchValue({
            email: data.email,
            name: data.name,
            roleIds: data.roleIds.map(String),
            firstName: data.firstName,
            lastName: data.lastName
          });
          if (!data.isActive) {
            this.showRecoverUserButton = true;
          }
          this.isLoading = false;

        });
      }
      else {
        this.userForm.get('firstName').clearValidators();
        this.userForm.get('firstName').updateValueAndValidity();
        this.userForm.get('lastName').clearValidators();
        this.userForm.get('lastName').updateValueAndValidity();
      }
    });
  }

  ngOnInit(): void {
    this.duplicateUserPopUp(false);
  }
  MoveBack() {
    this.locationService.back();
  }

  ResetPassword() {
    this.resetPasswordForm = true;
  }
  RecoverUser() {
    this.isSubmitting = true;
    this.isLoading = true;
    this.superAdminService.recoverUser(this.userId.toString(), this.currentTenant).subscribe({
      next: () => {
        this.router.navigate(["/Tenants"]);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        this.notify.showInfo("User Account Recovered succesfully", "User Recovered");
        this.isSubmitting = true;
        this.isLoading = true;
      },
    });
  }
  resetPasswordSubmit() {
    if (this.checkPassword) {
      return;
    }

    this.superAdminService.resetPassword(this.userId.toString(), this.passwordForm.get("password").value).subscribe({
      next: () => {
        this.router.navigate(["/Tenants"]);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
      },
    });
  }
  createUser() {
    if (!this.userForm.invalid) {
      this.isSubmitting = true;
      this.isLoading = true;
      if (this.userId) {
        const selectedRoleIds = this.userForm.get('roleIds').value; 
        const selectedRoleNames = selectedRoleIds
                                  .map(id => this.roles.find(role => role.id === id)?.name)
                                  .filter(name => !!name); 
        this.superAdminService
          .update({
            email: this.userForm.get("email").value,
            id: this.userId,
            password: "",
            confirmPassword: "",
            firstName: this.userForm.get("firstName").value,
            lastName: this.userForm.get("lastName").value,
            roleNames: selectedRoleNames,
            roleIds: this.userForm.get("roleIds").value,
            tenantId: this.currentTenant
          })
          .subscribe((data) => {
            this.notify.showInfo("Updated Successfully", "User");
            this.locationService.back();
          },
            (error) => {
              this.isSubmitting = false;
              this.isLoading = false;
              this.notify.showError("Error while updating", "User");
            });
      } else {
        this.superAdminService
          .createTenantUser({
            email: this.userForm.get("email").value,
            roleIds: this.userForm.get("roleIds").value,
            tenantId: this.currentTenant
          })
          .subscribe(
            (data) => {
              this.isLoading = false;
              this.notify.showInfo("Invitation sent Successfully", "User");
              this.locationService.back();
            },
            (error) => {
              this.isLoading = false;
              this.isSubmitting = false;
              this.notify.showError("Error while sending Invitation", "User");
            }
          );
      }
    }
  }
  setvalidation() {
    if (
      this.userForm.get("password").value ||
      this.userForm.get("confirmPassword").value
    ) {
      this.userForm.get("password").setValidators(Validators.required);
      this.userForm.get("confirmPassword").setValidators(Validators.required);
    }
    if (
      this.userForm.get("password").value ===
      this.userForm.get("confirmPassword").value
    ) {
      this.checkPassword = false;
    }

    if (
      this.userForm.get("password").value === null ||
      (this.userForm.get("password").value === "" &&
        (this.userForm.get("confirmPassword").value == null ||
          this.userForm.get("confirmPassword").value == ""))
    ) {
      this.userForm.get("password").clearValidators();
      this.userForm.get("confirmPassword").clearValidators();
      this.checkPassword = false;
    }
  }

  CheckValidation(event) {
    this.displayPasswordValidation = false;
    this.password = event.target.value;
    this.cpassword = this.userForm.get("confirmPassword").value;
    let pattern = / "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"/
    if (
      this.userForm.get("password").value === "" &&
      this.userForm.get("confirmPassword").value == ""
    ) {
      this.userForm.get("password").clearValidators();
      this.userForm.get("confirmPassword").clearValidators();
      this.checkPassword = false;
    }
    if (this.password.length == 0) {
      return;
    } else if (this.password.length < 14) {
      this.checkPassword = true;

      return this.notify.showError(
        "",
        "Password length must be greater then 14 characters"
      );
    } else if (pattern.test(this.password)) {
      this.checkPassword = false;
    } else {
      this.checkPassword = true;
      return this.notify.showError(
        "",
        "Password Does't Meet The Requriments"
      );
    }


  }

  ComparePassword(event) {
    this.cpassword = event.target.value;
    if (this.password !== this.cpassword) {
      this.checkPassword = true;
      return this.notify.showError(
        "",
        "Comfirm Password Does't Match With Password"
      );
    } else {
      this.checkPassword = false;
    }
  }

  showValidation() {
    this.displayPasswordValidation = true;
  }

  fetchRoles() {

    this.superAdminService.getAllRoles(this.currentTenant).subscribe((data) => {
      this.roles = data;
    },
      error => console.log(error));
  }
  duplicateUserPopUp(isShow: boolean) {
    this.duplicateUser = isShow;
  }
  linkUserTenant() {
    this.superAdminService.LinkToTenant(this.userName, this.currentTenant).subscribe({
      next: () => {
        this.router.navigate(["/Tenants"]);
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log("Linking user to tenant completed");
      },
    });
  }

  emailValidator(control: AbstractControl): Promise<any> | null {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(control.value);
    if (control.value && !emailRegex.test(control.value)) {
      return Promise.resolve({ invalidEmail: true });
    }

    return Promise.resolve(null);
  }

  preventSpace(event: KeyboardEvent) {
    const invalidCharsPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (event.code === 'Space' || invalidCharsPattern.test(event.key)) {
      event.preventDefault();
    }
  }


}
