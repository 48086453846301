
<div  class="d-flex">
  <div>
    <ng-container *ngIf=" chapterControlsShow==false ">
      <label>Select Document</label>
      <select id="docu" [formControl]="docu" class="form-control mb-4" (change)="changeDocument($event)">
        <option *ngFor="let doc of documents" value="{{doc.id}}">{{doc.name}}</option>
      </select>
    </ng-container>
  </div>
  <span class="spacer"></span>
  <span (click)="showfilterfun()" class="filterbutton">
    <mat-icon>filter_alt</mat-icon>
  </span>
  <span (click)="refresh()" class="filterbutton">
    <mat-icon>refresh</mat-icon>
  </span>
  <span class="ms-1">
    <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn btn-success btn-atlas" (click)="Navigate()">
      <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
      Create New
    </button>
  </span>
 
</div>
<div class="mt-3">

  

</div>


<ng-container>
  <app-atlas-table [columns]="columns" [dataSource]="controls" [customColumnWidths]="true"
    [haveCommonIcons]="true" [hasEditIcon]="true && (route | hasPermission:'canUpdate')" [hasDeleteIcon]="true && (route | hasPermission:'canDelete')" [onDelete]="onDelete.bind(this)" 
    [onEdit]="getEditUrl.bind(this)" [onOpen]="OpenControlDetails.bind(this)"
    [hasServerPaging]="true" [hasPaginator]="false" [totalItems]="totalItems"
    [hasPreviewIcon]="true"
    [customPageSizeOptions]="customPageSizeOptions" [pageChange]="pageChangeHandler.bind(this)">

  </app-atlas-table>
</ng-container>

<div class=" col-md-8">
  <app-app-control-details class="controlDetails" *ngIf="openDetails" [event]="controlDetails"
    [onClose]="onClose.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 10000001; border: 1px solid gray;
    border-radius: 5px;">
  </app-app-control-details>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 100000;">
  <mat-spinner></mat-spinner>
</div>


<!-- component for controls filter path -> Shared/control-filter -->
<app-controls-filter *ngIf="showfilter" [onClose]="onClose.bind(this)" (addedfileters)="applyFilter($event)">
</app-controls-filter>
<!-- <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                        >
  
  
  
    </app-atlas-table>
  </div> -->

<!-- <ng-template #content5 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title"> Are you sure you want to remove the CIS Asset Type</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')">
        </button>
    </div>
    <button type="button" class="btn btn-secondary" (click)="c('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="delete()">Yes</button>
  </ng-template>
  <button id="btnDelete" style="display: none;" type="hidden" class="btn btn-primary"  (click)="open(content5)">Launch demo modal</button>
   -->