import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

const basePath = environment.apiUrl + "Events";

@Injectable({
  providedIn: "root",
})
export class LoginServices {
  eventsBasePath = environment.apiUrl + "Account";

  constructor(private http: HttpClient) { }


  Login(subId, refreshToken): any {
    console.log(this.eventsBasePath);
    return this.http.post<any>(this.eventsBasePath + "/Login", {
      subId: subId,
      RefreshToken: refreshToken
    });
  }



  attachFile(file: any, eventId: number, caseId: number): any {
    let route = basePath + `/Documents`;
    if (eventId) {
      route += `/${eventId}`;
    }
    if (caseId) {
      route += `?caseId=${caseId}`;
    }

    const formData = new FormData();
    formData.append("File", file);

    return this.http.post(route, formData, {
      reportProgress: true,
      observe: "events",
    });
  }
}
