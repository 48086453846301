<div class="row">
   <div class="col-md-12">
      <app-audit-start-detail [AuditId]="AuditId" [Progress]="auditProgress"></app-audit-start-detail>
   </div>
   <!-- <div class="col-md-12" *ngIf="auditProgress">
      <mat-progress-bar mode="determinate" [value]="auditProgress"></mat-progress-bar>
      <span class="progress-bar-percent">{{auditProgress}}%</span>
   </div> -->
</div>
<div class="row mt-2">
   <div class="col-md-12">
      <div class="mt-1" style="cursor: pointer;float:right">
         <button (click)="downloadAuditToPDF()" class="auditButtons">
            <mat-icon>download</mat-icon>
         </button>
         <span>
         </span>
         <button (click)="saveAuditToPDF()" class="auditButtons">
            <mat-icon>print</mat-icon>
         </button>
         <button (click)="refresh()" class="auditButtons">
            <mat-icon>refresh</mat-icon>
         </button>
         <button class="me-1" (click)="showFilters()" class="auditButtons">
            <mat-icon>filter_alt</mat-icon>
         </button>
         <button (click)="showSortingDialog()" class="auditButtons">
            <mat-icon>sort</mat-icon>
         </button>
      </div>
   </div>
</div>
<div class="row">
   <div class="col-md-12" *ngIf="!isloading">
      <app-auditcontrolslist [AuditId]="AuditId" [openConctrolDetails]="OpenControlDetails.bind(this)"
         (addQuestion)="showAddQuestionPopUpModel($event)" (auditQuestionAnswers)="CreateAuditQuestionAnswer($event)"
         [AuditingId]="auditingId" [dataSource]="controlsData"></app-auditcontrolslist>
   </div>
</div>

<app-add-question-from-audit (questionCreated)="isQuestionCreated($event)" (questionEdited)="isQuestionEdited($event)"
   [auditId]="AuditId" (cancelPressed)="onClose($event)" *ngIf="showAddQuestionPopUp"
   [AddQuestionData]="AddQuestionPredefinedData" [onClose]="onClose.bind(this)"
   style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;"></app-add-question-from-audit>

<app-controls-filter *ngIf="showfilter" [onClose]="onClose.bind(this)" (addedfileters)="applyFilter($event)"
   [auditId]="AuditId" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-controls-filter>

<app-question-control-details class="controlDetails" *ngIf="openDetails" [event]="controlDetails"
   [onClose]="onClose.bind(this)" style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;">
</app-question-control-details>

<app-sorting-pop-up *ngIf="showSortingPopUp" (sortingData)="applySorting($event)" [onClose]="onClose.bind(this)"
   style="position: fixed;top: 15%;width: 50%;left: 35%;z-index: 1000000;"></app-sorting-pop-up>


<hr>
<div class="d-flex">
   <div>
      <button class="btn btn-atlas-cancel btn-audit-reset mb-2" (click)="CloseAuditConfermation(auditingId)" *ngIf="!auditingStatusBtn">Close
         Audit</button>
      <button class="btn btn-atlas-cancel btn-audit-reset mb-2" (click)="ReopenAudit(auditingId)" *ngIf="auditingStatusBtn">Reopen
         Audit</button>
   </div>
   <span class="spacer"></span>
   <div><button class="btn btn-atlas btn-saveall-Apply mb-2" (click)="SaveAuditAll()">Save All</button></div>
</div>