import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CustomerService } from "src/app/Services/customer.service";
import { DocumentService } from "src/app/Services/document.service";
import { DatePipe, Location } from "@angular/common";
import {
  hasInvalidControl,
  markFormGroupTouched,
} from "src/app/Helpers/FormHelper";
import { AuditService } from "src/app/Services/audit-services";
import { ActivatedRoute, Router } from "@angular/router";
import { AuditStatus } from "src/app/Common/Enums/Atlas-Enums";

@Component({
  selector: "app-customer",
  templateUrl: "./create-or-edit-audit.component.html",
  styleUrls: ["./create-or-edit-audit.component.sass"],
})
export class CreateAuditComponent implements OnInit {
  auditsForm: UntypedFormGroup;
  customers: string[] = [];
  documentsList: string[] = [];
  documentsListId: string[] = [];
  startDate: any;
  endDate: any;
  name: string;
  customerId: any;
  AuditId = null;
  isLoading = false;
  isReadOnly : boolean = false;
  auditStatus: any =[AuditStatus.New,AuditStatus.Ongoing,AuditStatus.Closed,AuditStatus.Reopened];
  auditStatusValues :any = AuditStatus

  constructor(
    private customerService: CustomerService,
    private documentService: DocumentService,
    private locationService: Location,
    private formBuilder: UntypedFormBuilder,
    private auditServices: AuditService,
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    this.auditsForm = this.formBuilder.group({
      customerId: ["", [Validators.required]],
      name: ["", [Validators.required]],
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      status:[AuditStatus.New,Validators.required]
    });

  } 


  ngOnInit(): void {
   
    console.log(this.auditStatus)
    this.activatedRouter.params.subscribe((data) => {
      if (data.Id) {
        this.AuditId = data.Id;
        this.auditServices.getAuditEditDocumentDetails(data.Id).subscribe(res => {
            this.isReadOnly = res;
        });
        this.auditServices.get(data.Id).subscribe((data) => {
          this.documentsListId = data.documentId;
          this.auditsForm.patchValue({
            name: data.name,
            customerId: data.customerId,
            startDate: data.startDate ? new Date(data.startDate) : "",
            endDate: data.endDate ? new Date(data.endDate) : "",
            status : data.status
          });
        });
      }
    });
    this.documentService.getList().subscribe((result) => {
      this.documentsList = result;
    });
    this.customerService.getAll().subscribe((result) => {
      this.customers = result;
    });
  }
  createAudits() {
    if (this.auditsForm.invalid || hasInvalidControl(this.auditsForm)) {
      markFormGroupTouched(this.auditsForm);
     
    } else {
      this.isLoading = true;
      if (this.AuditId) {
        let startDate =
          this.auditsForm.get("startDate").value != ""
            ? new Date(this.auditsForm.get("startDate").value).toISOString()
            : null;
        let endDate =
          this.auditsForm.get("endDate").value != ""
            ? new Date(
                this.datePipe.transform(
                  new Date(this.auditsForm.get("endDate").value),
                  "MM/dd/yyyy"
                )
              ).toISOString()
            : null;

            console.log(this.auditsForm.get("status").value)

        this.auditServices
          .update({
            customerId: this.auditsForm.get("customerId").value,
            auditName: this.auditsForm.get("name").value,
            status: this.auditsForm.get("status").value,
            documentId: this.documentsListId,
            startDate: startDate,
            endDate: endDate,
            id: this.AuditId,
          })
          .subscribe(
            (data) => {
              this.isLoading = false;
              this.router.navigate(["/Audit/Audits"]);
            },
            (error) => {
              this.isLoading = false;
            }
          );
      } else {
        this.auditServices
          .create({
            customerId: this.auditsForm.get("customerId").value,
            auditName: this.auditsForm.get("name").value,
            documentId: this.documentsListId,
            startDate: this.auditsForm.get("startDate").value,
            endDate: this.auditsForm.get("endDate").value,
          })
          .subscribe(
            (data) => {
              this.isLoading = false;
              this.router.navigate(["/Audit/Audits"]);
            },
            (error) => {
              this.isLoading = false;
            }
          );
      }
    }
  }
  selectEndDate(event) {
    this.endDate = event;
  }
  public selectStartDate(event) {
    this.startDate = event;
  }
  MoveBack() {
    this.locationService.back();
  }
}
