import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlagService {
  private flagSource = new BehaviorSubject<boolean>(false);
  flag$ = this.flagSource.asObservable();


  private isSuperAdminFlag: boolean = false;

  getSuperAdminFlag(): boolean {
    return this.isSuperAdminFlag;
  }
  setFlag(value: boolean) {
    this.flagSource.next(value);
  }
  setSuperAdminFlag(value: boolean): void {
    this.isSuperAdminFlag = value;
  }
}
