import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class UserSettingsService {
  constructor(private http: HttpClient) { }
  userbasePath = environment.apiUrl + "UserSession";

  getUserSettings(): any {
    return this.http.get<any>(this.userbasePath + "/Get");
  }

  create(body): any {
    return this.http.post<any>(this.userbasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.userbasePath + "/Update", body);
  }

}
