<p *ngFor="let question of Questions" class="QuestionDiv">

    <b> {{question.question}}</b>
    <span>
        <!-- <button mat-icon-button matTooltip="Edit" (click)="onEdit(question)" data-automation="edit-button">
            <mat-icon>edit</mat-icon>
        </button> -->
        <mat-icon style="cursor: pointer;" (click)="onEdit(question)">edit</mat-icon>
        <mat-icon style="cursor: pointer;" class="delete" (click)="onDelete(question)">delete</mat-icon>
    </span>
    <br>
    <b [innerHTML]="question.description"> </b>
</p>