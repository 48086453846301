import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient) { }
  userbasePath = environment.apiUrl + "Account";
  basePath = environment.apiUrl + 'Users/';
  connectionBasePath = this.basePath + "ConnectUser";
  getAllUsers(): any {
    return this.http.get<any>(this.userbasePath + "/All");
  }

  getUser(id): any {
    return this.http.get<any>(this.userbasePath + "/Get/" + id);
  }

  getUserFirstAndLastName(id): any {
    return this.http.get<any>(this.userbasePath + "/GetUserFirstAndLastName/" + id);
  }

  getUserId(): any {
    return this.http.get<any>(this.userbasePath + "/GetUserId");
  }
  getToken(): string {
    return localStorage.getItem("token")
  }
  create(body): any {
    return this.http.post<any>(this.userbasePath + "/Register", body);
  }
  CheckSession(): any {
    return this.http.post<any>(this.userbasePath + "/CheckSession", {});
  }
  //-----//
  sendMailInvitation(body): any {
    return this.http.post<any>(this.userbasePath + "/SendInvitation", body);
  }
  //-----//
  update(body): any {
    return this.http.post<any>(this.userbasePath + "/Update", body);
  }

  UpdateUserFirstAndLastName(body): any {
    return this.http.post<any>(this.userbasePath + "/UpdateUserFirstAndLastName", body);
  }

  delete(body): any {
    return this.http.post<any>(this.userbasePath + "/Delete", body);
  }
  resetPassword(body): any {
    return this.http.post<any>(this.userbasePath + "/ResetPassword", body);
  }
  LinkToTenant(name: string): any {
    const body = {
      email: name,
    };
    return this.http.post<any>(this.userbasePath + "/LinkToTenant", body);
  }
  Logout(): any {
    return this.http.post<any>(this.userbasePath + "/Logout", {});
  }
  ConnectUser(data): Observable<any> {
    return this.http.post<any>(this.userbasePath + "/ConnectUser", data);
  }
}
