import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationTimeZone } from "../Models/application-timezone";

@Injectable({
    providedIn: "root",
})
export class UserServices {
    constructor(private http: HttpClient, private router: Router) {
    }
    getTimeZone(): ApplicationTimeZone {
        return JSON.parse(localStorage.getItem("timeZone"));
    }
}
