import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
// DomSanitizationService
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { ThemeOptions } from "src/app/theme-options";
import { ConfigActions } from "src/app/ThemeOptions/store/config.actions";





@Component({
  selector: "app-app-control-details",
  templateUrl: "./app-control-details.component.html",
  styleUrls: ["./app-control-details.component.scss"],
})

export class AppControlDetailsComponent implements OnInit {
  @select('config') public config$: Observable<any>;

  constructor(
    private router: Router,
    protected _sanitizer: DomSanitizer,
    private elemRef: ElementRef,
    public globals: ThemeOptions,
    public configActions: ConfigActions
  ) { }
  description: SafeHtml;
  auditingGuideLines: SafeHtml;
  objective: SafeHtml;
  otherInformation: SafeHtml;
  hasPopupClass: boolean = false;
  addMargin: boolean = false;



  ngOnInit(): void {
    this.description = this._sanitizer.bypassSecurityTrustHtml(
      this.event.description
    );
    this.objective = this._sanitizer.bypassSecurityTrustHtml(
      this.event.objective
    );
    this.otherInformation = this._sanitizer.bypassSecurityTrustHtml(
      this.event.otherInformation
    );
    this.auditingGuideLines = this._sanitizer.bypassSecurityTrustHtml(
      this.event.auditingGuideLines
    );
    if (window.innerWidth < 939 && window.innerWidth >= 768) {
      this.addMargin = true
    }
    else {
      this.addMargin = false;
    }
  }
  @HostListener("document:click", ["$event"])
  documentClick(event) {
    this.hasPopupClass = false;

    if (event.target.parentElement.className == "controlDetails ng-star-inserted") {
      this.hasPopupClass = true;
    } else {
      this.getParentElByClassName(event.target)
    }

    if (!this.hasPopupClass) {
      this.onClose(true);
    }
  }

  getParentElByClassName(target) {
    let actualNode = target.parentElement;
    while (actualNode !== null) {
      if (actualNode == null)
        break;

      if (actualNode.className == "Pop-Up-Box pop-up-box") {

        this.hasPopupClass = true
        break;
      }
      actualNode = actualNode.parentElement;

    }
    return actualNode;;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth < 939 && window.innerWidth >= 768) {
      this.addMargin = true
    }
    else {
      this.addMargin = false;
    }
  }

  @Input()
  onClose: (closeboth) => {};

  @Input()
  selectedEvent: any = {};

  @Input()
  event: any = {} as any;

  getEditUrl(event: any) {
    this.router.navigate(["/Dashboard/Setup/createControl/" + this.event.id]);
  }
}
