<div class="row">
    <div class="col-md-12">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <form [formGroup]="documentForm" (ngSubmit)="createDocument()">
                    <h5 class="card-title">Knowledge Database</h5>
                    <div class="position-relative mb-3 col-md-6">
                        <label for="name" class="form-label">Name</label>
                        <input name="name" formControlName="name" id="name" placeholder="Name" type="email"
                            class="form-control">
                        <label for="name" class="form-label mt-1 text-danger"
                            *ngIf="this.documentForm.get('name').value==''">Required</label>

                    </div>
                    <div class="position-relative mb-3 col-md-6">
                        <mat-form-field appearance="fill" style="width: 100%;">
                            <mat-label>Categories</mat-label>
                            <mat-select multiple [(value)]="cats">
                                <mat-option *ngFor="let docTyoe of categories" [value]="docTyoe.id">
                                    {{docTyoe.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <p class="btn btn-primary btn-atlas" (click)="onOpenAdd()">
                            <mat-icon style="vertical-align:middle;font: size 19px;">add</mat-icon>
                            Add More Categories
                        </p>

                    </div>
                        <div class="col-md-12">
                            <div class="position-relative mb-3 ">
                                <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
                                    [onChange]="descriptionChange.bind(this)" [placeholder]="'lblDescription'"
                                    [descriptionHTMLFormate]="description">
                                </app-atlas-html-editor>
                            </div>
                        </div>
                
                    <div class="position-relative mb-3 col-md-12">
                        <app-atlas-files-list [filesList]="uploadedFiles" [visible]="databaseId ? true : false"
                            [visibleView]="databaseId ? true : false" [visibleDownload]="databaseId ? true : false"
                            [visibleLink]="false" [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
                            [isDeletable]="isDeletable.bind(this)" [formGroupData]="documentForm">
                        </app-atlas-files-list>
                        <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"></app-atlas-file-upload>
                    </div>
                    <div class="col-md-12">
                        <button class="mt-1 btn btn-success btn-atlas">Submit</button>
                        <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
    <mat-spinner></mat-spinner>
</div>
<div class="Pop-Up-Box pop-up-box" *ngIf="addCat">
    <div class="Pop-Up-Header">
        <span (click)="onCloseAdd(false)" class="material-icons">highlight_off</span>
        <h1>Add Category</h1>
    </div>

    <hr class="HR">
    <input name="name" [(ngModel)]="CatName" id="name" placeholder="Name" type="email" class="form-control">


    <p class="mt-2">
        <button class="btn btn-success btn-atlas" type="button" [disabled]="CatName==''" (click)=" SaveCat()">Save</button>
    </p>
</div>