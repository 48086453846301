<div class="row">
    <div class="position-relative mb-3 col-md-12">
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>Sort By:</mat-label>
        <mat-select [(ngModel)]="sortByOptions" name="scfdomainids" multiple style="background-color: none;">
          <mat-option  value="document">Document
          </mat-option>
          <mat-option  value="chapter">Chapter
        </mat-option>
        <mat-option  value="control">Control
        </mat-option>
        <mat-option  value="scfdomain">SCF Domain
        </mat-option>
        <mat-option  value="suggestedcontrol">Suggested Control
        </mat-option>
        <mat-option  value="sco">Suggested Control Ownership
        </mat-option>
        <mat-option  value="cisassettype">Cis Asset Type
        </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</div>