<div class="Pop-Up-Box pop-up-box">
    <div class="Pop-Up-Header">
        <span (click)="onClose(false)" class="material-icons">highlight_off</span>
        <h1>Apply Sorting</h1>

    </div>

    <hr class="HR">

    <div class="container">
        <div class="row">
            <div class="main-card mb-3 card">
                <div class="card-body">

                    <section>
                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.chapters">
                                Chapters
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.controlTypes">
                                Control Types
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary"  [(ngModel)]="sortingModel.controlFunctions">
                                Control Functions
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.cisAssetTypes">
                                CIS Assets
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.cisLevels">
                                CIS Levels
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.primaryObjectives">
                                Primary Objectives
                            </mat-checkbox>
                        </div>

                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.suggestedControls">
                                Suggested Controls
                            </mat-checkbox>
                        </div>
                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.scfDomains">
                                SCF Domains
                            </mat-checkbox>
                        </div>
                        <div class="col-md-4">
                            <mat-checkbox color="primary" [(ngModel)]="sortingModel.sCOs">
                                Suggested Controls Ownership
                            </mat-checkbox>
                        </div>


                    </section>

                </div>
            </div>
            <div class="col-md-12">
                <button (click)="applySorting()">
                    Apply
                </button>
                <button (click)="ResetSortingOptions()">Reset</button>
            </div>
        </div>
    </div>
</div>