import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faPlus, faStar } from "@fortawesome/free-solid-svg-icons";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ScfDomainsService } from "src/app/Services/scfDomains.service";
import { Column } from "src/app/Shared/form-module/atlas-forms.component";
import { HasPermissionPipe } from "src/app/Shared/pipes/HasPermissionPipe";
import { globalConstants } from "src/constants/global-constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-scf-domains",
  templateUrl: "./scf-domains.component.html",
  providers: [
    HasPermissionPipe,
  ],
 
})
export class ScfDomainsComponent implements OnInit {
  heading = "SCF Domains";
  subheading = "SCF Domains that you will be adding inside system";
  icon = "pe-7s-document icon-gradient bg-premium-dark";

  constructor(
    private scfDomainsService: ScfDomainsService,
    private router: Router,
    private modalService: NgbModal,
    private hasPermissionPipe: HasPermissionPipe,

  ) {}

  data = [];
  closeResult: string;
  faStar = faStar;
  faPlus = faPlus;
  scfDomainId: any;
  route :any;
  customPageSizeOptions = globalConstants.customPageSizeOptions;

  columns: Partial<Column>[] = [
    {
      iconColumn: true,
      elementAttribute: "positionOpenIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionDeleteIcon",
      columnWidth: "2%",
    },
    {
      iconColumn: true,
      elementAttribute: "positionEditIcon",
      columnWidth: "2%",
    },
    {
      label: "Name",
      elementAttribute: "name",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "SCF Identifier",
      elementAttribute: "scfIdentifier",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "Principal Intent",
      elementAttribute: "principalIntent",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
    {
      label: "SPD Principal",
      elementAttribute: "spdPrincipal",
      columnClass: "matters-TypeWidth",
      isLink: false,
      columnWidth: "200px",
    },
  ];

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.scfDomainsService.getAll().subscribe((res) => {
      this.data = res;
    });
  }

  getEditUrl(event: any) {
    this.router.navigate([
      "/Dashboard/Setup/createorEditScfDomains/" + event.id,
    ]);
  }

  onDelete(event) {
    this.scfDomainId = event.id;
    Swal.fire({
      title: "Are you sure want to remove?",
      text: "You will not be able to recover this file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.value) {
        this.scfDomainsService.delete(this.scfDomainId).subscribe((data) => {
          this.getAll();
          this.modalService.dismissAll();
        });
        Swal.fire("Deleted!", "Selected record has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your record is safe :)", "error");
      }
    });
  }

  open(content) {
    this.modalService.open(content).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  delete() {
    this.scfDomainsService.delete(this.scfDomainId).subscribe((data) => {
      this.getAll();
      this.modalService.dismissAll();
    });
  }
}
