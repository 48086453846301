import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ChapterService {
  constructor(private http: HttpClient) {}
  chapterbasePath = environment.apiUrl + "Chapter";
  getAll(documentId): any {
    return this.http.get<any>(this.chapterbasePath + "/All/" + documentId);
  }

  get(id): any {
    return this.http.get<any>(this.chapterbasePath + "/Get/" + id);
  }

  create(body): any {
    return this.http.post<any>(this.chapterbasePath + "/Create", body);
  }

  update(body): any {
    return this.http.post<any>(this.chapterbasePath + "/Update", body);
  }

  delete(id): any {
    return this.http.get<any>(this.chapterbasePath + "/Delete/" + id);
  }

  getList(id) {
    return this.http.get<any>(this.chapterbasePath + "/GetList/" + id);
  }

  getLeafChilds(id: string) {
    return this.http.get<any>(this.chapterbasePath + "/GetLeafChilds/" + id);
  }

  getChaptersForMultipleDocments(docIds : any)
  {
    return this.http.post<any>(this.chapterbasePath + "/GetCheptersForMultipleDocuments", docIds);
  }
}
