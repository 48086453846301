import { Location } from "@angular/common";
import { Component } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MenuService } from "src/app/Services/menu-services";
import { NotificationService } from "src/app/Services/notification.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
@Component({
  selector: "app-create-menu",
  templateUrl: "./create-menu.component.html",
  styleUrls: ["./create-menu.component.scss"],
})
export class CreateMenuComponent  {
  MenuForm: UntypedFormGroup;
  menus: any[];
  chiledMenus: any[];
  isLoading = false;
  submitForm = false;
  selectedMenu = [];
  parentMenuName: string = "";
  parentMenuIcon: string = "";
  parentMenuId: string = "";
  // It use For delete
  selectedChalidMenuId: string = "";
  addCat: boolean;
  isDeleteParentMenu: boolean =false;
  deleteChildConfirmation: boolean =false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private menuService: MenuService,
    private notify: NotificationService,
    private locationService: Location
  ) {
    this.MenuForm = this.formBuilder.group({
      menuId: [""],
      parentMenuId: ["", [Validators.required]],
      name: ["", [Validators.required]],
      route: ["", [Validators.required]],
      icon: [""],
    });
    this.isLoading = true;
    this.fetchParentMenu();
    
  }

 
  MoveBack() {
    this.locationService.back();
  }

  dropMenu(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.chiledMenus, event.previousIndex, event.currentIndex);
  }
  fetchParentMenu() {
    this.isLoading = true;
    this.menuService.getParentMenu().subscribe((data) => {
      this.isLoading = false;
      this.menus = data;
    });
  }


  ParentMenuChange(event) {
    this.isLoading = true;
    this.menuService.getMenuByParentId(event.value).subscribe((data) => {
      this.isLoading = false;
      if (data) {
        this.chiledMenus = data;
        this.resetChildMenuForm();
      }
    });
  }
  onOpenAdd() {
    this.addCat = true;
  }
  onCloseAdd() {
    this.parentMenuName = "";
    this.parentMenuIcon = "";
    this.parentMenuId = "";
    this.addCat = false;
  }
  onCloseDeleteMenuModel()
  {
    this.selectedChalidMenuId = "";
    this.deleteChildConfirmation = false;
  }
  onOpenDeleteMenuModel(id:string,isDeleteParentMenu:boolean = false)
  {
    this.selectedChalidMenuId = id;
    this.deleteChildConfirmation = true;
    this.isDeleteParentMenu = isDeleteParentMenu;
  }
 
  submitChildMenu() {
    let menuId = this.MenuForm.get("menuId").value;
    if (menuId && menuId != "") {
      this.UpdateMenu();
    }
    else {
      this.AddMenu();
    }
  }
  AddMenu() {
    this.submitForm = true;
    if (this.MenuForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.menuService
      .createMenu({
        parentID: this.MenuForm.get("parentMenuId").value,
        name: this.MenuForm.get("name").value,
        route: this.MenuForm.get("route").value,
        isSideMenuItem: true,
        icon: this.MenuForm.get("icon").value,
      })
      .subscribe(
        (data) => {
          this.isLoading = false;
         
          this.ParentMenuChange({ value: this.MenuForm.get("parentMenuId").value });
          this.resetChildMenuForm();
          this.notify.showSuccess("Created Successfully", "Menu");
          this.submitForm = false;
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  UpdateMenu() {
    this.submitForm = true;
    if (this.MenuForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.menuService
      .update({
        menuId: this.MenuForm.get("menuId").value,
        parentID: this.MenuForm.get("parentMenuId").value,
        name: this.MenuForm.get("name").value,
        route: this.MenuForm.get("route").value,
        isSideMenuItem: true,
        icon: this.MenuForm.get("icon").value,
      })
      .subscribe(
        (data) => {
          this.isLoading = false;
         
          this.ParentMenuChange({ value: this.MenuForm.get("parentMenuId").value });
          this.resetChildMenuForm();
          this.submitForm = false;
          this.notify.showSuccess("Edited Successfully", "Menu");
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  submitPatentMenu() {
    if (this.parentMenuId && this.parentMenuId != "") {
      this.UpdateParentMenu();
    }
    else {
      this.AddParentMenu();
    }
  }
  AddParentMenu() {
    if (this.parentMenuName == "") {
      return;
    }
    this.isLoading = true;

    this.menuService
      .createMenu({
        parentID: null,
        name: this.parentMenuName,
        icon: this.parentMenuIcon,
        isSideMenuItem: true,
      })
      .subscribe(
        (data) => {
          this.isLoading = false;
    
          this.fetchParentMenu();
          this.onCloseAdd();
          this.notify.showSuccess("Granted Successfully", "Menu");
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  UpdateParentMenu() {
    if (this.parentMenuName == "" || this.parentMenuId == "") {
      return;
    }
    this.isLoading = true;
    let parentMenu = this.menus.find(e => e.menuId == this.parentMenuId);
    parentMenu.name = this.parentMenuName;
    parentMenu.icon = this.parentMenuIcon;
    this.menuService
      .update(parentMenu)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.fetchParentMenu();
          this.onCloseAdd();
          this.notify.showSuccess("Granted Successfully", "Menu");
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  updateMenuOrder() {
    if (this.chiledMenus.length == 0) {
      return;
    }
    this.isLoading = true;

    for (let index = 0; index < this.chiledMenus.length; index++) {
      this.chiledMenus[index]!.order = index + 1;
    }

    this.menuService
      .updateList(this.chiledMenus)
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.notify.showSuccess("Granted Successfully", "Menu");
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  onDeleteMenu(menuId: string) {
    
    if(!menuId && menuId =="")
    {
      return;
    }
    this.menuService
      .delete(menuId)
      .subscribe(
        (data) => {
          if(this.isDeleteParentMenu)
          {
            this.chiledMenus =[];
            this.MenuForm.reset();
            this.fetchParentMenu();
            this.isLoading = false;
          }
          else
          {
            this.ParentMenuChange({ value: this.MenuForm.get("parentMenuId").value });
            
            this.isLoading = false;
              }
          this.notify.showSuccess("Granted Successfully", "Menu");
          this.onCloseDeleteMenuModel();
       
        },
        (error) => {
          this.isLoading = false;
          this.notify.showError("Grant Failed", "Menu");
        }
      );
  }
  setChildMenuValues(menuId: string) {
    let childmenu = this.chiledMenus.find(e => e.menuId == menuId);
    if (childmenu) {
      this.MenuForm.get("menuId").setValue(childmenu.menuId ?? "");
      this.MenuForm.get("name").setValue(childmenu.name ?? "");
      this.MenuForm.get("route").setValue(childmenu.route ?? "");
      this.MenuForm.get("icon").setValue(childmenu.icon ?? "");
    }
  }
  resetChildMenuForm() {
    let parentMenuId = this.MenuForm.get("parentMenuId").value;
    this.MenuForm.reset();
    this.MenuForm.get("parentMenuId").setValue(parentMenuId ?? "");
  }
  setParentMenu(menuId: string) {
    let parentMenu = this.menus.find(e => e.menuId == menuId);
    if (parentMenu) {
      this.parentMenuName = parentMenu?.name ?? "";
      this.parentMenuIcon = parentMenu?.icon ?? "";
      this.parentMenuId = parentMenu?.menuId ?? "";
      this.onOpenAdd();
    }

  }
}
