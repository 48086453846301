<div class="row" *ngIf="!IsPreviewDataLoaded">
  <div class="col-md-6">
    <div class="main-card mb-3 card">
      <div class="card-body">
        <h5 class="card-title">Import Documents</h5>
        <form (ngSubmit)="createDocument()" enctype="multipart/form-data">
          <div class="position-relative mb-3">
            <app-atlas-files-list [filesList]="uploadedFiles" [visible]="false" [visibleView]="false"
              [visibleDownload]="false" [visibleLink]="false" [delete]="deleteFile.bind(this)" [view]="view.bind(this)"
              [isDeletable]="isDeletable.bind(this)"></app-atlas-files-list>
            <app-atlas-file-upload [onFileUpload]="onFileUpload.bind(this)"></app-atlas-file-upload>
          </div>
          <button class="mt-1 btn btn-success btn-atlas">Submit</button>
          <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>

        </form>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="IsPreviewDataLoaded">
  <div class="">
    <div class="">
      <h1>Import Data Preview</h1>
    </div>

    <hr class="HR">

    <div class="row">
      <div class="col-md-6">
        <h4><strong>Document</strong></h4>
        <input [(ngModel)]="data.document" class="form-control" />
        <!-- <p>{{data.document}}</p> -->
      </div>
      <div class="col-md-6">
        <h4><strong>Document Type</strong></h4>
        <input [(ngModel)]="data.documentType" class="form-control" />
        <!-- <p>{{data.documentType}}</p> -->
      </div>
    </div>

    <hr class="HR">

    <div class="row">
      <div class="col-md-4">
        <h4><strong>Chapter Id</strong></h4>
      </div>
      <div class="col-md-4">
        <h4><strong>Chapter Title</strong></h4>
      </div>
    </div>

    <div class="container" *ngFor="let importData of data.importData">
      <div class="row m-1" *ngFor="let chapter of importData.chapters">
        <!-- <div class="row" *ngFor="let  of importChapter"> -->
        <span class="m-2" *ngIf="chapter.isExist==true">This Chapter Already Exist</span>
        <div class=" col-md-4">
          <input [(ngModel)]="chapter.chapterId" class="form-control"
            [ngClass]="{'alreadyExist': chapter.isExist==true}" />
          <!-- <p>{{chapter.chapterId}}</p> -->
        </div>
        <div class=" col-md-8">
          <input [(ngModel)]="chapter.title" class="form-control" [ngClass]="{'alreadyExist': chapter.isExist==true}" />
          <!-- <p>{{chapter.title}}</p> -->
        </div>
        <!-- </div> -->
      </div>
    </div>

    <hr class="HR mt-2">

    <div class="container m-1" *ngFor="let importControls of data.importData; let ind=index;">
      <!-- <div class="container" *ngFor="let control of importControls.control"> -->
      <div class="Col-Lg-12 col-lg-12">
        <h4><strong>Control Title</strong></h4>
        <span class="m-2" *ngIf="importControls.control.isExist==true">This Control Already Exist</span>
        <input [(ngModel)]="importControls.control.controlTitle" class="form-control"
          [ngClass]="{'alreadyExist': importControls.control.isExist==true}" />
        <!-- <p>{{importControls.control.controlTitle}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" [ngClass]="{'alreadyExist': importControls.control.isExist==true}">
        <h4><strong>Description</strong></h4>
        <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
          [onChange]="predefinedObservationActionChange.bind(this,'controlDescription',ind)"
          [descriptionHTMLFormate]="importControls.control.controlDescription" [placeholder]="'Description'">
        </app-atlas-html-editor>





        <!-- <p class="Paragraph" [innerHTML]="importControls.control.controlDescription"></p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" [ngClass]="{'alreadyExist': importControls.control.isExist==true}">
        <h4><strong>Objective</strong></h4>


        <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
          [onChange]="predefinedObservationActionChange.bind(this,'controlObjective',ind)"
          [descriptionHTMLFormate]="importControls.control.controlObjective" [placeholder]="'Objective'">
        </app-atlas-html-editor>





        <!-- <p [innerHTML]="importControls.control.controlObjective"></p> -->

      </div>
      <div class="Col-Lg-12 col-lg-12" [ngClass]="{'alreadyExist': importControls.control.isExist==true}">
        <h4><strong>Auditing Guidlines</strong></h4>
        <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
          [onChange]="predefinedObservationActionChange.bind(this,'auditingGuidlines',ind)"
          [descriptionHTMLFormate]="importControls.control.auditingGuidlines" [placeholder]="'Auditing Guidlines'">
        </app-atlas-html-editor>




        <!-- <p [innerHTML]="importControls.control.auditingGuidlines"></p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" [ngClass]="{'alreadyExist': importControls.control.isExist==true}">
        <h4><strong>Other Information</strong></h4>
        <app-atlas-html-editor [onCreateCommand]="onCreate.bind(this)"
          [onChange]="predefinedObservationActionChange.bind(this,'controlOtherInformation',ind)"
          [descriptionHTMLFormate]="importControls.control.controlOtherInformation" [placeholder]="'Other Information'">
        </app-atlas-html-editor>






        <!-- <p [innerHTML]="importControls.control.controlOtherInformation" class="Paragraph"></p> -->
      </div>
      <!-- </div> -->


    </div>
    <div class="container">
      <h4><strong>Data Lists</strong></h4>
    </div>

    <div class="container" *ngFor="let importControls of data.importData">
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasAnyNewDataList(importControls.dataLists)">
        <h4><strong>Control Title</strong></h4>
        <p>{{importControls.control.controlTitle}}</p>
        <!-- <p>{{importControls.control.controlTitle}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.cisAssetType)">

        <h4><strong>CIS Asset Types</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.cisAssetType">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p  class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.controlType)">
        <h4><strong>Control Types</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.controlType">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.controlType" class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.controlFunction)">
        <h4><strong>Control Functions</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.controlFunction">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.controlFunction" class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.cisLevels)">
        <h4><strong>CIS Levels</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.cisLevels">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.cisLevels" class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.primaryObjective)">
        <h4><strong>Primary Objectives</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.primaryObjective">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.primaryObjective" class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.scfDomain)">
        <h4><strong>SCF Domains</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.scfDomain">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.scfDomain" class="tag">{{tag}}</p> -->
      </div>
      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.suggestedControl)">
        <h4><strong>Suggested Controls</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.suggestedControl">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.suggestedControl" class="tag">{{tag}}</p> -->
      </div>

      <div class="Col-Lg-12 col-lg-12" *ngIf="hasNewDataList(importControls.dataLists.suggestedControlOwnership)">
        <h4><strong>Suggested Control Ownership</strong></h4>
        <div *ngFor="let tag of importControls.dataLists.suggestedControlOwnership">
          <input [(ngModel)]="tag.value" class="form-control mb-1" *ngIf="!tag.isExist">
        </div>
        <!-- <p *ngFor="let tag of importControls.dataLists.
        " class="tag">{{tag}}</p> -->
      </div>
    </div>

    <hr>

    <div class="container mb-2">
      <button class="closeBtn me-2" (click)="save()">Save</button>
    </div>
  </div>

</div>