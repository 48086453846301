import { Component, Input, OnInit } from '@angular/core';
import { ThemeOptions } from 'src/app/theme-options';

@Component({
  selector: 'app-app-customer-details',
  templateUrl: './app-customer-details.component.html',
  styleUrls: ['./app-customer-details.component.scss']
})
export class AppCustomerDetailsComponent implements OnInit {

  addMargin: boolean = false;
  customerDetails: any;
  @Input() set data(value) {
    if (value) {
      this.customerDetails = value;
    }
  }
  @Input()
  onClose: (closeboth) => {};
  constructor(public globals: ThemeOptions,) {
    if (window.innerWidth < 939 && window.innerWidth >= 768) {
      this.addMargin = true
    }
    else {
      this.addMargin = false;
    }
  }

  ngOnInit(): void {
    console.log('AppCustomerDetailsComponent initialized');

  }

}
