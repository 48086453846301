import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-question-details",
  templateUrl: "./question-details.component.html",
  styleUrls: ["./question-details.component.scss"],
})
export class QuestionDetailsComponent implements OnInit {

  description: SafeHtml = "";
  predefinedSuggestAction: SafeHtml = "";
  @Input() onClose: (closeboth) => {};
  @Input() selectedEvent: any = {};
  questionDetails: any = null;
  @Input() set event(value: any) {
    if (value) {
      this.description = value.description ? this._sanitizer.bypassSecurityTrustHtml(
        value.description
      ): '';
      this.predefinedSuggestAction = value.predefinedSuggestAction ? this._sanitizer.bypassSecurityTrustHtml(
        value.predefinedSuggestAction
      ) : '';
      this.questionDetails = value;
      this.ref.detectChanges();
    }
  }

  getEditUrl(event: any) {
    this.router.navigate(["/Audit/Question/" + this.questionDetails.id]);
  }
  constructor(private router: Router, protected _sanitizer: DomSanitizer, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.questionDetails = null;
  }
}
