<div class="row">
    <div class="col-md-6">
        <div class="main-card mb-3 card">
            <div class="card-body">
                <div *ngIf="resetPasswordForm">
                    <h5 class="card-title">Reset Password</h5>
                    <form [formGroup]="passwordForm" (ngSubmit)="resetPasswordSubmit()">
                        <div class="position-relative mb-3">
                            <label for="password" class="form-label">Password</label>
                            <input name="password" (change)="setvalidation()" (focus)="showValidation()"
                                (focusout)="CheckValidation($event)" id="password" formControlName="password"
                                type="password" placeholder="Password" class="form-control">
                            <!-- <small *ngIf="userForm.invalid" class="text text-danger">Required</small> -->
                            <div class="position-relative validationMsg" *ngIf="displayPasswordValidation"
                                style="background-color: #f1f4f6; border: 1px solid #f1f4f6;">
                                <ul>Password must be :
                                    <li>14 chatacters long.</li>
                                    <li>Must have special chatacters like (#*&).</li>
                                    <li>Must have upper and lower case chatacter.</li>
                                    <li>Must have Numeric value</li>
                                </ul>
                            </div>
                        </div>

                        <div class="position-relative mb-3">
                            <label for=" confirmPassword" class="form-label">Confirm Password</label>
                            <input name="confirmPassword" (focusout)="ComparePassword($event)"
                                (change)="setvalidation()" id="confirmPassword" formControlName="confirmPassword"
                                type="password" placeholder="confirm Password" class="form-control">
                            <small *ngIf="userForm.invalid" class="text text-danger">Required</small>
                        </div>
                        <div class="button-container">
                            <button type="submit" class="mt-1 btn btn-success btn-atlas" [disabled]="passwordForm.invalid">Reset
                                Password</button>
                        </div>
                    </form>
                </div>
                <div *ngIf="!resetPasswordForm">
                    <h5 class="card-title">User</h5>
                    <form [formGroup]="userForm" (ngSubmit)="createUser()" autocomplete="off">

                        <div class="position-relative mb-3">
                            <mat-form-field appearance="fill">
                                <mat-label>Select Role</mat-label>
                                <mat-select formControlName="roleIds" multiple>
                                    <mat-option *ngFor="let role of roles" [value]="role.id">
                                        {{role.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <br>
                            <label *ngIf="userForm.invalid" class="text text-danger">Required</label>
                        </div>
                        <div *ngIf="isEditForm">
                            <div class="position-relative mb-3">
                                <label for="firstName" class="form-label">First Name</label>
                                <input name="firstName" id="firstName" formControlName="firstName" type="text"
                                    placeholder="First Name" class="form-control"
                                    [ngClass]="{ 'invalid-input': userForm.get('firstName').invalid && userForm.get('firstName').touched }"
                                    required>
                                <small *ngIf="userForm.get('firstName').hasError('required')"
                                    class="text text-danger">Required</small>
                            </div>
                            <div class="position-relative mb-3">
                                <label for="lastName" class="form-label">Last Name</label>
                                <input name="lastName" id="lastName" formControlName="lastName" type="text"
                                    placeholder="Last Name" class="form-control"
                                    [ngClass]="{ 'invalid-input': userForm.get('lastName').invalid && userForm.get('lastName').touched }"
                                    required>
                                <small *ngIf="userForm.get('lastName').hasError('required')"
                                    class="text text-danger">Required</small>
                            </div>
                        </div>

                        <div class="position-relative mb-3">
                            <label for="email" class="form-label">Email</label>
                            <input name="email" id="email" formControlName="email" type="email" placeholder="Email"
                                class="form-control" [readonly]="isEditForm"
                                [ngClass]="{ 'invalid-input': userForm.get('email').invalid && userForm.get('email').touched }"
                                required>
                            <small *ngIf="userForm.get('email').hasError('invalidEmail')" class="text text-danger">
                                Please Provide Proper Email As it is Compulsory
                            </small>
                            <small *ngIf="userForm.get('email').hasError('required')"
                                class="text text-danger">Required</small>
                        </div>
                        <div class="button-container">
                            <div *ngIf="isEditForm">
                                <button type="submit" class="mt-1 btn btn-success btn-atlas"
                                    [disabled]="isLoading || submitted">Submit</button>
                                <!-- <button *ngIf="!showRecoverUserButton" class="mt-1 ms-2 btn btn-primary" type="button" (click)="ResetPassword()" [disabled]="isLoading || submitted">Reset
                                    Password</button> -->
                                <button *ngIf="showRecoverUserButton" class="mt-1 ms-2 btn btn-primary" type="button" (click)="RecoverUser()" [disabled]="isLoading || submitted">Recover User</button>
                            </div>
                            <div *ngIf="!isEditForm">
                                <button type="submit" class="mt-1 btn btn-success btn-atlas"
                                    [disabled]="isLoading || submitted">Invite</button>
                            </div>
                            <button class="mt-1 ms-2 btn btn-atlas-cancel" type="button" (click)="MoveBack()">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLoading" class="login-spinner" style="position: absolute; top: 45%; left: 45%;z-index: 100000;">
    <mat-spinner></mat-spinner>
</div>