import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TenantServices } from "src/app/Services/tenant-services";

@Component({
  selector: "app-tenant",
  templateUrl: "./tenant.component.html",
  styleUrls: ["./tenant.component.scss"],
})
export class TenantComponent implements OnInit {
  constructor(
    private tenantServices: TenantServices,
    private router: Router,
    private modalService: NgbModal
  ) { }

  tenant = [];

  tenantid: any;

  ngOnInit(): void {
    this.fetchTenant();
  }
  fetchTenant() {
    return this.tenantServices.getAll().subscribe((data) => {
      this.tenant = data;
    });
  }
  getEditUrl(event: any) {
    this.router.navigate(["/Settings/edit-tenant/" + event.id]);
  }
  onDelete(event) {
    this.tenantid = event.id;
    (document.getElementById("btnDelete") as HTMLInputElement).click();
  }

}
