import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/Services/notification.service';
import { PrimaryObjectivesService } from 'src/app/Services/primary-objectives.service';

@Component({
  selector: 'app-create-primary-objective',
  templateUrl: './create-primary-objective.component.html',

})
export class CreatePrimaryObjectiveComponent {

  heading = 'Primay Objective';
  subheading = 'Primay Objective inside the system';
  icon = 'pe-7s-document icon-gradient bg-premium-dark';
  primaryObjectiveForm: UntypedFormGroup;
  primaryObjectId: number;
  constructor(private formBuilder: UntypedFormBuilder,
    private primaryObjectiveService: PrimaryObjectivesService,
    private router: Router,
    private route: ActivatedRoute,
    private notify: NotificationService,
    private locationService: Location
  ) {

    this.primaryObjectiveForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });


    this.route.params.subscribe(qp => {
      let primaryObjectid = qp.id;
      this.primaryObjectId = primaryObjectid;

      if (this.primaryObjectId) {
        this.primaryObjectiveService.get(this.primaryObjectId).subscribe(data => {
          this.primaryObjectiveForm.patchValue({
            name: data.name,

          })
        })
      }
    })
  }

  MoveBack() {
    this.locationService.back();
  }


  createPrimayObjective() {
    if (this.primaryObjectiveForm.invalid) {
      return
    }
    else if (this.primaryObjectId) {
      this.primaryObjectiveService.update({
        name: this.primaryObjectiveForm.get('name').value,
        id: this.primaryObjectId,
      }).subscribe(data => {
        this.notify.showInfo('Updated Successfully', 'Primary Objective')
        this.router.navigate(['/Dashboard/Setup/primaryObjectives']);
      },
        error => {
          this.notify.showError('Error while updating', 'Primary Objective')
          this.router.navigate(['/Dashboard/Setup/primaryObjectives']);
        })
    }
    else {
      this.primaryObjectiveService.create({
        name: this.primaryObjectiveForm.get('name').value,
      }).subscribe(data => {
        this.notify.showSuccess('Created Successfully', 'Primary Objective')
        this.router.navigate(['/Dashboard/Setup/primaryObjectives'])
      },
        error => {
          this.notify.showError('Error while creating', 'Primary Objective')
          this.router.navigate(['/Dashboard/Setup/primaryObjectives'])
        })
    }

  }


}
