import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RichTextEditorComponent } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-atlas-html-editor',
  templateUrl: './atlas-html-editor.component.html',
  styleUrls: ['./atlas-html-editor.component.sass']
})
export class AtlasHtmlEditorComponent implements OnInit {

  constructor() { }
  @ViewChild('rteObj') rteObj: RichTextEditorComponent;
  @Input() onCreateCommand = (el) =>
    console.log("onCreate handler not implemented");
  @Input() onChange = (el) =>
    console.log("onChange handler not implemented");
  @Input() placeholder: string = "";
  @Input() Label: string = "";
  _descriptionHTMLFormate = "";
  @Input()
  set descriptionHTMLFormate(value: string | undefined) {
    this._descriptionHTMLFormate = value || "";
  }
  @Input() height: number = 200;
  public tools = {
    items: [
      'Undo', 'Redo', '|',
      'Bold', 'Italic', 'Underline', 'StrikeThrough', '|',
      'FontName', 'FontSize', 'FontColor', 'BackgroundColor', '|',
      'SubScript', 'SuperScript', '|',
      'LowerCase', 'UpperCase', '|',
      'Formats', 'Alignments', '|', 'OrderedList', 'UnorderedList', '|',
      'Indent', 'Outdent', '|', 'CreateLink',
      'Image', '|', 'ClearFormat', 'Print', 'SourceCode', '|', 'FullScreen'
    ]
  };
  ngOnInit(): void {

  }
  public onCreate(event): void {
    this.onCreateCommand(event)
  }
  ngAfterViewInIt() {
    this.rteObj.valueChange().subscribe(e => {
    })
  }
  change(event) {
    this.onChange(event)
  }
  public actionCompleteHandler(e: any): void {
    if (e.requestType === 'SourceCode') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.add('e-overlay');
    } else if (e.requestType === 'Preview') {
      this.rteObj.getToolbar().querySelector('#custom_tbar').parentElement.classList.remove('e-overlay');
    }
  }

}
