<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="icon {{icon}}"></i>
            </div>
            <div>
                {{heading}}
                <div class="page-title-subheading">
                    {{subheading}}
                </div>
            </div>
        </div>
        <div class="page-title-actions">
            <button *ngIf="route | hasPermission:'canCreate'" type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success btn-atlas" routerLink="/Audit/Create-or-edit-customer">
                <fa-icon [icon]="faPlus" class="me-2"></fa-icon>
                Create New
            </button>
        </div>
    </div>
  </div>
  
  <div>
    <app-atlas-table [columns]="columns" [dataSource]="data"
                     [customColumnWidths]="true" [customPageSizeOptions]="customPageSizeOptions"
                     [hasDeleteIcon]="true"
                     [hasPreviewIcon]="true"
                     [onEdit]="getEditUrl.bind(this)"
                     [onDelete]="onDelete.bind(this)"
                     [onOpen]="OpenCustomerDetails.bind(this)"
                        >
  
  
  
    </app-atlas-table>
  </div>
  
  <app-app-customer-details *ngIf="showDetailsPopup"  [onClose]="onClose.bind(this)" [data] ="customerDetails"
   style="position: fixed;top: 15%;width: 100%;left: 10%;z-index: 1000000;"></app-app-customer-details>

   <div *ngIf="isLoading" class="login-spinner" style="position: fixed; top: 45%; left: 45%;z-index: 1000001;">
    <mat-spinner></mat-spinner>
  </div>
  
   