import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DocumentationService {
  constructor(private http: HttpClient) {}
  knowledgeDataBase = environment.apiUrl + "KnowledgeDataBase";
  controlsBasePath = environment.apiUrl + "Controls";
  getAllKnowledgeDataBase(): any {
    return this.http.get<any>(this.knowledgeDataBase + "/All");
  }

  getKnowledgeDataBase(id): any {
    return this.http.get<any>(this.knowledgeDataBase + "/Get/" + id);
  }

  createKnowledgeDataBase(body, file: any): any {
    return this.http.post<any>(this.knowledgeDataBase + "/Create", body);
  }

  updateKnowledgeDataBase(body): any {
    return this.http.post<any>(this.knowledgeDataBase + "/Update/", body);
  }

  deleteKnowledgeDataBase(id): any {
    return this.http.post<any>(this.knowledgeDataBase + "/Delete/" + id, {});
  }

  getListKnowledgeDataBase(): any {
    return this.http.get<any>(this.knowledgeDataBase + "/GetList");
  }
  getControlsCount(documentId): any {
    return this.http.get<any>(
      this.controlsBasePath + "/GetControlsCountForDocument/" + documentId
    );
  }
}
