import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  constructor() { }
  @Input() open = false;
  @Input() UserDetails:any ={};
  @Input() Close = () => console.log("Open handler not implemented");

  ngOnInit(): void {
  }
 toggleNotifications() {
    this.Close();
  }
}
