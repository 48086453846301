export interface QuestionsViewModel {
  id: string;
  question: string;
  documentId: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  updatedBy: string;
  isDraft: string;
  controlId: string;
  description: string;
  predefinedSuggestAction: string;
  otherInformation: string;
  auditingGuideLines: string;
  chapterId: string;
  controlTypeIds: any[];
  cisAssetTypeIds: any[];
  cisLevelIds: any[];
  controlFunctionIds: any[];
  PrimaryObjectiveIds: any[];
  suggestedControlIds: any[];
  scfDomainIds: any[];
  scoIds: any[];
}

export class QuestionViewModelCLass implements QuestionsViewModel {
  
  id: string;
  question: string;
  title: string;
  documentId: string;
  dateCreated: string;
  dateUpdated: string;
  createdBy: string;
  updatedBy: string;
  isDraft: string;
  controlId: string;
  description: string;
  predefinedSuggestAction: string;
  otherInformation: string;
  auditingGuideLines: string;
  chapterId: string;
  controlTypeIds: any[] = [];
  cisAssetTypeIds: any[] = [];
  cisLevelIds: any[] = [];
  controlFunctionIds: any[] = [];
  PrimaryObjectiveIds: any[] = [];
  suggestedControlIds: any[] = [];
  scfDomainIds: any[] = [];
  scoIds: any[] = [];
}

export class linkQuestionModel {
  questionId: string = "";
  documentId: string = "";
  chapterId: string = "";
  controlId: string = "";
}

export class linkQuestionModelForLists {
  questionId: string[] = [];
  documentId: string[] = [];
  chapterId: string[] = [];
  controlId: string[] = [];
}
export class linkQuestionModelView {
  question: string = "";
  questionId: string = "";
  document: string = "";
  documentId: string = "";
  chapter: string = "";
  chapterId: string = "";
  control: string = "";
  controlId: string = "";
}
