const settingsKey = 'tableSettings'; 

export function applyUserTableSettings(columns, tableType) {
    const settings = localStorage.getItem(settingsKey);
    if (settings) {
      const parsedTableSettings = JSON.parse(settings);
      const tableSettings = parsedTableSettings?.find(setting => setting.tableType === tableType);
      if (tableSettings && tableSettings.columns) {
        columns.forEach(col => {
          if (col.hideFromTableSettings) return;
          const columnSetting = tableSettings.columns.find(c => c === col?.label);
          if (columnSetting) {
            col.hidden = true;
          } 
          else {
            col.hidden = false;
          }
        });
      }
    }
    return columns || []
  }

  export function getColumnsWithLabel(columns) {
    return columns
      .filter(column => column.label && !column?.hideFromTableSettings)  
      .map(column => column.label);  
  }
  
  export function getHiddenColumns(tableType){
    const settings = localStorage.getItem(settingsKey);
    if (settings && settings !== "null") {
      const parsedTableSettings = JSON.parse(settings);
      const tableSettings = parsedTableSettings?.find(setting => setting.tableType === tableType);
      if (tableSettings && tableSettings?.columns) {
        return tableSettings?.columns || []
      }
    }
    return []
  }

  export function saveUserTableSettings(hiddenColumns, tableType) {
    let parsedTableSettings = [];
    const settings = localStorage.getItem(settingsKey);

    if (settings && settings !== "null") {
      parsedTableSettings = JSON.parse(settings);
    }
    const existingSettingIndex = parsedTableSettings?.findIndex(setting => setting.tableType === tableType);
    if (existingSettingIndex !== -1 && settings&& settings !== "null") {
      parsedTableSettings[existingSettingIndex].columns = hiddenColumns;
    } else {
      parsedTableSettings.push({
        tableType: tableType,
        columns: hiddenColumns
      });
    }
    const stringifySetting = JSON.stringify(parsedTableSettings)
    localStorage.setItem(settingsKey,stringifySetting );
    return stringifySetting
  }