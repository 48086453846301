import { Component, Input } from '@angular/core';
import { ControlService } from 'src/app/Services/control.service';

@Component({
  selector: 'app-quill-control-list',
  templateUrl: './quill-control-list.component.html',
  styleUrls: ['./quill-control-list.component.scss']
})
export class QuillControlListComponent {

  linkedControlsList: any;
  openDetails: boolean = false;
  isLoading: boolean = false;
  controlDetails: any;

  constructor(private controlServices: ControlService) { }
  @Input()
  onClose: (closeboth) => {};
  @Input()
  closePopup: () => {};
  @Input() set LinkControl(value) {
    if (value) {
      this.controlServices.getControlsTitle(value).subscribe(res => {
        this.linkedControlsList = res;
      })
    }
  }

  OpenControlDetails(Id) {
    this.isLoading = true;
    this.controlServices.get(Id).subscribe((data) => {
      this.isLoading = false;
      this.openDetails = true;
      this.controlDetails = data;
    });
  }

  onCloseDetail(isShow) {
    this.openDetails = isShow;
  }
}
