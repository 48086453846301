import { Pipe, PipeTransform } from '@angular/core';
import { PermissionsGuard } from '../../Guard/permissions-guard';
import { ActivatedRouteSnapshot } from '@angular/router';

@Pipe({
  name: 'hasPermission'
})
export class HasPermissionPipe implements PipeTransform {

  constructor(
    private permissionsGuard: PermissionsGuard
  ) {}

  transform(route: ActivatedRouteSnapshot, action: 'canCreate' | 'canUpdate' | 'canDelete'): boolean {
    const permissionType = route.data.permissionType;
    return this.permissionsGuard.hasPermission(action, permissionType);
  }
}